import { Route, Switch } from 'react-router'

import { MethodContextProvider } from '../../Context/method-context.provider'
import UserDetailHeader from '../../Pages/Item/UserDetail/user-detail-header'
import UserDetailProfilePage from '../../Pages/Item/UserDetail/user-detail-profile-page'
import UserDetailEmailPage from '../../Pages/Item/UserDetail/UserDetailEmailPage'
import UserDetailMatchingPage from '../../Pages/Item/UserDetail/UserDetailMatchingPage'
import UserDetailSecurityPage from '../../Pages/Item/UserDetail/UserDetailSecurityPage'
import UserDetailStatisticsPage from '../../Pages/Item/UserDetail/UserDetailStatisticsPage'
import { USER_METHODS } from '../../util/methods'
import {
  USER_EMAIL_PATH,
  USER_MATCHING_PATH,
  USER_PROFILE_PATH,
  USER_SECURITY_PATH,
  USER_STATISTICS_PATH,
} from '../../util/path'
import ItemContainer from './helpers/ItemContainer'

export default function UserDetailPage() {
  return (
    <ItemContainer>
      {(data) => (
        <MethodContextProvider
          data={data}
          methods={USER_METHODS}>
          <UserDetailHeader data={data} />

          <Switch>
            <Route
              component={UserDetailStatisticsPage}
              path={USER_STATISTICS_PATH}
              exact
            />
            <Route
              component={UserDetailMatchingPage}
              path={USER_MATCHING_PATH}
              exact
            />
            <Route
              component={UserDetailSecurityPage}
              path={USER_SECURITY_PATH}
              exact
            />
            <Route
              component={UserDetailEmailPage}
              path={USER_EMAIL_PATH}
              exact
            />
            <Route
              component={UserDetailProfilePage}
              path={USER_PROFILE_PATH}
              exact
            />
          </Switch>
        </MethodContextProvider>
      )}
    </ItemContainer>
  )
}
