import { isOptionGroup, Option, OptionGroup } from '@gain/rpc/shared-model'
import { inputLabelClasses } from '@mui/material/InputLabel'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Select, { BaseSelectProps } from '@mui/material/Select'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'warning',
})<{ warning?: boolean }>(({ theme, warning, error }) => ({
  ...(!error &&
    warning && {
      [`& .${inputLabelClasses.root}`]: {
        color: theme.palette.warning.main,
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${theme.palette.warning.main} !important`,
      },
    }),
}))

type OptionOrOptionGroup = Option<string | number | null> | OptionGroup<string | number | null>

export interface NullableSelectProps extends BaseSelectProps {
  options: ReadonlyArray<OptionOrOptionGroup>
  nullFallbackValue?: string
  placeholder?: string
  warning?: boolean
}

/**
 * NullableSelect is a nullable version of the MUI Select component. MUI doesn't
 * allow `null` values in their Select component and isn't planning to support
 * it. This component performs a small hack to support the `null` option.
 */
export default function NullableSelect({
  options,
  nullFallbackValue,
  value,
  placeholder,
  ...props
}: NullableSelectProps) {
  return (
    <StyledSelect
      {...props}
      displayEmpty={Boolean(placeholder)}
      value={value ?? (nullFallbackValue || '')}>
      {placeholder && (
        <MenuItem
          value={nullFallbackValue || ''}
          disabled>
          <em>{placeholder}</em>
        </MenuItem>
      )}

      {options.flatMap((option) => {
        if (isOptionGroup(option)) {
          return [<ListSubheader key={option.label}>{option.label}</ListSubheader>].concat(
            option.options.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value ?? nullFallbackValue}>
                {item.label}
              </MenuItem>
            ))
          )
        }

        return (
          <MenuItem
            key={option.value}
            value={option.value ?? nullFallbackValue}>
            {option.label}
          </MenuItem>
        )
      })}
    </StyledSelect>
  )
}
