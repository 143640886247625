import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import ImageForm from './ImageForm'
import subscription from '../UpdateForm/helpers/subscription'
import Divider from '../../Components/Divider/Divider'

const ImagesForm = ({ path, alreadySelected }) => (
  <FieldArray
    subscription={subscription}
    name={path}>
    {({ fields }) =>
      fields.map((name) => (
        <div key={name}>
          <ImageForm
            name={name}
            alreadySelected={alreadySelected}
          />
          <Divider />
        </div>
      ))
    }
  </FieldArray>
)

ImagesForm.propTypes = {
  path: PropTypes.string.isRequired,
  alreadySelected: PropTypes.array.isRequired,
}

export default ImagesForm
