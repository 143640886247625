import * as yup from 'yup'

import { yupLinkedInUrl } from '../../common/input-fields/input-field-yup'

export const createPersonSchema = {
  firstName: yup.string().trim().nullable().required(),
  lastName: yup.string().trim().nullable().required(),
  birthYear: yup
    .number()
    .min(new Date().getFullYear() - 100)
    .max(new Date().getFullYear() - 15)
    .nullable(),
}

export const updatePersonSchema = {
  ...createPersonSchema,
  linkedInUrl: yupLinkedInUrl().nullable(),
}
