import { Url } from '@gain/rpc/cms-model'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { setWith } from 'lodash'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { ItemPageBlock } from '../../layout/item-page'
import { URLS } from '../../routes/route-asset/route-asset-utils'
import UrlForm from '../url-input-modal/url-form'
import URLInputModal from '../url-input-modal/url-input-modal'
import { InputCollection, useInputFormContext } from './index'

interface InputFieldMultiUrlProps {
  label?: string
  consistencyGuideName?: string
  urls: Url[]
  insideLegacy?: boolean
}

export const urlSchema = yup.object().shape({
  url: yup.string().url().required(),
})

export default function InputFieldMultiUrl({
  label,
  consistencyGuideName,
  urls,
  insideLegacy = false,
}: InputFieldMultiUrlProps) {
  const hasPrimaryUrl = urls.some((url) => url.isPrimary)
  const form = useFormContext()
  const inputForm = useInputFormContext()

  // Handle a radio button click to change primary URL. Because the `isPrimary`
  // is a field on each URL (`url.isPrimary`), each radio button in the form has
  // a unique name (`urls.index.isPrimary`). This doesn't automatically deselect
  // the old primary URL. Here we manually set the old primary URL to `false`,
  // then proceed applying the new primary URL.
  const handlePrimaryChange = async (event, fieldName) => {
    // Loop over all fields, find the previous primary URL, set to `false`
    // and patch in the backend.
    for (let i = 0; i < urls.length; i += 1) {
      const path = `urls.${i}.isPrimary`
      if (form.getValues(path)) {
        await inputForm.patch(setWith({}, path, false, Object))
      }
    }

    // Due to the patch in the loop above, the asset is re-fetched and the
    // form resets. Manually set the new primary URL to `true` again.
    form.setValue(fieldName, true, { shouldDirty: true })
  }

  return (
    <ItemPageBlock
      consistencyGuideName={consistencyGuideName}
      insideLegacy={insideLegacy}
      label={label}>
      {urls.length > 0 && (
        <>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            p={1}
            pr={7}>
            <Typography
              color={'grey'}
              fontSize={'small'}>
              Primary
            </Typography>
          </Stack>

          <Divider />
        </>
      )}

      <InputCollection
        name={URLS}
        disableCreate>
        <UrlForm onPrimaryChange={handlePrimaryChange} />
      </InputCollection>

      <URLInputModal
        selectPrimary={!hasPrimaryUrl}
        urls={urls}
      />
    </ItemPageBlock>
  )
}
