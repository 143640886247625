import { useUserProfileContext } from '@gain/modules/user'
import { isAdministrator } from '@gain/utils/user'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

import MainNavSectionTitle from '../../Components/Navigation/MainNavigation/MainNavSectionTitle'

const StyledUl = styled('ul')({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
})

export interface NavigationSectionProps {
  label: string
  adminOnly?: boolean
  children?: ReactNode
}

export default function NavigationSection({ label, adminOnly, children }: NavigationSectionProps) {
  const userProfile = useUserProfileContext()

  if (adminOnly && !isAdministrator(userProfile.role)) {
    return null
  }

  return (
    <>
      <MainNavSectionTitle>{label}</MainNavSectionTitle>
      <StyledUl>{children}</StyledUl>
    </>
  )
}
