import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import CreateAssetDialog from './create-asset-dialog'

interface CreateAssetButtonProps {
  onCreate?: (assetId: number) => void
  tooltip?: string
}

export default function ({ onCreate, tooltip }: CreateAssetButtonProps) {
  const [showDialog, handleShow, handleClose] = useDialogState()

  return (
    <>
      <Tooltip
        title={tooltip}
        disableInteractive>
        <Button
          onClick={handleShow}
          variant={'contained'}>
          Create new company
        </Button>
      </Tooltip>
      <CreateAssetDialog
        onClose={handleClose}
        onCreate={onCreate}
        show={showDialog}
      />
    </>
  )
}
