import { RpcListMethods } from '@gain/rpc/cms-model'
import { ListItemKey, ListSort, ListSortDirection } from '@gain/rpc/list-model'
import { deserializeListSort, serializeListSort } from '@gain/rpc/utils'
import type { GridSortingInitialState } from '@mui/x-data-grid/hooks'
import { GridSortItem, GridSortModel } from '@mui/x-data-grid/models/gridSortModel'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { useCallback, useRef } from 'react'
import { decodeDelimitedArray, encodeDelimitedArray, useQueryParam } from 'use-query-params'

import { SwrDataGridApiOptions } from './swr-data-grid.api'

const mapToListSort = <Method extends keyof RpcListMethods, Row extends RpcListMethods[Method]>({
  field,
  sort,
}: GridSortItem): ListSort<Row> => ({
  field: field as ListItemKey<Row>,
  direction: sort as ListSortDirection,
})

const mapToDataGridSort = <
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({
  field,
  direction,
}: ListSort<Row>): GridSortItem => ({
  field: field as ListItemKey<Row>,
  sort: direction,
})

export default function useSwrDataGridSorting<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({
  sort = [],
}: SwrDataGridApiOptions<Method, Row>): [
  ListSort<Row>[],
  GridSortingInitialState,
  Partial<DataGridProProps>
] {
  const [sorting, setSorting] = useQueryParam<ListSort<Row>[]>('sort', {
    encode: (value) => {
      if (value.length === 0) {
        return undefined
      }
      return encodeDelimitedArray(value.map(serializeListSort), ',')
    },
    decode: (value) => {
      const result = decodeDelimitedArray(value, ',') || []
      return result.filter(Boolean).map((item) => deserializeListSort(item!))
    },
  })

  const activeSort = sorting.length > 0 ? sorting : sort
  // Copy over to the ref, so we don't cause re-renders when the query param is updated
  const sortModelRef = useRef(activeSort.map(mapToDataGridSort))

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      setSorting(model.map(mapToListSort))
    },
    [setSorting]
  )

  return [
    activeSort,
    {
      sortModel: sortModelRef.current,
    },
    {
      sortingMode: 'server',
      onSortModelChange: handleSortModelChange,
    },
  ]
}
