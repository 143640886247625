import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export type CreateInputDateProps = Pick<MuiDatePickerProps<Date>, 'onChange' | 'value'>

export default function DatePicker({ onChange, value }: CreateInputDateProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        onChange={onChange}
        value={value}
      />
    </LocalizationProvider>
  )
}
