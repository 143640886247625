import { styled } from '@mui/material/styles'
import { gridClasses } from '@mui/x-data-grid-pro'
import { PropsWithChildren } from 'react'

export interface ItemPageContentProps {
  className?: string
  variant?: 'default' | 'grid' | 'legacy' | 'inside-legacy'
}

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '100vw',
})

const Container = styled('div')<ItemPageContentProps>(({ theme, variant }) => ({
  position: 'relative',
  padding: theme.spacing(4),
  maxWidth: 1200,
  width: '100%',

  ...(variant === 'default' && {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),

  // TODO:: Remove once no layout is using "legacy" anymore
  ...(variant === 'inside-legacy' && {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
    marginBottom: theme.spacing(2),
  }),

  // This one is to stay backwards compatible with the old forms
  ...(variant === 'legacy' && {
    display: 'flex',
    flexDirection: 'column',
  }),

  ...(variant === 'grid' && {
    [`& .${gridClasses.root}`]: {
      // 192 = height of the page header + tabs
      height: 'calc(100vh - 192px)',
    },
  }),
}))

export function ItemPageContent({
  children,
  className,
  variant = 'default',
}: PropsWithChildren<ItemPageContentProps>) {
  return (
    <Root className={className}>
      <Container variant={variant}>{children}</Container>
    </Root>
  )
}
