import { createSelector } from '@reduxjs/toolkit'
import { connect } from 'react-redux'

import { InputSelect } from './input-select'

const sectorsData = (state: any) => state.loadSectors.data
const subSectorsData = (state: any) => state.loadSubSectors.data

const getData = createSelector(
  [sectorsData, subSectorsData],
  (sectors: any[], subSectors: any[]) => ({
    options: sectors.map((sector) => ({
      label: sector.title,
      options: subSectors.filter((item) => sector.name === item.sector),
    })),
  })
)

const mapStateToProps = (state: any) => getData(state)

export interface SubSectorSelectProps {
  path: string
  options?: any[]
  disableClearable?: boolean
}

function SubSectorSelect({ path, options = [], disableClearable }: SubSectorSelectProps) {
  return (
    <InputSelect
      isClearable={!disableClearable}
      options={options}
      path={path}
    />
  )
}

export default connect(mapStateToProps)(SubSectorSelect)
