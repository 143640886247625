import PropTypes from 'prop-types'
import { File, getUniqueKeyForToggleNewFilename } from '../File'
import { nextResponse } from '../../../redux/action/nextAction'
import MethodContext from '../../../Context/method.context'
import { updateItem } from '../../../redux/action/updateItem'
import { partialAdd } from '../../../util/partial'
import { IMAGE_FIlES } from '../../../util/fileTypes'
import FileActionsGroup, { FILE_ACTION_TYPE_IMAGE, FILE_ACTION_UPLOAD } from '../file-actions-group'

const AddImageFile = ({ path }) => (
  <MethodContext.Consumer>
    {({ id, methods, disabled }) =>
      !disabled && (
        <File
          toggleNewFilename
          key={getUniqueKeyForToggleNewFilename()}
          accept={IMAGE_FIlES}
          next={nextResponse((fileId) =>
            updateItem(methods.update, {
              id,
              ...partialAdd(path, {
                fileId,
              }),
            })
          )}>
          <FileActionsGroup
            action={FILE_ACTION_UPLOAD}
            fileType={FILE_ACTION_TYPE_IMAGE}
          />
        </File>
      )
    }
  </MethodContext.Consumer>
)

AddImageFile.propTypes = {
  path: PropTypes.string.isRequired,
}

export default AddImageFile
