import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'

import CreateCreditDialog from './create-credit-dialog'

export default function CreateCreditButton() {
  const [showDialog, handleShow, handleClose] = useDialogState()

  return (
    <>
      <Button
        onClick={handleShow}
        variant={'contained'}>
        Create new credit
      </Button>
      <CreateCreditDialog
        onClose={handleClose}
        show={showDialog}
      />
    </>
  )
}
