import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import ConsistencyGuideButton from './consistency-guide-button'

interface ItemPageBlockTitleProps {
  label?: string
  consistencyGuideName?: string
}

export function ItemPageBlockTitle({ label, consistencyGuideName }: ItemPageBlockTitleProps) {
  // Bail out if there is nothing to render
  if (!label && !consistencyGuideName) {
    return null
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={1}>
      <Typography variant={'inherit'}>{label}</Typography>

      <ConsistencyGuideButton
        consistencyGuideName={consistencyGuideName}
        label={label}
      />
    </Stack>
  )
}
