import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import SideBar from '../../Components/Layout/SideBar'
import Navigation from '../navigation/navigation'

const Container = styled('span')<{ saving: boolean }>(({ saving }) => ({
  ...(saving && {
    'body &': {
      '*': {
        cursor: 'wait',
      },
    },
  }),
}))

export default function LayoutItemPage({ children }: PropsWithChildren<unknown>) {
  // TODO:: Remove when all pages use new InputForm
  const saving = useSelector((state: { save: { saving: boolean } }) => state.save.saving)

  return (
    <>
      <SideBar togglable>
        <Navigation />
      </SideBar>

      <Container saving={saving}>{children}</Container>
    </>
  )
}
