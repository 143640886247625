import { useUserProfileContext } from '@gain/modules/user'
import { isProduction } from '@gain/utils/environment'
import { styled } from '@mui/material/styles'
import { generatePath } from 'react-router'

import MainNavHeader from '../../Components/Navigation/MainNavigation/MainNavHeader'
import { NAV_ADVISORS, ROUTE_ADVISORS_PATH } from '../../routes/route-advisors/route-advisors-path'
import { NAV_ASSETS, ROUTE_ASSETS_PATH } from '../../routes/route-assets/route-assets-path'
import {
  NAV_CONFERENCE_EDITIONS,
  NAV_CONFERENCES,
  ROUTE_CONFERENCE_EDITIONS_PATH,
} from '../../routes/route-conference-editions/conference-editions-route-path'
import {
  NAV_CONFERENCES_FAMILY,
  ROUTE_CONFERENCES_PATH,
} from '../../routes/route-conferences/route-conferences-path'
import { NAV_CREDITS, ROUTE_CREDITS_PATH } from '../../routes/route-credits/route-credits-path'
import { NAV_DEALS, ROUTE_DEALS_PATH } from '../../routes/route-deals/route-deals-path'
import {
  NAV_INVESTORS,
  ROUTE_INVESTORS_PATH,
} from '../../routes/route-investors/route-investors-path'
import { NAV_LENDERS, ROUTE_LENDERS_PATH } from '../../routes/route-lenders/route-lenders-path'
import { NAV_PERSONS, ROUTE_PERSONS_PATH } from '../../routes/route-persons/route-persons-path'
import { NAV_PROJECTS, ROUTE_PROJECTS_PATH } from '../../routes/route-projects/route-projects-path'
import { NAV_TAGS, ROUTE_TAGS_PATH } from '../../routes/route-tags/tags-route-path'
import { NAV_USERS, ROUTE_USERS_PATH } from '../../routes/route-users/route-users-path'
import { NAV_VENUES, ROUTE_VENUES_PATH } from '../../routes/route-venues/route-venues-path'
import { LIST_USERS_METHOD } from '../../util/methods'
import {
  NAV_CUSTOMERS,
  NAV_EMPLOYEES,
  NAV_IN_THE_NEWS,
  NAV_INDUSTRIES,
  NAV_LEGAL_ENTITIES,
  NAV_RESEARCH,
} from '../../util/navigationTitles'
import { CUSTOMER_USERS_PATH } from '../../util/path'
import {
  DEFAULT_CUSTOMERS_LIST_PATH,
  DEFAULT_INDUSTRIES_LIST_PATH,
  DEFAULT_INTHENEWS_LIST_PATH,
  DEFAULT_LEGAL_ENTITIES_LIST_PATH,
  DEFAULT_RESEARCH_LIST_PATH,
  DEFAULT_SORT_FIRST_NAME,
} from '../../util/pathDefaults'
import { MainNavigationItemProps } from './main-navigation-item'
import MainNavigationFooter from './navigation-footer'
import MainNavigationItem from './navigation-item'
import NavigationSection, { NavigationSectionProps } from './navigation-section'
import { navItem, navItems } from './navigation-utils'

interface Section extends Omit<NavigationSectionProps, 'children'> {
  label: string
  adminOnly?: boolean
  children: MainNavigationItemProps[]
}

const sections = new Array<Section>(
  {
    label: 'Content',
    children: navItems(
      navItem(ROUTE_ASSETS_PATH, NAV_ASSETS, {
        children: navItems(navItem(ROUTE_PROJECTS_PATH, NAV_PROJECTS)),
      }),
      navItem(DEFAULT_INDUSTRIES_LIST_PATH, NAV_INDUSTRIES),
      navItem(ROUTE_INVESTORS_PATH, NAV_INVESTORS),
      navItem(ROUTE_PERSONS_PATH, NAV_PERSONS),
      navItem(ROUTE_DEALS_PATH, NAV_DEALS),
      // TODO(@remy): remove isProduction check when we want to enable lenders there
      !isProduction() &&
        navItem(ROUTE_LENDERS_PATH, NAV_LENDERS, {
          children: navItems(navItem(ROUTE_CREDITS_PATH, NAV_CREDITS)),
        }),
      navItem(ROUTE_ADVISORS_PATH, NAV_ADVISORS),
      navItem(ROUTE_CONFERENCE_EDITIONS_PATH, NAV_CONFERENCES, {
        children: navItems(
          navItem(ROUTE_CONFERENCES_PATH, NAV_CONFERENCES_FAMILY),
          navItem(ROUTE_CONFERENCE_EDITIONS_PATH, NAV_CONFERENCE_EDITIONS),
          navItem(ROUTE_VENUES_PATH, NAV_VENUES)
        ),
      }),
      navItem(DEFAULT_LEGAL_ENTITIES_LIST_PATH, NAV_LEGAL_ENTITIES),
      navItem(ROUTE_TAGS_PATH, NAV_TAGS, { adminOnly: true })
    ),
  },
  {
    label: 'Home',
    children: navItems(
      navItem(DEFAULT_INTHENEWS_LIST_PATH, NAV_IN_THE_NEWS),
      navItem(DEFAULT_RESEARCH_LIST_PATH, NAV_RESEARCH)
    ),
  },
  {
    label: 'Customer',
    children: navItems(
      navItem(DEFAULT_CUSTOMERS_LIST_PATH, NAV_CUSTOMERS),
      navItem(ROUTE_USERS_PATH, NAV_USERS)
    ),
    adminOnly: true,
  },
  {
    label: 'Gain',
    children: navItems(
      navItem(
        generatePath(CUSTOMER_USERS_PATH, {
          method: LIST_USERS_METHOD,
          sort: DEFAULT_SORT_FIRST_NAME,
          id: 1,
        }),
        NAV_EMPLOYEES
      )
    ),
    adminOnly: true,
  }
)

const Root = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  height: '100%',
  justifyContent: 'flex-start',

  '> *': {
    flex: '0 1 auto',
  },
})

const Container = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  overflow: 'auto',
  paddingTop: theme.spacing(2),
  borderTop: '1px solid hsla(0,0%,100%,.1)',
}))

export default function MainNavigation() {
  const userProfile = useUserProfileContext()

  return (
    <Root>
      <MainNavHeader data={userProfile} />

      <Container>
        {sections.map((section) => (
          <NavigationSection
            key={section.label}
            adminOnly={section.adminOnly}
            label={section.label}>
            {section.children.map((item) => (
              <MainNavigationItem
                key={item.path}
                {...item}
              />
            ))}
          </NavigationSection>
        ))}
      </Container>

      <MainNavigationFooter />
    </Root>
  )
}
