import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import PageContent from '../../../Components/Layout/PageContent'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'
import { ASSETS, MARKET } from './helpers/industryDetailNames'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import AssetsSegmentsForm from '../../../Compositions/IndustryDetail/AssetsSegmentsForm'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import AddAssetSelect from '../../../Compositions/Selects/AddAsyncSelect/AddAssetSelect'
import MethodContext from '../../../Context/method.context'
import { partialAdd } from '../../../util/partial'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailAssetsPage = ({ data }) => {
  const options = data[MARKET]
    ? data[MARKET].segments.map((segment) => ({
        value: segment.id,
        label: segment.name,
      }))
    : []

  return (
    <PageContent>
      <FormLabelComposition
        yml={'linked_company'}
        path={ASSETS}>
        Companies
      </FormLabelComposition>
      <InputGroupComposition>
        <UpdateForm initialValues={{ [ASSETS]: data[ASSETS] }}>
          {() => (
            <>
              <InputGroupItem>
                <AssetsSegmentsForm
                  data={data}
                  path={ASSETS}
                  options={options}
                />
              </InputGroupItem>
              <MethodContext.Consumer>
                {({ update, disabled }) =>
                  !disabled && (
                    <InputGroupItem style={{ padding: 10 }}>
                      <AddAssetSelect
                        placeholder="Select company"
                        alreadySelected={data[ASSETS].map((item) => item.assetId)}
                        sort={[]}
                        onChange={({ value }) => {
                          update(
                            partialAdd(ASSETS, {
                              assetId: value,
                              order: data[ASSETS].length,
                            })
                          )
                        }}
                      />
                    </InputGroupItem>
                  )
                }
              </MethodContext.Consumer>
            </>
          )}
        </UpdateForm>
      </InputGroupComposition>
      <SourcesButton usedFor={USED_FOR_INDUSTRY} />
    </PageContent>
  )
}

IndustryDetailAssetsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailAssetsPage)
