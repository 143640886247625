import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { isRequired } from '../../../../util/isRequired'
import Input from '../../../../Components/FormElements/Input/Input'
import { PrintFieldError } from './PrintFieldError'

const CreateInputField = ({ name, type, parse, placeholder, validate, autoFocus }) => (
  <Field
    name={name}
    type={type}
    validate={validate}
    parse={parse}>
    {({ input, meta }) => (
      <>
        <Input
          hasError={(meta.error || meta.submitError) && meta.touched}
          input={input}
          placeholder={placeholder}
          autoComplete="off"
          autoFocus={autoFocus}
        />
        <PrintFieldError {...meta} />
      </>
    )}
  </Field>
)

CreateInputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  parse: PropTypes.func,
  autoFocus: PropTypes.bool,
  validate: PropTypes.func,
}

CreateInputField.defaultProps = {
  type: 'text',
  parse: undefined,
  autoFocus: false,
  validate: isRequired,
}

export default CreateInputField
