import clsx from 'clsx'
import { FunctionComponent, memo } from 'react'

import { withClassNames } from '../../HigherOrder/withClassNames'
import classes from './CheckRadio.module.scss'

export interface CheckRadioProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  input: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  label?: string
  inverted?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const blurActiveElement = () => {
  const active: any = document.activeElement
  if (active && active.blur) {
    active.blur()
  }
}

const CheckRadio: FunctionComponent<CheckRadioProps> = ({
  className = '',
  input,
  label = '',
  inverted = false,
  disabled = false,
  readOnly = false,
  ...rest
}) => {
  const isRadio = input.type === 'radio'
  const componentId = `${input.name}-${input.value}`

  return (
    <div
      className={clsx(className, {
        [classes.typeRadio]: isRadio,
        [classes.noLabel]: label?.length === 0,
      })}
      {...rest}>
      <input
        className={classes.input}
        disabled={disabled}
        id={componentId}
        name={input.name}
        onMouseDown={blurActiveElement}
        readOnly={readOnly}
        {...input}
      />
      <label
        className={clsx(classes.label, {
          [classes.inverted]: inverted,
          'fix-checkbox-icon': !isRadio,
        })}
        htmlFor={componentId}>
        {label}
      </label>
    </div>
  )
}

export default memo(withClassNames(CheckRadio, classes))
