import styles from './SideBarMenu.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

export const SIDEBAR_MENU_VISIBLE = 'styleNameIsVisible'
export const SIDEBAR_MENU_TOGGLEABLE = 'styleNameIsToggleable'

const SideBarMenu = (props) => <div {...props} />

export default memo(withClassNames(SideBarMenu, styles))
