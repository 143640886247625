import PropTypes from 'prop-types'
import styles from './MainNavHeader.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import { ICON_GAIN_LOGO } from '../../Icons/Icon'
import Visual, { VISUAL_STYLENAME_ROUND } from '../../Visual/Visual'
import SpacedItems from '../../SpacedItems/SpacedItems'
import Text from '../../Text/Text'
import { memo } from 'react'

const MainNavHeader = ({ data, ...rest }) => (
  <SpacedItems
    className={styles.title}
    {...rest}>
    <Visual
      style={{ fontSize: 6 }}
      styleName={VISUAL_STYLENAME_ROUND}
      imageSource={<div className={styles.logo}>{ICON_GAIN_LOGO}</div>}
    />
    <Text className={styles.gainText}>Gain.pro</Text>
  </SpacedItems>
)

MainNavHeader.defaultProps = {
  data: undefined,
}

MainNavHeader.propTypes = {
  data: PropTypes.object,
}

export default memo(withClassNames(MainNavHeader, styles))
