import { UserRole } from '@gain/rpc/shared-model'
import { SUBSECTORS } from '@gain/utils/sector'
import { parse } from 'csv-parse/browser/esm/sync'
import camelCase from 'lodash/camelCase'

export interface ProcessedLine {
  isValid: boolean
  data: {
    firstName: string
    lastName: string
    email: string
    role: UserRole
    recommendRegions: string[]
    recommendSubsectors: string[]
  }
}

const regionRegex = new RegExp(/\(([A-Z]{2})\)/)

function processCsvLine(line: {
  firstName: string
  lastName: string
  emailAddress: string
  admin: string
  regionPreferences: string
  sectorPreferences: string
}): ProcessedLine {
  const sectors = line.sectorPreferences.split(',')
  const regions = line.regionPreferences.split(',')

  const processedRegions = regions
    .map((region) => {
      const matches = regionRegex.exec(region.trim())

      return matches && matches[1]
    })
    .filter(Boolean) as string[]

  const allowedSectors = SUBSECTORS.map(({ name }) => name)
  const processedSectors = sectors
    .map((sector) => {
      if (allowedSectors.includes(sector.trim())) {
        return sector.trim()
      }

      return false
    })
    .filter(Boolean) as string[]

  return {
    isValid: Boolean(line.firstName && line.lastName && line.emailAddress),
    data: {
      firstName: line.firstName,
      lastName: line.lastName,
      email: line.emailAddress,
      role: line.admin.toLowerCase() === 'true' ? UserRole.Admin : UserRole.User,
      recommendRegions: processedRegions,
      recommendSubsectors: processedSectors,
    },
  }
}

export function processCsvFile(file: File): Promise<[]> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (event) => {
      try {
        if (event.target && event.target.result) {
          const csvLines = parse(event.target.result as string, {
            columns: (header) => header.map((column) => camelCase(column.toLowerCase().trim())),
            delimiter: ';',
            trim: true,
          })
            .map(processCsvLine)
            .sort((lineOne) => {
              if (lineOne.isValid) {
                return 1
              }

              return -1
            })

          resolve(csvLines)
        }

        resolve([])
      } catch (err) {
        reject(err)
      }
    }

    fileReader.readAsText(file)
  })
}

export interface LineImportResult {
  id?: number
  failed: boolean
  reason?: string
}
