import { ItemPageContent } from '../../layout/item-page'
import PropTypes from 'prop-types'
import SourcesForm from './helpers/SourcesForm'
import { FormLabelComposition } from '../FormLabel/FormLabelComposition'
import InputGroupComposition from '../InputGroup/InputGroupComposition'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import { FlexContainer } from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_50_PX } from '../../Components/Tabular/FlexTable/FlexItem'
import Text, { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import { COLORNAME_TEXT_SECONDARY } from '../../util/colors'
import Divider from '../../Components/Divider/Divider'
import { UpdateForm } from '../UpdateForm/UpdateForm'
import { SOURCES } from '../../routes/route-asset/route-asset-utils'
import MethodContext from '../../Context/method.context'
import AddArrayItemComposition, { dataSize } from '../ItemActions/AddArrayItemComposition'
import SourcesButton from '../Buttons/SourcesButton'
import SpacedItems from '../../Components/SpacedItems/SpacedItems'
import PasteArrayItemComposition from '../ItemActions/PasteArrayItemComposition'

const SourcesComposition = ({ data, usedFor, notUsedFor }) => (
  <ItemPageContent variant={'legacy'}>
    <FormLabelComposition path={SOURCES}>Sources</FormLabelComposition>
    <InputGroupComposition>
      {usedFor.length > 0 && (
        <InputGroupItem>
          <FlexContainer>
            <FlexItem />
            {usedFor.map((uses) => (
              <FlexItem
                styleName={FLEXITEM_50_PX}
                key={uses.name}>
                <Text
                  size={SIZE_CAPTION_1}
                  colorName={COLORNAME_TEXT_SECONDARY}>
                  {uses.abbr}
                </Text>
              </FlexItem>
            ))}
            <FlexItem style={{ flex: '0 0 60px' }} />
          </FlexContainer>
        </InputGroupItem>
      )}
      <Divider style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.15)' }} />
      <UpdateForm initialValues={{ [SOURCES]: data[SOURCES] }}>
        {() => (
          <>
            <MethodContext.Consumer>
              {({ disabled }) => (
                <SourcesForm
                  lastIndex={disabled ? undefined : dataSize(data[SOURCES]) - 1}
                  usedFor={usedFor}
                />
              )}
            </MethodContext.Consumer>
            {dataSize(data[SOURCES]) > 0 && <Divider />}
            <SpacedItems>
              <AddArrayItemComposition
                path={SOURCES}
                value={{
                  order: dataSize(data[SOURCES]),
                }}>
                Add source
              </AddArrayItemComposition>
              <PasteArrayItemComposition
                path={SOURCES}
                order={dataSize(data[SOURCES])}
                deleteItems={[
                  'id',
                  'assetId',
                  'management',
                  ...notUsedFor.map((item) => item.name),
                ]}>
                Add copied source
              </PasteArrayItemComposition>
            </SpacedItems>
          </>
        )}
      </UpdateForm>
    </InputGroupComposition>
    <SourcesButton usedFor={usedFor} />
  </ItemPageContent>
)

SourcesComposition.propTypes = {
  data: PropTypes.object.isRequired,
  usedFor: PropTypes.array,
  notUsedFor: PropTypes.array.isRequired,
}

SourcesComposition.defaultProps = {
  usedFor: [],
}

export default SourcesComposition
