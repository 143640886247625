import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import { DateCalendar } from '@mui/x-date-pickers'
import { useState } from 'react'

import { useBulkDataGridContext } from './asset-bulk-data-grid-context'
import AssetBulkDataGridMenu from './asset-bulk-data-grid-menu'

const StyledDateCalendar = styled(DateCalendar)(({ theme }) => ({
  margin: theme.spacing(-2),
}))

export default function AssetBulkDataGridDueDate() {
  const bulkContext = useBulkDataGridContext()
  const [dueDate, setDueDate] = useState<Date | null>(null)

  const handleSave = () => {
    return bulkContext.patch({ dueDate: dueDate === null ? null : dueDate.toISOString() })
  }

  return (
    <AssetBulkDataGridMenu
      icon={<CalendarTodayIcon color={'inherit'} />}
      title={'Due date'}>
      <StyledDateCalendar
        onChange={(newValue) => setDueDate(newValue)}
        value={dueDate}
      />

      <LoadingButton
        loading={bulkContext.updating}
        onClick={handleSave}
        variant={'contained'}>
        Save
      </LoadingButton>
    </AssetBulkDataGridMenu>
  )
}
