import { Children, cloneElement, PropsWithChildren, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'

import DropZoneIndicator from '../../Components/FormElements/DropZoneIndicator/DropZoneIndicator'
import { uploadFile } from '../../redux/action/uploadFile'
import { EXCEL_FILES, isExcelFile, isXBRLFile, XML_FILES } from '../../util/fileTypes'

export const getUniqueKeyForToggleNewFilename = () => new Date().getTime()

export interface IFileProps {
  next: () => void
  secure?: boolean
  accept?: string
  toggleNewFilename?: boolean
  readOnly?: boolean
}

export function File({
  secure = false,
  accept,
  children = 'Click here or drop files to upload',
  toggleNewFilename = false,
  readOnly = false,
  next,
}: PropsWithChildren<IFileProps>) {
  const dispatch = useDispatch()
  const doUploadFile = useCallback(
    (file, uploadSecure, uploadAccept) =>
      dispatch(uploadFile(file, uploadSecure, uploadAccept, next)),
    [next, dispatch]
  )
  const [filename, setFilename] = useState(undefined)

  const onDrop = useCallback(
    (event) => {
      const file = event.target ? event.target.files[0] : event[0]

      // #170927926 - Can't upload Excel in Chrome on Windows, same for
      // XBRL on macOS Safari/Chrome. Including fallback to `text/plain`
      // for future unknown files.
      if (file.type == null || file.type.trim().length === 0) {
        let mimeType = 'text/plain'
        if (isExcelFile(file)) {
          mimeType = EXCEL_FILES
        } else if (isXBRLFile(file)) {
          mimeType = XML_FILES
        }

        Object.defineProperty(file, 'type', {
          value: mimeType,
        })
      }

      setFilename(file.name)
      doUploadFile(file, secure, accept)
    },
    [doUploadFile, secure, accept]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const childrenWithProps = Children.map(children, (child) =>
    cloneElement(child as any, {
      filename,
      newFilename: true,
    })
  )

  return (
    <div
      {...getRootProps()}
      style={{ position: 'relative', maxWidth: '100%' }}>
      <input
        {...getInputProps()}
        accept={accept}
        readOnly={readOnly}
      />
      {toggleNewFilename && filename ? childrenWithProps : children}
      {isDragActive && <DropZoneIndicator>Drop your files here</DropZoneIndicator>}
    </div>
  )
}
