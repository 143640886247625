import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ColumnedItems.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { createItemSpaceStyle } from './SpacedItems'
import { memo } from 'react'

const ColumnedItems = ({ tagName, columns, className, style, itemSpace, ...rest }) => {
  const TagName = tagName

  return (
    <TagName
      className={classNames(className, styles[`columns-${columns}`])}
      style={createItemSpaceStyle(style, itemSpace)}
      {...rest}
    />
  )
}

ColumnedItems.defaultProps = {
  className: '',
  tagName: 'div',
  style: undefined,
  columns: 2,
  itemSpace: 8,
}

ColumnedItems.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
  style: PropTypes.object,
  columns: PropTypes.number,
  itemSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default memo(withClassNames(ColumnedItems, styles))
