import styles from './Tabular.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

export const TABULAR_HEADER = 'styleNameHeader'

const Tabular = (props) => {
  return <div {...props} />
}

export default memo(withClassNames(Tabular, styles))
