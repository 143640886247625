import { Field } from 'react-final-form'
import Select from 'react-select'

import InputError from '../../Components/FormElements/Error/InputError'
import MethodContext from '../../Context/method.context'
import { getNoOptionsMessage } from '../../util/no-options-message'
import { partial } from '../../util/partial'
import useInputSelectStyles from '../../util/use-input-select-styles'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import regionSelectComponents from './helpers/regionComponents'
import { useRegionOptions } from './helpers/use-region-options'

interface RegionSelectProps {
  path: string
}

export default function RegionsSelect({ path }: RegionSelectProps) {
  const options = useRegionOptions()
  const styles = useInputSelectStyles()

  return (
    <MethodContext.Consumer>
      {({ update, disabled, readOnly }) => (
        <>
          <Field
            name={path}
            options={options}
            type={'select'}>
            {({ input, ...rest }) => (
              <Select
                {...input}
                readOnly={readOnly}
                {...rest}
                components={regionSelectComponents}
                isDisabled={disabled}
                noOptionsMessage={getNoOptionsMessage}
                onChange={(values) =>
                  update(
                    partial(path, Array.isArray(values) ? values.map((item) => item.value) : [])
                  )
                }
                placeholder={'Select'}
                styles={styles}
                value={options
                  .flatMap((option) => option.options)
                  .filter(
                    (option) => Array.isArray(input.value) && input.value.includes(option.value)
                  )}
                isMulti
              />
            )}
          </Field>
          <ErrorContainer path={path}>
            {(onClick, hasErrors) => (
              <InputError
                hasErrors={hasErrors}
                onClick={onClick}
              />
            )}
          </ErrorContainer>
        </>
      )}
    </MethodContext.Consumer>
  )
}
