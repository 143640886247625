import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  linkToExcelSuccess,
  LINK_TO_EXCEL,
  LINK_TO_EXCEL_CANCELLED,
  LINK_TO_EXCEL_SUCCESS,
} from '../action/linkToExcelFile'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(LINK_TO_EXCEL),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => linkToExcelSuccess(response, action.next)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(LINK_TO_EXCEL_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(LINK_TO_EXCEL_SUCCESS),
      filter(({ next }) => isFunction(next)),
      map(({ response, next }) => next(response))
    )
  )
