import PropTypes from 'prop-types'
import styles from './Note.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Text from '../Text/Text'
import { memo } from 'react'

export const NOTE_STYLENAME_WARNING = 'styleNameWarning'
export const NOTE_STYLENAME_INFO = 'styleNameInfo'

const Note = ({ children, ...rest }) => (
  <div {...rest}>{typeof children === 'string' ? <Text>{children}</Text> : children}</div>
)

Note.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Note.defaultProps = {
  children: undefined,
}

export default memo(withClassNames(Note, styles))
