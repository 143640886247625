import PropTypes from 'prop-types'
import styles from './Ratio.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

const Ratio = ({ children, style, ratio, ...rest }) => (
  <div
    {...rest}
    style={{
      ...style,
      ...{
        '--aspect-ratio': ratio,
      },
    }}>
    <div className={styles.content}>{children}</div>
  </div>
)

Ratio.defaultProps = {
  ratio: 4 / 3,
  style: undefined,
  children: undefined,
}

Ratio.propTypes = {
  style: PropTypes.object,
  ratio: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default memo(withClassNames(Ratio, styles))
