import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import InputFile from '../InputFile'
import FileActionsGroup, {
  FILE_ACTION_REPLACE,
  FILE_ACTION_TYPE_IMAGE,
} from '../file-actions-group'
import { IMAGE_FIlES } from '../../../util/fileTypes'
import MethodContext from '../../../Context/method.context'

const ImageField = ({ path }) => (
  <Field
    name={path}
    type="text">
    {({ input }) => (
      <MethodContext.Consumer>
        {({ disabled }) =>
          disabled ? (
            <FileActionsGroup
              fileId={input.value.fileId}
              imageUrl={input.value.fileUrl}
              filename={input.value.filename || 'Please add a file'}
              fileType={FILE_ACTION_TYPE_IMAGE}
            />
          ) : (
            <InputFile
              path={`${path}.fileId`}
              accept={IMAGE_FIlES}>
              <FileActionsGroup
                fileId={input.value.fileId}
                imageUrl={input.value.fileUrl}
                filename={input.value.filename || 'Please add a file'}
                fileType={FILE_ACTION_TYPE_IMAGE}
                action={FILE_ACTION_REPLACE}
              />
            </InputFile>
          )
        }
      </MethodContext.Consumer>
    )}
  </Field>
)

ImageField.propTypes = {
  path: PropTypes.string.isRequired,
}

export default ImageField
