import classNames from 'classnames'
import { memo } from 'react'

import Button, { BUTTON_STYLENAME_PILL, BUTTON_STYLENAME_SECONDARY, ButtonProps } from './Button'

function PillButtonSecondary(props: ButtonProps) {
  return (
    <Button
      styleName={classNames(BUTTON_STYLENAME_PILL, BUTTON_STYLENAME_SECONDARY)}
      tagName={'button'}
      type={'button'}
      {...props}
    />
  )
}

export default memo(PillButtonSecondary)
