import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap, UserListItem } from '@gain/rpc/cms-model'
import { isJsonRpcError, listFilter } from '@gain/rpc/utils'
import PeopleIcon from '@mui/icons-material/People'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { selectedIdsLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { InputFieldAutoComplete, InputFormProvider } from '../../../common/input-fields'
import { useSwrDataGrid } from '../../../common/swr-data-grid'
import AssetBulkDataGridMenu from './asset-bulk-data-grid-menu'

export default function AssetBulkDataGridAssignee() {
  const [updating, setUpdating] = useState(false)
  const [resetting, setResetting] = useState(false)
  const swrDataGrid = useSwrDataGrid()
  const rpcClient = useRpcClient<RpcMethodMap>()
  const theme = useTheme()

  const apiRef = useGridApiContext()
  const selectedIds = useGridSelector(apiRef, selectedIdsLookupSelector)
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm({
    defaultValues: { input: [] },
  })

  const handleSave = () => {
    form.handleSubmit(async ({ input }) => {
      try {
        setUpdating(true)
        const userIds = input.map(({ id }) => id) as number[]

        await rpcClient({
          method: 'cms.addAssetWorkflowAssignees',
          params: {
            ids: Object.values(selectedIds) as number[],
            userIds,
          },
        })

        // Refresh the grid
        swrDataGrid.api.swr.mutate()
      } catch (error) {
        if (isJsonRpcError(error)) {
          enqueueSnackbar(error.data?.[0]?.message ?? error.message, {
            id: 'set-projects',
            preventDuplicate: true,
            variant: 'error',
          })
        }
      } finally {
        setUpdating(false)
      }
    })()
  }

  const handleReset = async () => {
    try {
      setResetting(true)
      await rpcClient({
        method: 'cms.deleteAssetWorkflowAssignees',
        params: {
          ids: Object.values(selectedIds) as number[],
        },
      })

      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    } catch (error) {
      if (isJsonRpcError(error)) {
        enqueueSnackbar(error.data?.[0]?.message ?? error.message, {
          id: 'set-projects',
          preventDuplicate: true,
          variant: 'error',
        })
      }
    } finally {
      setResetting(false)
    }
  }

  return (
    <AssetBulkDataGridMenu
      icon={<PeopleIcon color={'inherit'} />}
      title={'Assignee'}>
      <InputFormProvider form={form}>
        <InputFieldAutoComplete
          defaultFilter={[
            listFilter<UserListItem>('status', '=', 'active'),
            listFilter<UserListItem>('customerId', '=', 1),
          ]}
          getOptionLabel={(user) => (user ? `${user.firstName} ${user.lastName}` : user)}
          labelProp={'firstName'}
          method={'customer.listUsers'}
          name={'input'}
          slotProps={{
            popper: {
              slotProps: {
                root: {
                  style: { zIndex: theme.zIndex.tooltip },
                },
              },
            },
          }}
          valueProp={'id'}
          variant={'outlined'}
          enableCheckboxes
          multiple
        />
      </InputFormProvider>

      <Stack
        direction={'row'}
        gap={1}>
        <LoadingButton
          loading={resetting}
          onClick={handleReset}
          variant={'outlined'}>
          Reset
        </LoadingButton>

        <LoadingButton
          loading={updating}
          onClick={handleSave}
          sx={{ flex: 1 }}
          variant={'contained'}>
          Add
        </LoadingButton>
      </Stack>
    </AssetBulkDataGridMenu>
  )
}
