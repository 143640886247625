import Flag from '@gain/components/flag'

import PageContentList from '../../Components/Layout/PageContentList'
import StickyTableHeader from '../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_MEDIUM,
  CELL_TYPE_REGION,
  CELL_TYPE_SMALL,
  CELL_TYPE_XLARGE,
} from '../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../Components/Tabular/TabularRow/TabularRow'
import { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import LegalEntityActions from '../../Compositions/Actions/LegalEntityActions'
import ActionsMenu from '../../Compositions/ActionsMenu/ActionsMenu'
import AddButton from '../../Compositions/Buttons/AddButton'
import { TabularCellSortLink } from '../../Compositions/Link/TabularCellSortLink'
import LegalEntityCreateModal from '../../Compositions/Modals/CreateModals/LegalEntityCreateModal'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { LEGAL_ENTITY_METHODS } from '../../util/methods'
import { LEGAL_ENTITY_PROFILE_PATH } from '../../util/path'
import { pathGenerate } from '../../util/pathGenerate'
import { toastCreate } from '../../util/toastCreate'
import { ListContainer } from './helpers/ListContainer'

export default function LegalEntitiesPage() {
  return (
    <>
      <PageHeaderComposition
        extras={<AddButton onClick={toastCreate(<LegalEntityCreateModal />)} />}
        title={'Legal Entities'}
      />
      <PageContentList>
        <StickyTableHeader>
          <Tabular styleName={TABULAR_HEADER}>
            <TabularRow styleName={ROWTYPE_HEAD}>
              <TabularCellSortLink
                sort={'-name'}
                styleName={CELL_TYPE_XLARGE}>
                Name
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-region'}
                styleName={CELL_TYPE_REGION}>
                HQ
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-externalId'}
                styleName={CELL_TYPE_MEDIUM}>
                Entity number
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-annualReports'}
                styleName={CELL_TYPE_SMALL}>
                ARs
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-linkedAssets'}
                styleName={CELL_TYPE_SMALL}>
                Linked companies
              </TabularCellSortLink>
              <TabularCell styleName={CELL_TYPE_ACTIONS} />
            </TabularRow>
          </Tabular>
        </StickyTableHeader>
        <Tabular>
          <ListContainer>
            {(data) =>
              data.map((item) => (
                <MethodContextProvider
                  key={item.id}
                  data={item}
                  methods={LEGAL_ENTITY_METHODS}>
                  <TabularRow styleName={ROWTYPE_HOVER}>
                    <TabularCell styleName={CELL_TYPE_CLICK}>
                      <TabularRow
                        to={pathGenerate(LEGAL_ENTITY_PROFILE_PATH, {
                          id: item.id,
                        })}>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_XLARGE}>
                          {item.name}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_REGION}>
                          <Flag code={item.region} />
                        </TabularCell>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_MEDIUM}>
                          {item.externalId}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>{item.annualReports}</TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>{item.linkedAssets}</TabularCell>
                      </TabularRow>
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_ACTIONS}>
                      <ActionsMenu
                        content={
                          <LegalEntityActions
                            data={item}
                            listPage
                          />
                        }
                        openerColorStyle={'darken'}
                      />
                    </TabularCell>
                  </TabularRow>
                </MethodContextProvider>
              ))
            }
          </ListContainer>
        </Tabular>
      </PageContentList>
    </>
  )
}
