import PropTypes from 'prop-types'
import styles from './ToastCloseButton.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Icon, { ICON_CROSS } from '../Icons/Icon'
import { memo } from 'react'

const ToastCloseButtonAndArea = ({ closeToast, ...rest }) => (
  <div
    onClick={closeToast}
    {...rest}>
    <Icon
      className={styles.closeIcon}
      src={ICON_CROSS}
    />
  </div>
)

ToastCloseButtonAndArea.propTypes = {
  closeToast: PropTypes.func.isRequired,
}

export default memo(withClassNames(ToastCloseButtonAndArea, styles))
