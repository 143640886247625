import { createSelector } from '@reduxjs/toolkit'
import { FunctionComponent, ReactNode, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LoadingRow from '../../../Components/Tabular/TabularRow/LoadingRow'
import NoResultsRow from '../../../Components/Tabular/TabularRow/NoResultsrRow'
import LoadMoreWaypoint from '../../../Components/Waypoint/LoadMoreWaypoint'
import { fetchListActions } from '../../../redux/reducer/fetchList'
import { hasMoreFiltered } from '../../../util/hasMore'

const loadMoreData = (state: any) => hasMoreFiltered(state.fetchList)
const hasResultsData = (state: any) => state.fetchList.hasResults
const dataData = (state: any) => state.fetchList.data

const getData = createSelector(
  [loadMoreData, dataData, hasResultsData],
  (loadMore, data, hasResults) => ({ loadMore, data, hasResults })
)

export interface ListContainerProps {
  title?: string
  noResultsComponent?: FunctionComponent
  loadingComponent?: FunctionComponent
  children: (data: any) => ReactNode
}

export const ListContainer: FunctionComponent<ListContainerProps> = ({
  title,
  children,
  noResultsComponent,
  loadingComponent,
}) => {
  const dispatch = useDispatch()
  const fetchMoreListData = useCallback(
    () => dispatch(fetchListActions.fetchMoreList()),
    [dispatch]
  )
  const { loadMore, data, hasResults } = useSelector(getData)
  const NoResultsComponent = noResultsComponent || NoResultsRow
  const LoadingComponent = loadingComponent || LoadingRow

  if (!hasResults) {
    return <NoResultsComponent>{title || 'No results found'}</NoResultsComponent>
  }

  if (!data.length) {
    return <LoadingComponent />
  }

  return (
    <>
      {children(data)}
      {loadMore && <LoadMoreWaypoint onEnter={fetchMoreListData} />}
    </>
  )
}
