import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ToggleHead.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

const ToggleHead = ({ isOpen, className, ...rest }) => (
  <div
    className={classNames(className, isOpen ? styles.isOpen : '')}
    {...rest}
  />
)

ToggleHead.defaultProps = {
  isOpen: false,
  className: '',
}

ToggleHead.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

export default memo(withClassNames(ToggleHead, styles))
