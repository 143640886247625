import { toast, ToastOptions } from 'react-toastify'

import ToastCloseButton from '../Components/Toastify/ToastCloseButton'
import styles from '../Components/Toastify/Toastify_toast.module.scss'
import { toastDismiss } from './toastDismiss'

export const EXPLAIN_MODAL_TOAST = 'explainModal'

const DEFAULT_OPTIONS: ToastOptions = {
  autoClose: false,
  className: styles.styleToastInfo,
  closeButton: <ToastCloseButton />,
  closeOnClick: false,
  draggable: false,
  icon: false,
  position: 'top-right',
  type: 'info',
}

export function toastExplain(component, toastOptions?: ToastOptions) {
  const options = {
    ...DEFAULT_OPTIONS,
    ...toastOptions,
    toastId: EXPLAIN_MODAL_TOAST,
  }

  return () => {
    const timeout = toastDismiss(EXPLAIN_MODAL_TOAST) ? 300 : 0
    setTimeout(() => toast(component, options), timeout)
  }
}
