import { useCmsSwr } from '@gain/api/swr'
import { CustomerListItem, UserListItem } from '@gain/rpc/cms-model'
import { UserStatus } from '@gain/rpc/shared-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useDialogState } from '@gain/utils/dialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import { SwrDataGridRef } from '../../../common/swr-data-grid'
import UserInvitationsButton from '../../../Compositions/Actions/UserActions/user-invitations-button.component'
import UserCreateModal from '../../../Compositions/Modals/CreateModals/user-create-modal.component'
import UploadUsersDialog from '../../../Compositions/Modals/upload-users-dialog'
import { ItemPageContent } from '../../../layout/item-page'
import { LIST_USERS_METHOD } from '../../../util/methods'
import { toastCreate } from '../../../util/toastCreate'
import UsersList from '../../Lists/helpers/users-list'

export default function CustomerDetailUsersPage() {
  const customer: CustomerListItem = useSelector((state: any) => state.fetchItem.data)
  const dataGridRef = useRef<SwrDataGridRef>(null)
  const [bulkUploadUsersDialogOpen, handleDialogOpen, handleDialogClose] = useDialogState()

  // Fetch users that are not yet invited. This is done in the
  // parent component rather than in the UserInvitationsButton
  // directly so that it can be refreshed after user actions.
  const uninvitedUsers = useCmsSwr(LIST_USERS_METHOD, {
    filter: listFilters<UserListItem>(
      listFilter('customerId', '=', customer.id),
      listFilter('status', '=', UserStatus.NotInvited)
    ),
    sort: [],
    limit: 500,
    page: 0,
    search: '',
  })

  // Refresh the data grid and the uninvited users list
  const refresh = useCallback(() => {
    dataGridRef.current?.refresh()
    uninvitedUsers.mutate()
  }, [uninvitedUsers])

  const handleCloseBulkUploadDialog = () => {
    handleDialogClose()
    refresh()
  }

  return (
    <ItemPageContent variant={'grid'}>
      <UsersList
        actions={[
          <Box
            key={'user-actions'}
            display={'flex'}
            gap={1}>
            <UserInvitationsButton
              afterAction={refresh}
              customer={customer}
              users={uninvitedUsers.data?.items}
            />

            <Button
              onClick={toastCreate(
                <UserCreateModal
                  afterAction={refresh}
                  customer={customer}
                />
              )}
              variant={'contained'}>
              Create user
            </Button>

            {!customer.deactivated && (
              <Button
                onClick={handleDialogOpen}
                variant={'contained'}>
                Import user list
              </Button>
            )}
          </Box>,
        ]}
        dataGridRef={dataGridRef}
        filter={[listFilter('customerId', '=', customer.id)]}
      />

      <UploadUsersDialog
        handleClose={handleCloseBulkUploadDialog}
        open={bulkUploadUsersDialogOpen}
      />
    </ItemPageContent>
  )
}
