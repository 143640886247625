import { XIcon } from '@gain/components/icons'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { useLocalStorage } from '@gain/utils/storage'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { capitalize } from '@mui/material/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router'
import * as yup from 'yup'
import { InferType } from 'yup'

import { InputFieldSelect, InputFormProvider } from '../../../common/input-fields'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../../route-asset'
import AutomatedAssetForm, { AutomatedAssetFormRef } from './automated-asset-form'
import CuratedAssetForm, { CuratedAssetFormRef } from './curated-asset-form'

const StyledInputFieldSelect = styled(InputFieldSelect)({
  width: 173,
  minWidth: 173,
})

// The profile types that can be created in this dialog. Minimal profiles
// can no longer be created.
const PROFILE_TYPES = [
  AssetProfileType.Automated,
  AssetProfileType.Full,
  AssetProfileType.Limited,
] as const

// Extract ProfileType typings
type ProfileType = (typeof PROFILE_TYPES)[number]

// Define form validation schema
const formSchema = yup.object({
  profileType: yup.string().oneOf(PROFILE_TYPES).required(),
})

// Extract FormValues types from validation schema
type FormValues = InferType<typeof formSchema>

export interface CreateAssetDialogProps {
  show: boolean
  onClose: () => void

  // By default, the user is redirected to the created asset profile. This
  // behaviour can be overwritten by providing an onCreate callback function
  onCreate?: (assetId: number) => void
}

/**
 * This dialog contains 2 forms. One to create an automated asset profile and
 * the other one to create a curated profile. Both forms are always visible but
 * one will be disabled based on the selected profile type.
 */
export default function CreateAssetDialog({ show, onClose, onCreate }: CreateAssetDialogProps) {
  // Allow the user to dismiss the info dialog
  const [showInfo, setShowInfo] = useLocalStorage(
    'create-company-dialog-show-automated-profile-info',
    true
  )

  // Store most recently created profile type to allow the user to quickly
  // create new profiles based on the most recently used type
  const [initialProfileType, setInitialProfileType] = useLocalStorage<ProfileType>(
    'create-company-dialog-profile-type',
    AssetProfileType.Automated
  )

  const form = useForm<FormValues>({
    defaultValues: {
      profileType: initialProfileType,
    },
    resolver: yupResolver(formSchema),
  })

  const profileType = form.watch('profileType')
  const isAutomated = profileType === AssetProfileType.Automated

  // Store most recently selected profile type in local storage
  useEffect(() => {
    setInitialProfileType(profileType)
  }, [profileType, setInitialProfileType])

  // Use refs for both curated and automated forms. This allows us to submit
  // from within this component but keep the actual logic within designated
  // components
  const curatedAssetFormRef = useRef<CuratedAssetFormRef>(null)
  const automatedAssetFormRef = useRef<AutomatedAssetFormRef>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    // Determine which form to submit
    if (isAutomated) {
      await automatedAssetFormRef.current?.submit()
    } else {
      await curatedAssetFormRef.current?.submit()
    }
    setIsSubmitting(false)
  }, [isAutomated])

  const history = useHistory()

  const handleCreate = useCallback(
    (assetId: number) => {
      // Execute onCreate when provided, navigate to created profile otherwise
      if (onCreate) {
        onCreate(assetId)
      } else {
        history.push(
          generatePath(ROUTE_ASSET_KEY_FACTS_PATH, {
            id: assetId,
          })
        )
      }

      onClose()
    },
    [history, onClose, onCreate]
  )

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={show}
      fullWidth>
      <DialogTitle>Create a new company</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          {showInfo && (
            <Alert
              action={
                <IconButton onClick={() => setShowInfo(false)}>
                  <XIcon />
                </IconButton>
              }
              color={'info'}
              icon={false}>
              If a&nbsp;
              <Typography
                component={'span'}
                variant={'subtitle2'}>
                LinkedIn URL is available
              </Typography>
              , start by creating an automated profile. After that, you can convert this to a Full
              or Limited profile.
            </Alert>
          )}
          <Stack
            direction={'row'}
            gap={2}
            sx={{ pt: 1 }}>
            <InputFormProvider form={form}>
              <StyledInputFieldSelect
                defaultValue={profileType}
                label={'Profile type'}
                name={'profileType'}
                options={PROFILE_TYPES.map((type) => ({
                  label: capitalize(type),
                  value: type,
                }))}
              />
            </InputFormProvider>

            <AutomatedAssetForm
              ref={automatedAssetFormRef}
              disabled={!isAutomated}
              onCreate={handleCreate}
            />
          </Stack>

          <CuratedAssetForm
            ref={curatedAssetFormRef}
            onCreate={handleCreate}
            profileType={profileType}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClose}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={handleSubmit}
          variant={'contained'}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
