import { useRpcClient } from '@gain/api/swr'
import { AuthContext } from '@gain/modules/auth'
import { useUserProfileContext } from '@gain/modules/user'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'

import { appRpcClient } from '../../util/rpcClient'
import useLogout from '../../util/useLogout'

const Root = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  borderTop: '1px solid hsla(0,0%,100%,.1)',
  padding: theme.spacing(2, 1.5),

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(),
}))

export default function MainNavigationFooter() {
  const userProfile = useUserProfileContext()
  const authContext = useContext(AuthContext)
  const rpcClient = useRpcClient(appRpcClient)
  const logout = useLogout(authContext, rpcClient, true)

  return (
    <Root>
      <Typography
        color={'white'}
        fontWeight={'bold'}
        variant={'body2'}
        noWrap>
        {userProfile && `${userProfile.firstName} ${userProfile.lastName}`}
      </Typography>
      <Button
        onClick={logout}
        variant={'contained'}>
        Sign out
      </Button>
    </Root>
  )
}
