import { Url } from '@gain/rpc/cms-model'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { InputFieldText, InputFormProvider, urlSchema, useInputFormContext } from '../input-fields'
import { emptyInputFormContext } from '../input-fields/input-form-hooks'

interface URL {
  url: string | null
  isPrimary: boolean
  isIndexed: boolean
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  width: 500,
}))

interface URLInputModalProps {
  selectPrimary: boolean
  urls: Url[]
}

export default function URLInputModal({ urls, selectPrimary }: URLInputModalProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>()
  const { enqueueSnackbar } = useSnackbar()
  const inputForm = useInputFormContext()

  // Define default form values
  const defaultValues = useMemo(
    () => ({
      url: null,
      isPrimary: selectPrimary,
      isIndexed: true,
    }),
    [selectPrimary]
  )

  // Create form for a single URL
  const form = useForm<URL>({
    resolver: yupResolver(urlSchema),
    defaultValues,
  })

  // Sync the primary prop with the form
  useEffect(() => {
    form.setValue('isPrimary', selectPrimary)
  }, [selectPrimary, form])

  // Pick up any errors related to this new URL
  useEffect(() => {
    if (inputForm.issues) {
      const pathPrefix = `urls[${urls.length}].`
      for (const issue of inputForm.issues) {
        // Skip issues of other URLs
        if (!issue.path.startsWith(pathPrefix)) {
          continue
        }

        // Set the form errors
        form.setError(issue.path.replace(pathPrefix, '') as never, {
          message: issue.message,
        })
      }
    }
  }, [inputForm.issues, form, urls])

  // Submit the form when pressing add URL
  const handleSave = useCallback(() => {
    form.handleSubmit(async (values) => {
      const didCreateUrl = await inputForm.patch({
        urls: {
          push: values,
        },
      })

      // On success, close modal, reset form and notify of success
      if (didCreateUrl) {
        setAnchorEl(null)
        form.reset(defaultValues, {
          keepDirty: false,
          keepErrors: false,
          keepTouched: false,
          keepIsValid: false,
        })

        enqueueSnackbar('URL added!', {
          variant: 'success',
        })
      }
    })()
  }, [enqueueSnackbar, form, inputForm, setAnchorEl, defaultValues])

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Button
          color={'primary'}
          disabled={inputForm.disabled}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant={'contained'}>
          Add URL
        </Button>

        <StyledPopper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement={'bottom-end'}>
          <Paper>
            <InputFormProvider
              api={{
                ...emptyInputFormContext,
                disabled: inputForm.disabled,
              }}
              form={form}>
              <Stack
                gap={2}
                minWidth={300}
                p={2}
                pt={2}
                px={2}>
                <InputFieldText
                  label={'URL'}
                  name={'url'}
                />
                <Button
                  onClick={handleSave}
                  variant={'contained'}>
                  Add URL
                </Button>
              </Stack>
            </InputFormProvider>
          </Paper>
        </StyledPopper>
      </div>
    </ClickAwayListener>
  )
}
