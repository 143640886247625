import Accordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import { ReactNode, useContext } from 'react'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'

import { InputCollectionContext } from './input-collection'
import { useFieldName } from './input-form-hooks'

export interface InputCollectionAccordionProps {
  children: NonNullable<ReactNode>
}

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<{ hasError: boolean }>(({ theme, hasError }) => ({
  ...(hasError && {
    borderColor: theme.palette.error.dark,
  }),
}))

/**
 * Renders an accordion that can expand and show an error border
 * if any of the fields inside the collection has an error.
 */
export default function InputCollectionAccordion<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ children }: InputCollectionAccordionProps) {
  const form = useFormContext()
  const inputCollectionContext = useContext(InputCollectionContext)
  const parentField = useFieldName<TName>()

  const hasError = Boolean(form.getFieldState(parentField).error)

  return (
    <StyledAccordion
      expanded={inputCollectionContext.expandedAccordionName === parentField}
      hasError={hasError}
      onChange={(_, toExpanded) =>
        inputCollectionContext.changeExpandedAccordionName(parentField, toExpanded)
      }>
      {children}
    </StyledAccordion>
  )
}
