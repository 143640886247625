import Stack from '@mui/material/Stack'
import { ReactElement } from 'react'
import { Field } from 'react-final-form'

import InputFieldTextCharacterCount from '../../common/input-fields/input-field-text-character-count'
import InputError from '../../Components/FormElements/Error/InputError'
import Input from '../../Components/FormElements/Input/Input'
import { isEnter } from '../../util/isEnter'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import OnBlurSave from './helpers/OnBlurSave'

export interface InputStringProps {
  path: string
  title?: string
  type?: string
  after?: string | ReactElement
  maxLength?: number
  onBlurValue?: (value: string) => void
}

export default function InputString({
  path,
  title = '',
  after,
  type = 'text',
  maxLength,
  onBlurValue,
}: InputStringProps) {
  return (
    <>
      <Field
        name={path}
        type={type}>
        {({ input, meta }) => (
          <div style={{ position: 'relative' }}>
            <Input
              after={after}
              input={{
                ...input,
                onKeyUp: ({ key, target }) => {
                  if (isEnter(key)) {
                    target.blur()
                  }
                },
              }}
              placeholder={title}
            />
            {maxLength && (
              <Stack justifyContent={'end'}>
                <InputFieldTextCharacterCount
                  recommendedLength={maxLength}
                  text={input.value}
                />
              </Stack>
            )}
            <OnBlurSave
              dirty={!!meta.dirty}
              path={path}
              value={onBlurValue ? onBlurValue(input.value) : input.value}
            />
          </div>
        )}
      </Field>
      <ErrorContainer path={path}>
        {(onClick, hasErrors) => (
          <InputError
            hasErrors={hasErrors}
            onClick={onClick}
          />
        )}
      </ErrorContainer>
    </>
  )
}
