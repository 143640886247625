import { ROUTE_ASSETS_PATH } from '../route-assets/route-assets-path'

export enum RouteAssetTabs {
  KeyFacts = 'key-facts',
  Assessment = 'assessment',
  Background = 'background',
  Business = 'business',
  Financials = 'financials',
  Market = 'market',
  Sources = 'sources',
  TimeEntries = 'time-entries',
}

const ROUTE_ASSET_PATH = `${ROUTE_ASSETS_PATH}/:id`
export const ROUTE_ASSET_TABS_PATH = `${ROUTE_ASSET_PATH}/:tab?`
export const ROUTE_ASSET_KEY_FACTS_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.KeyFacts}`
export const ROUTE_ASSET_ASSESSMENT_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Assessment}`
export const ROUTE_ASSET_BACKGROUND_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Background}`
export const ROUTE_ASSET_BUSINESS_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Business}`
export const ROUTE_ASSET_FINANCIALS_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Financials}`
export const ROUTE_ASSET_MARKET_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Market}`
export const ROUTE_ASSET_SOURCES_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.Sources}`
export const ROUTE_ASSET_TIME_ENTRIES_PATH = `${ROUTE_ASSET_PATH}/${RouteAssetTabs.TimeEntries}`

export const ROUTE_ASSET_PREVIEW_PATH = '/app/asset/:id/:name'
