import PropTypes from 'prop-types'
import InputCheckbox from '../../Inputs/InputCheckbox'
import SpacedItems, {
  SPACED_ITEMS_STYLENAME_NOWRAP,
} from '../../../Components/SpacedItems/SpacedItems'
import FlexItem from '../../../Components/Tabular/FlexTable/FlexItem'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import InputTextArea from '../../Inputs/InputTextArea'
import InputNumber from '../../Inputs/InputNumber'
import SourceSelect from '../../Selects/SourceSelect'
import InputString from '../../Inputs/input-string.component'
import Divider from '../../../Components/Divider/Divider'

const SourceForm = ({ name, usedFor }) => (
  <div>
    <FlexContainer>
      <FlexItem>
        <div>
          <InputTextArea
            rows={2}
            path={`${name}.title`}
            title="Title"
          />
        </div>
      </FlexItem>
    </FlexContainer>
    <Divider style={{ margin: '8px 0' }} />
    <FlexContainer>
      <FlexItem>
        <FormLabel styleName={FORMLABEL_SECONDARY}>Source</FormLabel>
        <SourceSelect path={`${name}.publisher`} />
      </FlexItem>
      <FlexItem>
        <FormLabel styleName={FORMLABEL_SECONDARY}>Publication date</FormLabel>
        <SpacedItems styleName={SPACED_ITEMS_STYLENAME_NOWRAP}>
          <InputNumber
            path={`${name}.publicationYear`}
            title="Year"
          />
          <InputNumber
            path={`${name}.publicationMonth`}
            title="Month"
            step={1}
            min={1}
            max={12}
          />
        </SpacedItems>
      </FlexItem>
    </FlexContainer>
    <Divider style={{ margin: '8px 0' }} />
    <FlexContainer>
      <FlexItem>
        <div>
          <FormLabel styleName={FORMLABEL_SECONDARY}>URL</FormLabel>
          <InputString
            path={`${name}.url`}
            title="URL"
          />
        </div>
      </FlexItem>

      {usedFor.length > 0 && (
        <FlexItem>
          <FormLabel styleName={FORMLABEL_SECONDARY}>Used for</FormLabel>
          <SpacedItems>
            {usedFor.map((uses) => (
              <KeyboardInput
                key={uses.name}
                style={{
                  width: 'auto',
                }}>
                <InputCheckbox
                  path={`${name}.${uses.name}`}
                  title={uses.title}
                />
              </KeyboardInput>
            ))}
          </SpacedItems>
        </FlexItem>
      )}
    </FlexContainer>
  </div>
)

SourceForm.propTypes = {
  name: PropTypes.string.isRequired,
  usedFor: PropTypes.array.isRequired,
}

export default SourceForm
