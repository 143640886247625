import { useCurrencies } from '@gain/cms/api'
import { FieldPath, FieldValues } from 'react-hook-form'

import InputFieldSelect, { InputFieldSelectProps } from './input-field-select'

export type InputFieldCurrencyProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<InputFieldSelectProps<TFieldValues, TName>, 'options'>

export default function InputFieldCurrency<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: InputFieldCurrencyProps<TFieldValues, TName>) {
  const swrCurrencies = useCurrencies({
    limit: 100,
  })

  return (
    <InputFieldSelect
      options={
        swrCurrencies.data?.items?.map(({ name, symbol }) => ({
          label: `${name} (${symbol})`,
          value: name,
        })) ?? []
      }
      {...props}
    />
  )
}
