import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { DragDropOrdering } from '../DragDropOrdering/drag-drop-ordering'
import AddInputTextArea from './helpers/AddInputTextArea'
import subscription from '../UpdateForm/helpers/subscription'
import DeleteArrayItemComposition from '../ItemActions/delete-array-item-composition'
import DeleteInput from '../../Components/FormElements/Input/DeleteInput'
import TextAreaWithDelete from '../../Components/FormElements/Input/TextAreaWithDelete'
import MethodContext from '../../Context/method.context'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import InputError from '../../Components/FormElements/Error/InputError'

const MultiInputTextArea = ({ path, max, rows, title, maxLength }) => {
  return (
    <MethodContext.Consumer>
      {({ disabled }) => (
        <div>
          <DragDropOrdering path={path}>
            {(fields, name) => (
              <TextAreaWithDelete
                style={{ margin: 8 }}
                path={`${name}.text`}
                rows={rows}
                maxLength={maxLength}
                after={
                  !disabled && (
                    <DeleteArrayItemComposition path={name}>
                      {(deleteItem) => <DeleteInput onClick={() => deleteItem()} />}
                    </DeleteArrayItemComposition>
                  )
                }
              />
            )}
          </DragDropOrdering>
          {!disabled && (
            <FieldArray
              subscription={subscription}
              name={path}>
              {({ fields }) =>
                fields.length < max && (
                  <div style={{ margin: 8 }}>
                    <AddInputTextArea
                      length={fields.length}
                      path={path}
                      title={title}
                      rows={rows}
                      maxLength={maxLength}
                    />
                  </div>
                )
              }
            </FieldArray>
          )}
          <ErrorContainer path={path}>
            {(onClick, hasErrors) => (
              <InputError
                onClick={onClick}
                hasErrors={hasErrors}
              />
            )}
          </ErrorContainer>
        </div>
      )}
    </MethodContext.Consumer>
  )
}
MultiInputTextArea.propTypes = {
  path: PropTypes.string.isRequired,
  rows: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
  maxLength: PropTypes.number,
}

MultiInputTextArea.defaultProps = {
  rows: 2,
  title: 'Add',
  max: Number.MAX_SAFE_INTEGER,
  maxLength: undefined,
}

export default MultiInputTextArea
