import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './ToggleBody.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

const ToggleBody = ({ className, isOpen, children, ...rest }) => (
  <div
    className={classNames(className, isOpen ? styles.isOpen : '')}
    {...rest}>
    {isOpen ? children : null}
  </div>
)

ToggleBody.defaultProps = {
  isOpen: false,
  className: '',
}

ToggleBody.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
}

export default memo(withClassNames(ToggleBody, styles))
