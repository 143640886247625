import Chip from '@mui/material/Chip'

import {
  getSWRRowStatusColor,
  getSWRRowStatusLabel,
  SwrRowStatusType,
} from './swr-row-status-utils'

interface SWRRowStatusProps {
  status?: SwrRowStatusType
}

export default function SWRRowStatus({ status }: SWRRowStatusProps) {
  return (
    <Chip
      color={getSWRRowStatusColor(status)}
      label={getSWRRowStatusLabel(status)}
      size={'small'}
      variant={'outlined'}
    />
  )
}
