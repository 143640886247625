import PropTypes from 'prop-types'
import styles from './MainNavSectionTitle.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import Text, { SIZE_CAPTION_3 } from '../../Text/Text'
import { COLORNAME_TEXT_SECONDARY } from '../../../util/colors'
import { memo } from 'react'

const MainNavSectionTitle = ({ children, ...rest }) => (
  <div {...rest}>
    <Text
      size={SIZE_CAPTION_3}
      colorName={COLORNAME_TEXT_SECONDARY}
      style={{ textTransform: 'uppercase' }}>
      {children}
    </Text>
  </div>
)

MainNavSectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
}

export default memo(withClassNames(MainNavSectionTitle, styles))
