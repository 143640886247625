import { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'

import InputLabelConsistencyGuideInfo from './input-label-consistency-guide-info'

interface ConsistencyGuideProps {
  consistencyGuideName: string
  consistencyGuideLabel: string
}

interface HelperTextProps {
  helperText?: ReactNode | string | null | undefined
}

function isConsistencyGuideProps(props: InputFieldHelperTextProps): props is ConsistencyGuideProps {
  return 'consistencyGuideName' in props
}

function isHelperTextProps(props: InputFieldHelperTextProps): props is HelperTextProps {
  return 'helperText' in props
}

type InputFieldHelperTextProps = (ConsistencyGuideProps | HelperTextProps) & {
  errorMessage?: string | null | undefined
}

/**
 * Used to render the error for any input field given a valid name. This helper
 * supports messages formatted with markdown. Markdown is sometimes used to
 * include URL's to other entity types for examples.
 */
export default function InputFieldHelperText(props: InputFieldHelperTextProps) {
  const { errorMessage } = props

  // Render error message as markdown if it's defined
  if (errorMessage) {
    return <ReactMarkdown children={errorMessage} />
  }

  if (isConsistencyGuideProps(props)) {
    return (
      <InputLabelConsistencyGuideInfo
        label={props.consistencyGuideLabel}
        name={props.consistencyGuideName}
      />
    )
  }

  if (isHelperTextProps(props)) {
    return props.helperText
  }

  return null
}
