import { ReactNode, useCallback } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { BUTTON_STYLENAME_PILL } from '../../../../Components/Buttons/Button'
import { FormButton } from '../../../../Components/Buttons/FormButton/FormButton'
import ModalBox from '../../../../Components/Modal/ModalBox'
import ModalBoxFoot from '../../../../Components/Modal/ModalBoxFoot'
import { createItem } from '../../../../redux/action/createItem'

interface CreateFormProps<FormValues = never, InitialFormValues = Partial<FormValues>> {
  initialValues: InitialFormValues
  getValues?: (values: FormValues) => FormValues
  children: (form: FormRenderProps<FormValues, InitialFormValues>) => ReactNode
  buttonLabel?: string
  asPartial?: boolean
  method: string
  next: () => void
}

export default function CreateForm<FormValues = never, InitialFormValues = Partial<FormValues>>({
  initialValues = {} as InitialFormValues,
  getValues = (values: FormValues) => ({ ...values }),
  children,
  buttonLabel = 'Create',
  asPartial = true,
  method,
  next,
}: CreateFormProps<FormValues, InitialFormValues>) {
  const dispatch = useDispatch()

  const handleCreateItem = useCallback(
    (body, callback) => {
      dispatch(createItem(method, body, callback, next))
    },
    [dispatch, method, next]
  )

  return (
    <Form<FormValues, InitialFormValues>
      initialValues={initialValues}
      onSubmit={(values, _, callback) =>
        handleCreateItem(asPartial ? { partial: getValues(values) } : getValues(values), callback)
      }>
      {(form) => (
        <form onSubmit={form.handleSubmit}>
          <ModalBox>
            {children(form)}
            <ModalBoxFoot>
              <FormButton
                disabled={
                  !form.dirtySinceLastSubmit && (form.submitting || form.pristine || form.invalid)
                }
                spinner={form.submitting}
                styleName={BUTTON_STYLENAME_PILL}>
                {buttonLabel}
              </FormButton>
            </ModalBoxFoot>
          </ModalBox>
        </form>
      )}
    </Form>
  )
}
