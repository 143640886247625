import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { parseISO } from 'date-fns/parseISO'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldDateProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> extends Pick<DatePickerProps<any>, 'disableFuture' | 'slotProps' | 'views' | 'maxDate'> {
  name: TName
  label: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  required?: boolean
  disabled?: boolean
}

function transformDate(date: string | Date | null): null | Date {
  if (typeof date === 'string') {
    return parseISO(date)
  }

  return date
}

export default function InputFieldDate<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  defaultValue,
  disabled,
  ...dateFieldProps
}: InputFieldDateProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleNormalizeDate = (date: Date | null) => {
    if (date === null) {
      return null
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  }

  return (
    <DatePicker
      {...dateFieldProps}
      ref={field.ref}
      disabled={disabled || inputForm.disabled}
      label={label}
      name={field.name}
      onAccept={() => {
        // Call the onBlur so the value is validated correctly after one uses the icon to select a date
        inputForm.onBlur(fieldName, field.onBlur, true)()
      }}
      onChange={(newValue: null | Date) =>
        field.onChange(handleNormalizeDate(newValue) as FieldPathValue<TFieldValues, TName>)
      }
      slotProps={{
        ...dateFieldProps.slotProps,
        textField: {
          error: Boolean(fieldState.error),
          helperText: fieldState.error && fieldState.error.message,
          required,
          variant: 'outlined',
          fullWidth: true,
          onBlur: inputForm.onBlur(fieldName, field.onBlur),
        },
      }}
      value={transformDate(field.value || null)}
    />
  )
}
