import PropTypes from 'prop-types'
import Text from '../../../../Components/Text/Text'
import { COLOR_SUPPORT_4 } from '../../../../util/colors'

export const PrintFieldError = ({ error, submitError, touched }) => (
  <Text size="caption-2">
    {(error || submitError) && touched && (
      <span
        style={{
          color: COLOR_SUPPORT_4,
          padding: '2px 0 0 8px',
          display: 'inline-block',
        }}>
        {error || submitError}
      </span>
    )}
  </Text>
)

PrintFieldError.propTypes = {
  error: PropTypes.string,
  submitError: PropTypes.string,
  touched: PropTypes.bool.isRequired,
}

PrintFieldError.defaultProps = {
  error: undefined,
  submitError: undefined,
}
