import { InvestorStatus } from '@gain/rpc/shared-model'
import { ChipProps } from '@mui/material/Chip'

export type SwrRowStatusType = InvestorStatus

export function getSWRRowStatusColor(status?: SwrRowStatusType): ChipProps['color'] {
  switch (status) {
    case InvestorStatus.Archived:
      return 'error'

    case InvestorStatus.Published:
      return 'success'

    case InvestorStatus.New:
    case InvestorStatus.Review:
    default:
      return 'info'
  }
}

export function getSWRRowStatusLabel(status?: SwrRowStatusType) {
  switch (status) {
    case InvestorStatus.Archived:
      return 'Archived'

    case InvestorStatus.Published:
      return 'Published'

    case InvestorStatus.New:
      return 'New'

    case InvestorStatus.Review:
      return 'Review'

    default:
      return 'unknown'
  }
}
