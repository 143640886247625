import { FunctionComponent, memo } from 'react'

import ListButton from '../../../Components/Buttons/ListButton'
import { pathGenerate } from '../../../util/pathGenerate'

export interface EditProps {
  id: number
  path: string
}

export const Edit: FunctionComponent<EditProps> = memo(({ id, path }) => (
  <ListButton
    to={pathGenerate(path, {
      id,
    })}>
    Edit
  </ListButton>
))
