import PropTypes from 'prop-types'
import CreatableInputSelect from './CreatableSelect/CreatableInputSelect'
import sourceOptions from '../../json/sources.json'

const SourceSelect = ({ path }) => (
  <CreatableInputSelect
    options={sourceOptions}
    path={path}
    placeholder="source"
  />
)

SourceSelect.propTypes = {
  path: PropTypes.string.isRequired,
}

export default SourceSelect
