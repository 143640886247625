import {
  GridFooterContainer,
  GridFooterContainerProps,
  useGridRootProps,
} from '@mui/x-data-grid-pro'

import { useSwrDataGrid } from '../swr-data-grid.hooks'

export default function SwrDataGridFooter(props: GridFooterContainerProps) {
  const rootProps = useGridRootProps()
  const swrDataGrid = useSwrDataGrid()

  return (
    <GridFooterContainer {...props}>
      <div />

      {swrDataGrid.bulkActions}

      {rootProps.slots.pagination && (
        <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
      )}
    </GridFooterContainer>
  )
}
