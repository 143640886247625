import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledTypography = styled(Typography)({
  maxWidth: 'fit-content',
  alignSelf: 'end',
})

interface InputFieldTextCharacterCountProps {
  text?: string
  recommendedLength?: number
}

export default function InputFieldTextCharacterCount({
  text,
  recommendedLength,
}: InputFieldTextCharacterCountProps) {
  const theme = useTheme()

  if (!text || !recommendedLength) {
    return null
  }

  const length = text.length

  let color = theme.palette.text.secondary
  if (length > recommendedLength) {
    color = theme.palette.error.main
  }

  return (
    <StyledTypography
      color={color}
      variant={'overline'}>
      {length} / {recommendedLength}
    </StyledTypography>
  )
}
