import PropTypes from 'prop-types'
import ButtonsList from '../../Components/Buttons/ButtonsList'
import { Edit } from './Actions/Edit'
import Delete from './Actions/Delete'
import StatusToggle from './Actions/StatusToggle'
import isDisabled from '../../util/is-disabled'
import { DEFAULT_INDUSTRIES_LIST_PATH } from '../../util/pathDefaults'
import { INDUSTRY_GENERAL_PATH } from '../../util/path'
import { openCreateNewsItems } from '../../redux/action/openCreateNewsItems'
import IndustryPublishedModal from '../Modals/IndustryPublishedModal'
import { memo } from 'react'

const IndustryActions = ({ data, listPage }) => (
  <ButtonsList>
    {listPage && (
      <Edit
        id={data.id}
        path={INDUSTRY_GENERAL_PATH}
      />
    )}
    {listPage && (
      <StatusToggle
        on={isDisabled(data)}
        title="industry research"
        next={openCreateNewsItems(<IndustryPublishedModal id={data.id} />)}
      />
    )}
    <Delete
      name={data.title ? `the ${data.title} industry research` : 'this industry research'}
      title="industry research"
      path={DEFAULT_INDUSTRIES_LIST_PATH}
    />
  </ButtonsList>
)

IndustryActions.propTypes = {
  data: PropTypes.object.isRequired,
  listPage: PropTypes.bool,
}

IndustryActions.defaultProps = {
  listPage: false,
}

export default memo(IndustryActions)
