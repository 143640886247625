import { UserStatus } from '@gain/rpc/shared-model'

export const userStatuses = {
  [UserStatus.NotInvited]: { label: 'Not invited', color: 'warning' },
  [UserStatus.Invited]: { label: 'Invited', color: 'info' },
  [UserStatus.Deactivated]: { label: 'Deactivated', color: 'error' },
  [UserStatus.Active]: { label: 'Active', color: 'success' },
} as const

export function getUserStatus(userStatus: UserStatus) {
  return userStatuses[userStatus]
    ? userStatuses[userStatus]
    : ({ label: 'Unknown', color: 'error' } as const)
}
