import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useInputFieldIssues } from '../../common/input-fields/input-field-hooks'

const StyledIssueDot = styled('span')({
  width: 6,
  height: 6,
  borderRadius: '50%',
})

export interface TabConfig {
  id: string
  label: string
  path: string
  fieldNames?: string[]
}

interface ItemPageTabProps {
  tab: TabConfig
  value: string | number
}

/**
 * ItemPageTab is a custom wrapper around MUI's `Tab`. MUI doesn't play very well
 * with custom wrappers around `Tab`. In the `Tabs` implementation, MUI simply
 * looks at the direct children to read the `value` prop. So adding it here on
 * `Tab` is useless. Add the `value` prop on this `ItemPageTab` instead.
 */
export default function ItemPageTab({ tab }: ItemPageTabProps) {
  const params = useParams<{ tab?: string }>()
  const issues = useInputFieldIssues(...(tab.fieldNames || []))

  return (
    <Tab
      aria-controls={`nav-tabpanel-${tab.id}`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      id={`nav-tab-${tab.id}`}
      label={
        <>
          {tab.label}

          <Stack
            direction={'row'}
            gap={1}
            mb={-1}
            mt={0.5}>
            {issues.hasErrors && <StyledIssueDot sx={{ bgcolor: 'error.main' }} />}
            {issues.hasWarnings && <StyledIssueDot sx={{ bgcolor: 'warning.main' }} />}
          </Stack>
        </>
      }
      to={generatePath(tab.path, params)}
      replace
    />
  )
}
