import { Option } from '@gain/rpc/shared-model'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label: string
  options: ReadonlyArray<Option<string>>

  defaultValue?: FieldPathValue<TFieldValues, TName>
  required?: boolean

  direction?: 'row' | 'column'
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export default function InputFieldRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  options,
  direction = 'column',
  defaultValue,
}: InputFieldRadioGroupProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    (event) => {
      field.onChange(event)

      inputForm.onBlur(fieldName, field.onBlur, true)(event)
    },
    [field, fieldName, inputForm]
  )

  return (
    <StyledFormControl>
      <FormLabel required={required}>{label}</FormLabel>

      <StyledRadioGroup
        onChange={handleChange}
        row={direction === 'row'}
        value={field.value}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={<Radio disabled={inputForm.disabled} />}
            label={option.label}
            value={option.value}
          />
        ))}
      </StyledRadioGroup>
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </StyledFormControl>
  )
}
