import { RpcListMethods } from '@gain/rpc/cms-model'
import { isCmdClickEvent } from '@gain/utils/event'
import { GridRowParams } from '@mui/x-data-grid/models/params'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { MouseEvent, useCallback } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router'

import { SwrDataGridApiOptions } from './swr-data-grid.api'

export default function useSwrDataGridRowClick<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({ path }: SwrDataGridApiOptions<Method, Row>): Partial<DataGridProProps> {
  const routeMatch = useRouteMatch()
  const history = useHistory()

  const handleRowClick = useCallback(
    (params: GridRowParams, event: MouseEvent<HTMLElement>) => {
      // If we click on a link inside the data grid than we follow that link
      if ((event.target as HTMLAnchorElement).href) {
        return
      }

      const pathname = generatePath(path?.startsWith('/') ? path : `${routeMatch.url}/${path}`, {
        ...routeMatch.params,
        ...params.row,
      })

      if (isCmdClickEvent(event)) {
        window.open(history.createHref({ pathname }))
      } else {
        history.push(pathname)
      }
    },
    [history, path, routeMatch.params, routeMatch.path]
  )

  if (!path) {
    return {}
  }

  return {
    onRowClick: handleRowClick,
  }
}
