import { LinkedInIcon } from '@gain/components/icons'
import { PersonListItem } from '@gain/rpc/cms-model'
import { addHttpsIfMissing } from '@gain/utils/common'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { useRef } from 'react'

import InputForm from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import LayoutDataGrid from '../../layout/data-grid'
import { ROUTE_PERSON_PROFILE_PATH } from '../route-person/route-person-path'
import CreatePersonButton from './create-person-button'

export default function RoutePersons() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  return (
    <LayoutDataGrid title={'People'}>
      <Card>
        <SwrDataGrid
          ref={dataGridRef}
          actions={[<CreatePersonButton />]}
          columns={[
            {
              field: 'firstName',
              headerName: 'First Name',
              flex: 1,
              filterable: false,
            },
            {
              field: 'lastName',
              headerName: 'Last Name',
              flex: 1,
              filterable: false,
            },
            {
              field: 'linkedInUrl',
              headerName: 'LinkedIn',
              flex: 1,
              filterable: false,
              maxWidth: 100,
              renderCell: ({ value }: GridRenderCellParams<PersonListItem>) =>
                value ? (
                  <IconButton
                    href={addHttpsIfMissing(value)}
                    rel={'noopener noreferrer'}
                    size={'small'}
                    sx={{ fontSize: 16 }}
                    target={'_blank'}>
                    <LinkedInIcon fontSize={'inherit'} />
                  </IconButton>
                ) : null,
            },
            {
              field: 'birthYear',
              headerName: 'Age',
              valueFormatter: (value) =>
                value ? new Date().getFullYear() - parseInt(value, 10) : '-',
              flex: 1,
              filterable: false,
            },
            {
              field: 'investorName',
              headerName: 'Investor',
              flex: 1,
              filterable: false,
            },
            {
              field: 'assets',
              headerName: 'Companies',
              flex: 1,
              filterable: false,
              sortable: false,
              valueFormatter: (value: PersonListItem['assets']) => (value ? value.length : '-'),
            },
            {
              field: 'live',
              headerName: 'Published',
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Published' : 'Draft'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'publishedAt',
              headerName: 'Published at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'unpublishedAt',
              headerName: 'Unpublished at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },
          ]}
          label={'People'}
          method={'data.listPersons'}
          path={ROUTE_PERSON_PROFILE_PATH}
          renderRow={({ rowId, row }, children) => (
            <InputForm
              deleteMethod={'data.deletePerson'}
              isDisabled={() => row.live}
              name={'person'}
              publishMethod={'data.publishPerson'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishPerson'}
              validateMethod={'data.validatePerson'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'firstName', direction: 'asc' }]}
          disableColumnFilter
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
