import { snakeCase } from 'lodash'

import Markdown from '../../common/markdown'
import InputError from '../../Components/FormElements/Error/InputError'
import FormLabel from '../../Components/FormElements/FormLabel/FormLabel'
import { ICON_QUESTIONMARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import SpacedItems from '../../Components/SpacedItems/SpacedItems'
import { getFromConsistencyGuide } from '../../features/consistency-guide'
import NotificationModal, {
  NOTIFICATION_MODAL_TYPE_HELP,
} from '../../Modals/Notification/NotificationModal'
import { toastExplain } from '../../util/toastExplain'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'

export interface FormLabelCompositionProps {
  yml?: string
  path?: string
  styleName?: string
  children?: string
}

export function FormLabelComposition({
  children,
  yml,
  path,
  styleName,
  ...rest
}: FormLabelCompositionProps) {
  const markdown = getFromConsistencyGuide(yml || snakeCase(children))

  return (
    <>
      {markdown ? (
        <SpacedItems
          onClick={toastExplain(
            <NotificationModal
              body={<Markdown source={markdown} />}
              header={children}
              modalType={NOTIFICATION_MODAL_TYPE_HELP}
            />,
            {
              icon: false,
            }
          )}
          style={{ display: 'inline-flex' }}>
          <FormLabel
            styleName={styleName}
            {...rest}>
            {children}
          </FormLabel>
          <div style={{ alignSelf: 'flex-start', marginTop: styleName ? 8 : 3 }}>
            <IconInCircle
              colorStyle={'textSecondary'}
              icon={ICON_QUESTIONMARK}
              size={'small'}
            />
          </div>
        </SpacedItems>
      ) : (
        <FormLabel
          styleName={styleName}
          {...rest}>
          {children}
        </FormLabel>
      )}
      {path && (
        <ErrorContainer path={path}>
          {(onClick, hasErrors) => (
            <InputError
              hasErrors={hasErrors}
              onClick={onClick}
              style={{
                marginTop: '-32px',
              }}
            />
          )}
        </ErrorContainer>
      )}
    </>
  )
}
