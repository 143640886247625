import { AnnualReport } from '@gain/rpc/cms-model'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { getLatestAnnualReport } from '../../../../../libs/utils/annual-report/src/get-latest-annual-report'
import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import {
  doesAnnualReportDownloadIncursCosts,
  downloadAnnualReportsSinceYear,
} from '../../util/annual-report'
import { toastCreate } from '../../util/toastCreate'
import { toastDismiss } from '../../util/toastDismiss'

const CONFIRM_DOWNLOAD_NL_REPORTS = 'TOAST_CONFIRM_DOWNLOAD_NL_REPORTS'

interface ConfirmDownloadNewReportsModalProps {
  onSubmit: (since: number) => void
  region: string
  annualReports: AnnualReport[]
}

type downloadType = 'All' | 'Latests'
interface FormData {
  downloadType: downloadType
}

const FormSchema = Yup.object().shape({
  downloadType: Yup.string().oneOf(['All', 'Latests']).required('Required'),
})

/**
 * Modal that is shown when people try to download new annual reports
 * for regions that would incur costs (eg: NL and ES)
 */
function ConfirmDownloadNewReportsModal(props: ConfirmDownloadNewReportsModalProps) {
  const { onSubmit, region, annualReports } = props
  const latestAnnualReport = getLatestAnnualReport(annualReports)
  const sinceLatestAnnualReport = getLatestAnnualReport(annualReports)?.bookYearEnd?.substring(0, 4)
  let since: number | null

  const form = useForm<FormData>({
    defaultValues: {
      downloadType: 'Latests',
    },
    resolver: yupResolver(FormSchema),
  })

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    if (formData.downloadType === 'All') {
      since = downloadAnnualReportsSinceYear(region)

      onSubmit(since)
    } else if (formData.downloadType === 'Latests') {
      if (latestAnnualReport != null) {
        since = Number(sinceLatestAnnualReport)
      } else {
        since = downloadAnnualReportsSinceYear(region)
      }
      onSubmit(since)
    }
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <ModalBox>
        <ModalBoxHead>Download new reports</ModalBoxHead>
        <ModalBoxBody style={{ padding: 24 }}>
          <InputGroupItem>
            <p style={{ marginBottom: '8px' }}>
              {' '}
              {latestAnnualReport
                ? `Downloading new reports for ${region} will incur costs. Please consider selecting 'Annual reports
                since ${sinceLatestAnnualReport}' if previous reports were downloaded, otherwise select 'Annual
                reports since ${downloadAnnualReportsSinceYear(
                  region
                )}' to download annual reports over the last 5 years.`
                : `Downloading reports for ${region} will incur costs. If you wish to download all annual
                reports since ${downloadAnnualReportsSinceYear(region)}, select 'Continue'.`}
            </p>
            {latestAnnualReport && (
              <div>
                <FormControl component={'fieldset'}>
                  <Controller
                    control={form.control}
                    name={'downloadType'}
                    render={({ field }) => {
                      return (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            control={<Radio />}
                            label={`Annual reports since ${downloadAnnualReportsSinceYear(region)}`}
                            value={'All'}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label={`Annual reports since ${sinceLatestAnnualReport}`}
                            value={'Latests'}
                          />
                        </RadioGroup>
                      )
                    }}
                    rules={{ required: true }}
                  />
                </FormControl>
              </div>
            )}
          </InputGroupItem>
        </ModalBoxBody>
        <ModalBoxFoot>
          <FormButton
            disabled={!form.formState.isValid}
            spinner={false}
            styleName={BUTTON_STYLENAME_PILL}
            type={'submit'}>
            Continue
          </FormButton>
        </ModalBoxFoot>
      </ModalBox>
    </form>
  )
}

/**
 * Handles clicking a download button. Will not do anything if a
 * download is already in progress. If the region is NL, the user
 * is prompted using the Modal above to confirm the action. If it
 * is another region, the download will be started directly.
 */
export function confirmAndDownloadReports(
  isDownloading: boolean,
  region: string,
  annualReports: AnnualReport[],
  downloadCallback: (since?: number) => void
) {
  // Halt if a download is already in progress
  if (isDownloading) {
    return
  }

  if (doesAnnualReportDownloadIncursCosts(region)) {
    toastCreate(
      <ConfirmDownloadNewReportsModal
        annualReports={annualReports}
        onSubmit={(since) => {
          toastDismiss(CONFIRM_DOWNLOAD_NL_REPORTS)
          downloadCallback(since)
        }}
        region={region}
      />,
      CONFIRM_DOWNLOAD_NL_REPORTS
    )()
  } else {
    downloadCallback(downloadAnnualReportsSinceYear(region))
  }
}
