import { useFileInfo, useFileUpload } from '@gain/cms/api'
import { apiDomain } from '@gain/rpc/utils'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'
import { styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import { PropsWithChildren, useCallback, useRef } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export const ACCEPT_ALL_FILES: Accept = {
  'application/pdf': [],
  'image/gif': [],
  'image/png': [],
  'image/jpeg': [],
  'application/json': [],
  'text/html': [],
  'text/xml': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'image/tiff': [],
  'text/plain': [],
  'text/csv': [],
}

export const ACCEPT_IMAGE_FILES: Accept = {
  'image/*': [],
}

export const ACCEPT_CSV_FILES: Accept = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.csv'],
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${formLabelClasses.root}`]: {
    padding: theme.spacing(0, 1.5),
  },
}))

const ImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{
  isDragActive: boolean
  disabled: boolean
}>(({ theme, isDragActive, disabled }) => ({
  position: 'relative',
  border: `${isDragActive ? 'dashed' : 'solid'} 1px ${theme.palette.divider}`,
  borderRadius: theme.spacing(),
  maxWidth: 200,
  minWidth: 200,
  minHeight: 150,
  padding: theme.spacing(),
  cursor: disabled ? 'initial' : 'pointer',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    width: '100%',
  },

  ...(disabled && {
    opacity: 0.6,
  }),

  ...(isDragActive && {
    [`& div:not(.${typographyClasses.root})`]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.white,
      opacity: 0.9,
    },
  }),

  [`& .${typographyClasses.root}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(0.5),
}))

export interface InputFieldFileProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label: string

  secure?: boolean
  accept?: Accept

  onUpload?: (fileId: number) => void
  disableImagePreview?: boolean
  disabled?: boolean
  required?: boolean
}

export default function InputFieldFile<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  accept,
  secure,
  disableImagePreview,
  onUpload,
  disabled,
  required,
  children,
}: PropsWithChildren<InputFieldFileProps<TFieldValues, TName>>) {
  const inputForm = useInputFormContext()
  const { watch } = useFormContext()
  const fieldName = useFieldName<TName>(name)

  const uploadingRef = useRef(false)

  const fileId = watch(name) as number | undefined
  const swrfile = useFileInfo(fileId ?? null)
  const fileUrl = swrfile?.data?.url

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
  })

  const uploadFile = useFileUpload()

  const handleOnDrop = useCallback(
    async (event) => {
      const file = event.target ? event.target.files[0] : event[0]

      if (!file || uploadingRef.current) {
        return
      }

      // Prevents uploading multiple times at the same time
      uploadingRef.current = true

      const uploadedFileId = await uploadFile(file, { secure: Boolean(secure) })

      if (uploadedFileId) {
        field.onChange(uploadedFileId as FieldPathValue<TFieldValues, TName>)
        await inputForm.onBlur(fieldName, field.onBlur)(event)

        onUpload?.(uploadedFileId)
      }

      uploadingRef.current = false
    },
    [field, fieldName, inputForm, onUpload, secure, uploadFile]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    disabled: inputForm.disabled || disabled,
    accept,
  })

  return (
    <StyledFormControl
      disabled={disabled || inputForm.disabled}
      error={Boolean(fieldState.error)}>
      <FormLabel required={required}>{label}</FormLabel>

      <ImageContainer
        {...getRootProps()}
        disabled={disabled || inputForm.disabled}
        isDragActive={isDragActive}>
        <input
          {...getInputProps()}
          readOnly={inputForm.disabled || disabled}
        />

        {!disableImagePreview && fileUrl && (
          <img
            alt={fieldName}
            src={fileUrl}
          />
        )}

        {!disabled && disableImagePreview && fileUrl && (
          <Button
            component={'a'}
            href={fileUrl.startsWith('http') ? fileUrl : `${apiDomain}${fileUrl}`}
            onClick={(e) => e.stopPropagation()}
            target={'_blank'}>
            Download
          </Button>
        )}

        <div />

        {isDragActive && <Typography variant={'caption'}>Drop your files here</Typography>}
        {!fileUrl && !isDragActive && !children && (
          <Typography variant={'caption'}>Click here or drop files to upload</Typography>
        )}

        {children}
      </ImageContainer>

      {fileUrl && <FormHelperText>Click here or drop files to upload</FormHelperText>}
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </StyledFormControl>
  )
}
