import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { PropsWithChildren, ReactNode, useState } from 'react'

export interface AssetBulkDataGridMenuProps {
  title: string
  icon: ReactNode
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  maxWidth: 500,
}))

export default function AssetBulkDataGridMenu({
  title,
  icon,
  children,
}: PropsWithChildren<AssetBulkDataGridMenuProps>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>()

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <span>
        <Button
          color={'inherit'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={icon}
          variant={'text'}>
          {title}
        </Button>

        <StyledPopper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement={'bottom-end'}>
          <Paper>
            <Stack p={2}>
              <Typography variant={'h6'}>{title}</Typography>
            </Stack>
            <Divider />
            <Stack
              gap={2}
              minWidth={300}
              p={2}>
              {children}
            </Stack>
          </Paper>
        </StyledPopper>
      </span>
    </ClickAwayListener>
  )
}
