import { get, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'

import { partial } from '../../../util/partial'

export interface SaveReorderProps {
  values: any
  update: any
  path: string
  dirty: boolean
}

function SaveReorder({ values, update, path, dirty }: SaveReorderProps) {
  const [newValues, setNewValues] = useState<null | any>(null)

  useEffect(() => {
    if (dirty && JSON.stringify(values) !== JSON.stringify(newValues)) {
      setNewValues(values)
    }
  }, [dirty, values, setNewValues, newValues])

  useEffect(() => {
    const orderArray: { order: number }[] = get(newValues, path)

    if (!Array.isArray(orderArray)) {
      return
    }

    const updates = orderArray
      .reduce((acc, value, index) => {
        acc[value.order] = { order: index }
        return acc
      }, [] as Array<{ order: number }>)
      .filter((item) => !isEmpty(item))

    const sortOrderChanged = updates.filter((item, index) => index !== item.order).length > 0

    if (sortOrderChanged) {
      update(partial(path, updates))
    }
  }, [newValues, update, path])

  return null
}

export interface UpdateOrderingProps {
  update: any
  path: string
}

export function UpdateOrdering({ update, path }: UpdateOrderingProps) {
  return (
    <FormSpy subscription={{ values: true, dirty: true }}>
      {({ values, dirty }) => (
        <SaveReorder
          dirty={dirty}
          path={path}
          update={update}
          values={values}
        />
      )}
    </FormSpy>
  )
}
