import { useRpcClient } from '@gain/api/swr'
import { AdvisorListItem, RpcMethodMap } from '@gain/rpc/cms-model'
import { ADVISORY_ACTIVITY_OPTIONS_CMS } from '@gain/utils/advisor'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import InputForm from '../../common/input-fields'
import SwrDataGrid from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import LayoutDataGrid from '../../layout/data-grid'
import { ADVISOR_PROFILE_PAGE_PATH } from '../route-advisor'

export default function RouteAdvisors() {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const history = useHistory()

  const handleCreatNewAdvisor = useCallback(async () => {
    const response = await rpcClient({
      method: 'data.createAdvisor',
      params: {
        partial: {
          // By default enable all advisory activities
          advisoryActivities: ADVISORY_ACTIVITY_OPTIONS_CMS.map((option) => option.value),
        },
      },
    })

    if (response && response?.id) {
      history.push(generatePath(ADVISOR_PROFILE_PAGE_PATH, { id: response.id }))
    }
  }, [history, rpcClient])

  return (
    <LayoutDataGrid title={'Advisors'}>
      <Card>
        <SwrDataGrid<'data.listAdvisors', AdvisorListItem>
          actions={[
            <Button
              key={'actions'}
              onClick={handleCreatNewAdvisor}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 1,
              minWidth: 150,
              filterable: false,
            },
            {
              field: 'dealsCount',
              headerName: 'Deals',
              minWidth: 20,
              filterable: false,
            },
            {
              field: 'live',
              headerName: 'Published',
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Published' : 'Draft'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'operationalHqCountryCode',
              headerName: 'Operational HQ Country',
              filterable: false,
              minWidth: 150,
            },
            {
              field: 'publishedAt',
              headerName: 'Published at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'unpublishedAt',
              headerName: 'Unpublished at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },
          ]}
          label={'Advisors'}
          method={'data.listAdvisors'}
          path={':id'}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              deleteMethod={'data.deleteAdvisor'}
              isDisabled={() => row.live}
              name={'advisor'}
              publishMethod={'data.publishAdvisor'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishAdvisor'}
              validateMethod={'data.validateAdvisor'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
