import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import InputFieldClearButton from './input-field-clear-button'
import InputFieldHelperText from './input-field-helper-text'
import { useInputFieldIssues } from './input-field-hooks'
import { useFieldName, useInputFormContext } from './input-form-hooks'
import NullableSelect, { NullableSelectProps } from './nullable-select'

const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'warning',
})<{ warning?: boolean }>(({ theme, error, warning }) => ({
  ...(!error &&
    warning && {
      color: theme.palette.warning.main,
    }),
}))

const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== 'warning',
})<{ warning?: boolean }>(({ theme, error, warning }) => ({
  ...(!error &&
    warning && {
      color: theme.palette.warning.main,
    }),
}))

const StyledInputFieldClearButton = styled(InputFieldClearButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

export interface InputFieldSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label?: string
  placeholder?: string
  options: NullableSelectProps['options']

  disabled?: boolean
  className?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  required?: boolean
  renderValue?: NullableSelectProps['renderValue']
  MenuProps?: NullableSelectProps['MenuProps']
  nullFallbackValue?: NullableSelectProps['nullFallbackValue']
  size?: NullableSelectProps['size']
  onChange?: (event: ChangeEvent<HTMLInputElement>, fieldName: string) => Promise<void>

  // When `true` will render X that will clear the selected value
  clearable?: boolean
}

export default function InputFieldSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  placeholder,
  required,
  options,
  defaultValue,
  MenuProps,
  nullFallbackValue,
  renderValue,
  size,
  className,
  clearable,
  disabled,
  onChange,
}: InputFieldSelectProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const issues = useInputFieldIssues(fieldName)

  const { field } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    async (event) => {
      field.onChange(event)
      await onChange?.(event, field.name)
      await inputForm.onBlur(fieldName, field.onBlur, true)(event)
    },
    [field, fieldName, inputForm, onChange]
  )

  return (
    <FormControl
      className={className}
      fullWidth>
      <StyledInputLabel
        error={issues.hasErrors}
        required={required}
        warning={issues.hasWarnings}>
        {label}
      </StyledInputLabel>

      <NullableSelect
        disabled={disabled ?? inputForm.disabled}
        endAdornment={clearable && field.value && <StyledInputFieldClearButton name={name} />}
        error={issues.hasErrors}
        label={label}
        MenuProps={MenuProps}
        nullFallbackValue={nullFallbackValue}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        renderValue={renderValue}
        size={size}
        value={field.value}
        warning={issues.hasWarnings}
        fullWidth
      />
      <StyledFormHelperText
        error={issues.hasErrors}
        warning={issues.hasWarnings}>
        <InputFieldHelperText errorMessage={issues.message} />
      </StyledFormHelperText>
    </FormControl>
  )
}
