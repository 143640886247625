import { SpinnerIcon } from '@gain/components/icons'
import PublicPage from '@gain/components/public-page'
import { createMethod } from '@gain/jsonrpc'
import { useIsAuthenticated, useStartSession } from '@gain/modules/auth'
// User profile is fetched from the app, not the CMS API
// eslint-disable-next-line no-restricted-imports
import { UserProfile } from '@gain/rpc/app-model'
import { isGainProUser } from '@gain/utils/user'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { HOME_PATH } from '../../util/path'
import { redirectToAppLogin } from '../../util/redirect'
import { appRpcClient } from '../../util/rpcClient'

/**
 * RouteAuthCallback is the page where a user is sent to after a successful
 * authentication at the app. It will try to fetch the user profile to check if
 * the user is authenticated and then redirect to the home page or back to the
 * app login page.
 */
export default function RouteAuthCallback() {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()
  const startSession = useStartSession()
  const [isUnauthorized, setIsUnauthorized] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        // Try to successfully get the user profile to check authentication status
        const userProfile = await appRpcClient.rpc<UserProfile>(
          createMethod('account.getUserProfile')
        )

        // Show error when this is not a Gain.pro user
        if (!isGainProUser(userProfile)) {
          setIsUnauthorized(true)
          return
        }

        // Success, tell our auth context we're signed in if not already
        if (!isAuthenticated) {
          startSession()
        }
        history.replace(HOME_PATH)
      } catch (error) {
        // Error while fetching user profile, redirect back to app login
        redirectToAppLogin()
      }
    })()
    // Explicitly disable exhaustive deps because we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PublicPage>
      {!isUnauthorized ? (
        <>
          <SpinnerIcon fontSize={'inherit'} />
          <Typography variant={'body2'}>Signing in...</Typography>
        </>
      ) : (
        <Typography
          color={'error'}
          variant={'body2'}>
          No access
        </Typography>
      )}
    </PublicPage>
  )
}
