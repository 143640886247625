import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Pill.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Text, { SIZE_CAPTION_3 } from '../Text/Text'
import { memo } from 'react'

export const PILL_COLORSTYLE_WHITE_50 = 'colorStyle-white-50'
export const PILL_COLORSTYLE_WHITE = 'colorStyle-white'
export const PILL_COLORSTYLE_YELLOW = 'colorStyle-yellow'
export const PILL_COLORSTYLE_GREEN = 'colorStyle-green'
export const PILL_COLORSTYLE_ORANGE = 'colorStyle-orange'
export const PILL_COLORSTYLE_BLUE = 'colorStyle-blue'
export const PILL_COLORSTYLE_RED = 'colorStyle-red'
export const PILL_COLORSTYLE_GREY = 'colorStyle-grey'

const Pill = ({ tag, size, children, className, colorStyle, outline, ...rest }) => (
  <Text
    tag={tag}
    size={size}
    className={classNames(className, colorStyle && styles[colorStyle], outline && styles.outline)}
    {...rest}>
    <span className={styles.pillChildren}>{children}</span>
  </Text>
)

Pill.defaultProps = {
  outline: true,
  className: '',
  colorStyle: undefined,
  size: SIZE_CAPTION_3,
  tag: 'div',
  children: undefined,
}

Pill.propTypes = {
  outline: PropTypes.bool,
  className: PropTypes.string,
  colorStyle: PropTypes.string,
  size: PropTypes.string,
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default memo(withClassNames(Pill, styles))
