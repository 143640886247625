import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import PageContent from '../../../Components/Layout/PageContent'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import MultiInputTextArea from '../../../Compositions/Inputs/MultiInputTextArea'
import { KEY_TAKE_AWAYS } from './helpers/industryDetailNames'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailSummaryPage = ({ data }) => (
  <PageContent>
    <FormLabelComposition>Key takeaways</FormLabelComposition>
    <InputGroupComposition>
      <UpdateForm initialValues={data}>
        {() => (
          <MultiInputTextArea
            path={KEY_TAKE_AWAYS}
            maxLength={100}
            rows={1}
            title="Enter text"
          />
        )}
      </UpdateForm>
    </InputGroupComposition>
    <SourcesButton usedFor={USED_FOR_INDUSTRY} />
  </PageContent>
)

IndustryDetailSummaryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailSummaryPage)
