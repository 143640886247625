import PropTypes from 'prop-types'
import { FlexContainer } from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_ROUNDBUTTON } from '../../Components/Tabular/FlexTable/FlexItem'
import DeleteButton from '../../Components/Buttons/DeleteButton'
import DeleteArrayItemComposition from '../ItemActions/delete-array-item-composition'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ImageField from '../File/helpers/ImageField'
import ChartTypeSelect from '../Selects/ChartTypeSelect'

const ImageForm = ({ name, alreadySelected }) => (
  <InputGroupItem>
    <FlexContainer
      itemSpace={0}
      style={{ alignItems: 'center' }}>
      <FlexItem>
        <ImageField path={name} />
      </FlexItem>
      <FlexItem>
        <ChartTypeSelect
          path={`${name}.type`}
          alreadySelected={alreadySelected}
        />
      </FlexItem>
      <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
        <DeleteArrayItemComposition path={name}>
          {(deleteItem) => <DeleteButton onClick={deleteItem} />}
        </DeleteArrayItemComposition>
      </FlexItem>
    </FlexContainer>
  </InputGroupItem>
)

ImageForm.propTypes = {
  name: PropTypes.string.isRequired,
  alreadySelected: PropTypes.array.isRequired,
}

export default ImageForm
