import PropTypes from 'prop-types'
import { LIST_ASSETS_METHOD } from '../../../util/methods'
import AddAsyncInputSelect from './AddAsyncInputSelect'

const AddAssetSelect = ({ onChange, placeholder, alreadySelected, sort }) => (
  <AddAsyncInputSelect
    key={alreadySelected.length}
    method={LIST_ASSETS_METHOD}
    placeholder={placeholder}
    alreadySelected={alreadySelected}
    onChange={onChange}
    sort={sort}
  />
)

AddAssetSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  alreadySelected: PropTypes.array,
  placeholder: PropTypes.string,
}

AddAssetSelect.defaultProps = {
  alreadySelected: [],
  placeholder: 'Enter company name',
}

export default AddAssetSelect
