import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody, { MODALBODY_OVERFLOW_VISIBLE } from '../../Components/Modal/ModalBoxBody'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import Button, {
  BUTTON_STYLENAME_PILL,
  BUTTON_STYLENAME_TINT,
} from '../../Components/Buttons/Button'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import IndustryArticleSelect from '../Selects/AddAsyncSelect/IndustryArticleSelect'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'

const mapStateToProps = (state) => ({
  sending: state.userEmail.sending,
})

const EmailIndustryModal = ({ onClick, title, sending }) => {
  const [industryId, setIndustryId] = useState(undefined)

  return (
    <ModalBox>
      <ModalBoxHead>Industry research emails</ModalBoxHead>
      <ModalBoxBody styleName={MODALBODY_OVERFLOW_VISIBLE}>
        <InputGroupItem>
          <IndustryArticleSelect
            onChange={(value) => {
              setIndustryId(value.value)
            }}
          />
        </InputGroupItem>
      </ModalBoxBody>
      <ModalBoxFoot style={{ justifyContent: 'center' }}>
        <Button
          style={{ display: 'inline-flex' }}
          styleName={`${BUTTON_STYLENAME_PILL} ${BUTTON_STYLENAME_TINT}`}
          onClick={() => {
            onClick(industryId)
          }}
          spinner={sending}>
          {title}
        </Button>
      </ModalBoxFoot>
    </ModalBox>
  )
}

EmailIndustryModal.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  sending: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(EmailIndustryModal)
