import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { ICON_CHECKMARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'

const TrueIcon = () => (
  <IconInCircle
    colorStyle={'textSecondary-diap'}
    icon={ICON_CHECKMARK}
  />
)

const ShowBoolean = ({ path, isTrue, isFalse }) => {
  return (
    <Field name={path}>
      {({ input }) => {
        return input.value ? isTrue : isFalse
      }}
    </Field>
  )
}

ShowBoolean.defaultProps = {
  isTrue: <TrueIcon />,
  isFalse: '',
}

ShowBoolean.propTypes = {
  path: PropTypes.string.isRequired,
  isTrue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isFalse: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ShowBoolean
