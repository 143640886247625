import Stack from '@mui/material/Stack'
import { ChangeEvent } from 'react'

import { InputEndAdornmentHref, InputFieldText, InputGroupAdornmentDelete } from '../input-fields'
import InputFieldRadio from '../input-fields/input-field-radio'

interface UrlFormProps {
  onPrimaryChange?: (event: ChangeEvent<HTMLInputElement>, fieldName: string) => Promise<void>
}

export default function UrlForm({ onPrimaryChange }: UrlFormProps) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      p={1}>
      <InputFieldText
        InputProps={{
          endAdornment: <InputEndAdornmentHref field={'url'} />,
        }}
        label={'URL'}
        name={'url'}
      />
      <InputFieldRadio
        name={'isPrimary'}
        onChange={onPrimaryChange}
      />
      <InputGroupAdornmentDelete />
    </Stack>
  )
}
