import PropTypes from 'prop-types'
import ButtonsList from '../../Components/Buttons/ButtonsList'
import { Edit } from './Actions/Edit'
import Delete from './Actions/Delete'
import { DEFAULT_LEGAL_ENTITIES_LIST_PATH } from '../../util/pathDefaults'
import { LEGAL_ENTITY_PROFILE_PATH } from '../../util/path'
import { memo } from 'react'

const LegalEntityActions = ({ data, listPage }) => (
  <ButtonsList>
    {listPage && (
      <Edit
        id={data.id}
        path={LEGAL_ENTITY_PROFILE_PATH}
      />
    )}
    <Delete
      name={data.name}
      title="entity"
      subTitle="All annual reports will also be deleted."
      path={DEFAULT_LEGAL_ENTITIES_LIST_PATH}
    />
  </ButtonsList>
)

LegalEntityActions.defaultProps = {
  listPage: false,
}

LegalEntityActions.propTypes = {
  data: PropTypes.object.isRequired,
  listPage: PropTypes.bool,
}

export default memo(LegalEntityActions)
