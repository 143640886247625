import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FlexItem, { FLEXITEM_50_PX } from '../../../Components/Tabular/FlexTable/FlexItem'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import Text, { SIZE_CAPTION_1, SIZE_CAPTION_2 } from '../../../Components/Text/Text'
import ShowString from '../../Inputs/ShowString'
import ShowBoolean from '../../Inputs/ShowBoolean'
import { COLORNAME_TEXT_SECONDARY } from '../../../util/colors'
import Icon, { ICON_ARROW_LINK } from '../../../Components/Icons/Icon'
import { fixUrl } from '../../../util/fixUrl'
import IconAndText from '../../../Components/Icons/IconAndText'
import InputError from '../../../Components/FormElements/Error/InputError'
import { ErrorContainer } from '../../UpdateForm/ErrorContainer'

const SourceFormHead = ({ name, usedFor }) => (
  <>
    <FlexContainer>
      <FlexItem>
        <Text size={SIZE_CAPTION_1}>
          <ShowString path={`${name}.title`} />
        </Text>
        <IconAndText
          size={SIZE_CAPTION_2}
          colorName={COLORNAME_TEXT_SECONDARY}
          after
          icon={
            <Field name={`${name}.url`}>
              {({ input }) =>
                input.value && (
                  <a
                    href={fixUrl(input.value)}
                    onClick={(e) => e.stopPropagation()}
                    rel="noopener noreferrer"
                    target="_blank">
                    <Icon src={ICON_ARROW_LINK} />
                  </a>
                )
              }
            </Field>
          }>
          <ShowString
            path={`${name}.publicationYear`}
            after={
              <ShowString
                before=" / "
                path={`${name}.publicationMonth`}
                after=" - "
                fallback=" "
              />
            }
          />
          <ShowString path={`${name}.publisher`} />
        </IconAndText>
      </FlexItem>

      {usedFor.map((uses) => (
        <FlexItem
          styleName={FLEXITEM_50_PX}
          key={uses.name}>
          <ShowBoolean path={`${name}.${uses.name}`} />
        </FlexItem>
      ))}
    </FlexContainer>
    <ErrorContainer
      path={[
        `${name}.title`,
        `${name}.publicationYear`,
        `${name}.publicationMonth`,
        `${name}.url`,
      ]}>
      {(onClick, hasErrors) => (
        <InputError
          onClick={onClick}
          hasErrors={hasErrors}
          style={{
            left: '-63px',
            marginTop: '-23px',
          }}
        />
      )}
    </ErrorContainer>
  </>
)

SourceFormHead.propTypes = {
  name: PropTypes.string.isRequired,
  usedFor: PropTypes.array.isRequired,
}

export default SourceFormHead
