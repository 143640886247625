// @ts-expect-error the webpack loader handles it
import guide from './consistency-guide.yml'

export function getFromConsistencyGuide(name?: unknown): string {
  if (!name) {
    return ''
  }

  return guide[name]
}
