import { isBoolean } from 'lodash'

import { canEditAsset, isAsset } from '../routes/route-asset/route-asset-utils'

export default function isDisabled(data: any) {
  if (isAsset(data)) {
    return !canEditAsset(data)
  }
  if (isBoolean(data.profileLive)) {
    return data.profileLive
  }
  if (isBoolean(data.live)) {
    return data.live
  }
  if (isBoolean(data.published)) {
    return data.published
  }
  return data.status === 'published'
}
