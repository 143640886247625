import { formHelperTextClasses } from '@mui/material/FormHelperText'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'warning',
})<{ warning?: boolean }>(({ theme, warning, error }) => ({
  ...(!error &&
    warning && {
      [`& .${inputLabelClasses.root}`]: {
        color: theme.palette.warning.main,
      },
      [`& .${formHelperTextClasses.root}`]: {
        color: theme.palette.warning.main,
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${theme.palette.warning.main} !important`,
      },
    }),
}))

export default StyledTextField
