import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isGermany } from '@gain/utils/countries'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputString from '../../../Compositions/Inputs/input-string.component'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import PageContent from '../../../Components/Layout/PageContent'
import RegionSelect from '../../../Compositions/Selects/region-select.component'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import MultiInputString from '../../../Compositions/Inputs/MultiInputString'
import RegisterCourtForm from '../../../Compositions/LegalEntityDetail/RegisterCourtForm'
import Divider from '../../../Components/Divider/Divider'
import { CurrencySelect } from '../../../Compositions/Selects/CurrencySelect'

const PREVIOUS_NAMES = 'previousNames'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const LegalEntityDetailProfilePage = ({ data }) => (
  <PageContent>
    <FormLabelComposition>Entity name</FormLabelComposition>
    <InputGroupComposition>
      <UpdateForm initialValues={data}>
        {() => (
          <InputGroupItem>
            <InputString
              path="name"
              title="Name"
            />
          </InputGroupItem>
        )}
      </UpdateForm>
    </InputGroupComposition>

    <FormLabelComposition>Previous names</FormLabelComposition>
    <InputGroupComposition>
      <UpdateForm initialValues={{ [PREVIOUS_NAMES]: data[PREVIOUS_NAMES] }}>
        {() => (
          <div style={{ marginTop: -1 }}>
            <MultiInputString path={PREVIOUS_NAMES} />
          </div>
        )}
      </UpdateForm>
    </InputGroupComposition>

    <FormLabelComposition>General information</FormLabelComposition>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>
          {() => (
            <>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Headquarters</FormLabel>
              <RegionSelect path="region" />
            </>
          )}
        </UpdateForm>
      </InputGroupItem>
      <Divider />
      <InputGroupItem>
        <UpdateForm initialValues={data}>
          {() => (
            <>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Currency</FormLabel>
              <CurrencySelect path="currency" />
            </>
          )}
        </UpdateForm>
      </InputGroupItem>
      <Divider />

      {isGermany(data.region) ? (
        <RegisterCourtForm
          data={data}
          path="externalId"
        />
      ) : (
        <UpdateForm initialValues={data}>
          {() => (
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Entity number</FormLabel>
              <InputString
                path="externalId"
                title="Entity number"
              />
            </InputGroupItem>
          )}
        </UpdateForm>
      )}
    </InputGroupComposition>
  </PageContent>
)

LegalEntityDetailProfilePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(LegalEntityDetailProfilePage)
