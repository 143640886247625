import { InputBaseComponentProps } from '@mui/material/InputBase'
import { forwardRef, useMemo } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import { PatternFormat } from 'react-number-format'

import InputFieldText, { InputFieldTextProps } from './input-field-text'

export interface InputFieldPatternFormatProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputFieldTextProps<TFieldValues, TName> {
  format: string
}

export default function InputFieldPatternFormat<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ format, ...props }: InputFieldPatternFormatProps<TFieldValues, TName>) {
  const inputComponent = useMemo(
    () =>
      forwardRef(
        (
          { onChange, defaultValue: _defaultValue, ...inputProps }: InputBaseComponentProps,
          ref
        ) => {
          return (
            <PatternFormat
              {...inputProps}
              format={format}
              getInputRef={ref}
              onValueChange={({ formattedValue }) => {
                onChange?.(formattedValue as FieldPathValue<TFieldValues, TName>)
              }}
            />
          )
        }
      ),
    [format]
  )

  return (
    <InputFieldText
      {...props}
      InputProps={{
        ...props.InputProps,
        inputComponent,
      }}
    />
  )
}
