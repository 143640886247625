import { AssetListItem, ProjectListItem } from '@gain/rpc/cms-model'
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'
import Chip from '@mui/material/Chip'
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { InputFieldAutoComplete, InputFormProvider } from '../../../common/input-fields'
import { isSwrDataGridListMethodColumn } from '../../../common/swr-data-grid'

export function renderAssetProjectEditCell(params: GridRenderEditCellParams) {
  return <AssetProjectEditCell {...params} />
}

function AssetProjectEditCell({ id, colDef, api, row }: GridRenderEditCellParams<AssetListItem>) {
  const form = useForm({
    defaultValues: { input: row.projects },
  })

  const handleChange = useCallback(
    (values: number | ProjectListItem | (number | ProjectListItem)[]) => {
      if (!Array.isArray(values)) {
        return
      }

      api.setEditCellValue({
        id,
        field: 'projects',
        value: values,
      })
    },
    [api, id]
  )

  const renderTags = useCallback((items, getCustomizedTagProps: AutocompleteRenderGetTagProps) => {
    return items.map((option: ProjectListItem, index: number) => (
      <Chip
        label={option.name}
        size={'small'}
        {...getCustomizedTagProps({ index })}
      />
    ))
  }, [])

  if (!isSwrDataGridListMethodColumn(colDef)) {
    return null
  }

  return (
    <InputFormProvider form={form}>
      <InputFieldAutoComplete
        defaultFilter={colDef.defaultMethodFilter}
        getOptionLabel={colDef.getOptionLabel}
        label={''}
        labelProp={colDef.labelProp}
        method={colDef.method}
        name={'input'}
        onAfterChange={handleChange}
        renderTags={renderTags}
        valueProp={colDef.valueProp}
        variant={'standard'}
        multiple
      />
    </InputFormProvider>
  )
}
