import { format as fnsFormat } from 'date-fns/format'
import { formatDistance } from 'date-fns/formatDistance'
import { parseISO } from 'date-fns/parseISO'
import moment from 'moment'

// date-fns constants
export const DATE_YYYY_MM_DASH = 'yyyy-MM'

// momentjs constants
export const DATE_LLLL = 'LLLL'
export const DATE_YYYY_MM = 'YYYY / MM'
export const DATE_YYYY_MM_DD = 'YYYY-MM-DD'
export const DATE_HH_MM = 'DD-MM-YYYY, HH:mm'
export const DATE_UPDATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export const isDateBogus = (date) => parseInt(moment(date).format('Y'), 10) < 2 // because unentered date in database returns 0001-01-01T00:00:00Z

export const formatDistanceFromNow = (date, alternative = '') => {
  if (date) {
    return formatDistance(new Date(date), new Date(), { addSuffix: true })
  }
  return alternative
}

export const formatDate = (date: string, format: string) => fnsFormat(parseISO(date), format)
