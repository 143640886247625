import Head from '@gain/modules/head'
import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

import SideBar from '../../Components/Layout/SideBar'
import Navigation from '../navigation/navigation'

export interface LayoutDataGridProps {
  title?: string
}

const Root = styled('div')(({ theme }) => ({
  marginLeft: 248,
  padding: theme.spacing(5),
  maxWidth: 'calc(100vw - 248px)',
}))

export default function LayoutDataGrid({
  children,
  title,
}: PropsWithChildren<LayoutDataGridProps>) {
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}

      <SideBar togglable={false}>
        <Navigation />
      </SideBar>

      <Root>{children}</Root>
    </>
  )
}
