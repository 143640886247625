import PropTypes from 'prop-types'
import Pill from '../../Components/Pill/Pill'
import { memo } from 'react'

const StatusIndicator = ({ status, getStatus }) => (
  <Pill style={{ '--pill-bg-color': getStatus(status).color }}>{getStatus(status).label}</Pill>
)

StatusIndicator.propTypes = {
  getStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
}

StatusIndicator.defaultProps = {
  status: undefined,
}

export default memo(StatusIndicator)
