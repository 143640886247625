import { Industry } from '@gain/rpc/cms-model'
import { useMemo } from 'react'

import SourcesComposition from '../../../Compositions/Sources/SourcesComposition'
import { useFetchItem } from '../../../redux/reducer/fetchItem'
import { getUseForAsset, USED_FOR_INDUSTRY } from '../../../util/usedFor'

export function IndustryDetailSourcesPage() {
  const data = useFetchItem<Industry>()

  const hasAssessmentSources = useMemo(() => {
    if (!data) {
      return false
    }
    return data.sources.filter((source) => source.assessment).length > 0
  }, [data])

  const usedFor = useMemo(() => {
    if (hasAssessmentSources) {
      return USED_FOR_INDUSTRY
    }
    return USED_FOR_INDUSTRY.filter((item) => item.name !== 'assessment')
  }, [hasAssessmentSources])

  if (!data) {
    return null
  }

  return (
    <SourcesComposition
      data={data}
      notUsedFor={getUseForAsset().filter((item) => item.name !== 'market')}
      usedFor={usedFor}
    />
  )
}
