import { DealListItem } from '@gain/rpc/cms-model'
import {
  DEAL_ITEM_STATUS_OPTIONS,
  DEAL_STATUS_OPTIONS,
  DealItemStatus,
} from '@gain/rpc/shared-model'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { ReactElement, useCallback, useRef } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import ArrowLink from '../../common/arrow-link'
import InputForm, { useInputFormAPI } from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import LayoutDataGrid from '../../layout/data-grid'
import { getDateFormatted } from '../../util/date'
import { pathGenerate } from '../../util/pathGenerate'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../route-asset'
import { DEAL_GENERAL_PATH } from '../route-deal'
import { NAV_DEALS } from './route-deals-path'

export default function RouteDeals() {
  const dataGridRef = useRef<SwrDataGridRef>(null)
  const history = useHistory()

  const inputFormAPI = useInputFormAPI({
    createMethod: 'data.createDeal',
  })

  const handleCreateNewDeal = useCallback(async () => {
    await inputFormAPI.create({ partial: {} }, (response) => {
      history.push(generatePath(DEAL_GENERAL_PATH, { id: response?.id }))
    })
  }, [history, inputFormAPI])

  return (
    <LayoutDataGrid title={NAV_DEALS}>
      <Card>
        <SwrDataGrid<'data.listDeals', DealListItem>
          ref={dataGridRef}
          actions={[
            <LoadingButton
              key={'new-deal'}
              loading={inputFormAPI.busy}
              onClick={handleCreateNewDeal}
              variant={'contained'}>
              New
            </LoadingButton>,
          ]}
          columns={[
            {
              field: 'announcementDateYear',
              headerName: 'Date',
              valueFormatter: (value, row) =>
                getDateFormatted({
                  year: row.announcementDateYear,
                  month: row.announcementDateMonth,
                }),
              filterable: false,
            },
            {
              field: 'dealStatus',
              headerName: 'Deal status',
              width: 150,
              type: 'options',
              options: DEAL_STATUS_OPTIONS,
              filterable: false,
            },
            {
              field: 'linkedAssetId',
              headerName: 'Target',
              flex: 1,
              filterable: false,
              renderCell: ({ value, row }) =>
                value ? (
                  <ArrowLink
                    to={pathGenerate(ROUTE_ASSET_KEY_FACTS_PATH, {
                      id: value,
                    })}>
                    {row.asset}
                  </ArrowLink>
                ) : (
                  row.asset
                ),
            },
            {
              field: 'status',
              headerName: 'Status',
              minWidth: 150,
              filterable: false,
              type: 'chip-options',
              options: DEAL_ITEM_STATUS_OPTIONS,
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },

            /**
             * Hidden columns, needed otherwise the filters/sorting don't work
             */
            {
              field: 'announcementDateMonth',
              filterable: false,
            },
          ]}
          columnVisibilityModel={{
            announcementDateMonth: false,
          }}
          label={NAV_DEALS}
          method={'data.listDeals'}
          path={DEAL_GENERAL_PATH}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              deleteMethod={'data.deleteDeal'}
              isDisabled={() => row.status === DealItemStatus.Published}
              name={'deal'}
              publishMethod={'data.publishDeal'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishDeal'}
              validateMethod={'data.validateDeal'}>
              {children}
            </InputForm>
          )}
          sort={[
            { field: 'announcementDateYear', direction: 'desc' },
            { field: 'announcementDateMonth', direction: 'desc' },
          ]}
          disableColumnFilter
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
