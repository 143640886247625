import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import CheckRadio from '../../Components/FormElements/CheckRadio/CheckRadio'
import { partial } from '../../util/partial'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import InputError from '../../Components/FormElements/Error/InputError'
import { useMethodContext } from '../../Context/method-context.provider'

const InputCheckbox = ({ path, title, disabled }) => {
  const methodCtx = useMethodContext()

  return (
    <>
      <Field
        name={path}
        component="input"
        type="checkbox">
        {({ input, meta }) => (
          <>
            <CheckRadio
              disabled={disabled || methodCtx.disabled || methodCtx.readOnly}
              input={input}
              label={title}
              readOnly={methodCtx.readOnly}
            />
            <OnChange name={path}>
              {(value) => {
                if (meta.dirty && typeof value === 'boolean') {
                  methodCtx.update(partial(path, value))
                }
              }}
            </OnChange>
          </>
        )}
      </Field>
      <ErrorContainer path={path}>
        {(onClick, hasErrors) => (
          <InputError
            onClick={onClick}
            hasErrors={hasErrors}
          />
        )}
      </ErrorContainer>
    </>
  )
}

InputCheckbox.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
}

InputCheckbox.defaultProps = {
  title: undefined,
}

export default InputCheckbox
