import { Redirect, Route, Switch } from 'react-router'

import { HOME_PATH } from '../util/path'
import MainRoutes from './main-routes'
import { ROUTE_ASSETS_PATH } from './route-assets/route-assets-path'

export default function Routes() {
  return (
    <Switch>
      <Redirect
        from={HOME_PATH}
        to={ROUTE_ASSETS_PATH}
        exact
      />
      <Route component={MainRoutes} />
    </Switch>
  )
}
