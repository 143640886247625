import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Text.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

export const SIZE_HEADER_2 = 'header-2'
export const SIZE_HEADER_3 = 'header-3'
export const SIZE_HEADER_4 = 'header-4'
export const SIZE_HEADER_5 = 'header-5'
export const SIZE_BODY_1 = 'body-1'
export const SIZE_CAPTION_1 = 'caption-1'
export const SIZE_CAPTION_2 = 'caption-2'
export const SIZE_CAPTION_3 = 'caption-3'
export const SIZE_CAPTION_4 = 'caption-4'

const Text = ({ size, tag, className, center, maxLines, children, colorName, ...rest }) => {
  const TextTag = tag
  const sizeClassName = size && styles[`${size}-${maxLines}`]
  const colorClassName = colorName && styles[colorName]
  const centerClassName = center && styles.center
  return (
    <TextTag
      title={maxLines > 0 && typeof children === 'string' ? children : null}
      className={classNames(className, sizeClassName, centerClassName, colorClassName)}
      {...rest}>
      {children}
    </TextTag>
  )
}

Text.defaultProps = {
  className: '',
  children: undefined,
  size: undefined,
  maxLines: 0,
  tag: 'div',
  center: false,
  colorName: undefined,
}

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  maxLines: PropTypes.number,
  center: PropTypes.bool,
  tag: PropTypes.string,
  colorName: PropTypes.string,
}

export default memo(withClassNames(Text, styles))
