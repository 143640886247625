import { RpcMethodMap } from '@gain/rpc/cms-model'
import { useDialogState } from '@gain/utils/dialog'
import { IncludeStartsWith } from '@gain/utils/typescript'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { Noop } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'

import { InputFormAPI, useInputFormAPI } from './input-form-api'
import { InputFormContextAPI, useSyncFormIssues } from './input-form-hooks'
import InputFormProvider from './input-form-provider'
import { useInputFormReduxAPI } from './input-form-redux-api'

export interface InputFormProps<GetMethod, UpdateMethod, CreateMethod, Item extends FieldValues>
  extends Pick<InputFormContextAPI, 'recordId'>,
    InputFormAPI<GetMethod, UpdateMethod, CreateMethod, Item> {
  name?: string
  isDisabled?: (item?: Item) => boolean

  // Enables syncing with the fetch item of redux
  syncFetchWithRedux?: boolean
}

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'saving',
})<{ saving: boolean }>(({ saving }) => ({
  ...(saving && {
    'body &': {
      '*': {
        cursor: 'wait',
      },
    },
  }),
}))

export default function InputForm<
  GetMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.get'>,
  UpdateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.update'>,
  CreateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.create'>,
  Item extends RpcMethodMap[GetMethod]['result'] & FieldValues
>({
  name,
  recordId,
  isDisabled,

  getMethod,
  updateMethod,
  validateMethod,
  publishMethod,
  unPublishMethod,
  deleteMethod,
  archiveMethod,
  unArchiveMethod,

  validationSchema = {},
  alwaysPatchOnBlur = false,
  children,

  syncFetchWithRedux = false,
  defaultValues,
}: PropsWithChildren<InputFormProps<GetMethod, UpdateMethod, CreateMethod, Item>>) {
  const [showDeleteDialog, openDeleteDialog, closeDeleteDialog] = useDialogState()
  const deleteCallbackRef = useRef<() => void>()

  const api = useInputFormAPI({
    getMethod,
    updateMethod,
    validateMethod,
    publishMethod,
    unPublishMethod,
    deleteMethod,
    archiveMethod,
    unArchiveMethod,
    validationSchema,
    alwaysPatchOnBlur,
    defaultValues,
  })

  const issues = useSyncFormIssues<Partial<Item>>(api.form, api.issues)

  useInputFormReduxAPI(syncFetchWithRedux, () => api.fetch(recordId))

  const handleFetch = useCallback(() => {
    return api.fetch(recordId)
  }, [api, recordId])

  useEffect(() => {
    api.fetch(recordId)
    // We only want to re-fetch when the record id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId])

  const handlePatch = useCallback(
    (partial: object) => {
      return api.patch(recordId, partial)
    },
    [api, recordId]
  )

  const handleFieldBlur = useCallback(
    (unknownFieldName: unknown, formOnBlur: Noop, force?: boolean) => {
      return api.onBlur(recordId, unknownFieldName, formOnBlur, force)
    },
    [api, recordId]
  )

  const handlePublish = useCallback(
    (ignoreWarnings = false, callback?: () => void): Promise<void> => {
      return api.publish(recordId, ignoreWarnings, callback)
    },
    [api, recordId]
  )

  const handleUnPublish = useCallback(
    (callback?: () => void): Promise<boolean> => {
      return api.unPublish(recordId, callback)
    },
    [api, recordId]
  )

  const handleArchive = useCallback((): Promise<boolean> => {
    return api.archive(recordId)
  }, [api, recordId])

  const handleUnArchive = useCallback((): Promise<boolean> => {
    return api.unArchive(recordId)
  }, [api, recordId])

  const handleCloseDeleteDialog = useCallback(() => {
    deleteCallbackRef.current = undefined
    closeDeleteDialog()
  }, [closeDeleteDialog])

  const handleShowDeleteDialog = useCallback(
    (callback?: () => void) => {
      deleteCallbackRef.current = callback
      openDeleteDialog()
    },
    [openDeleteDialog]
  )

  const handleDelete = useCallback(
    (): Promise<boolean> => api.delete(recordId, {}, deleteCallbackRef.current),
    [api, recordId]
  )

  return (
    <InputFormProvider
      api={{
        recordId,
        issues,
        name,
        disabled: api.saving || (isDisabled?.(api.fetchedRecord) ?? false),

        busy: api.busy,
        saving: api.saving,
        publishing: api.publishing,
        validating: api.validating,
        deleting: api.deleting,
        archiving: api.archiving,

        fetch: handleFetch,
        patch: handlePatch,
        onBlur: handleFieldBlur,

        publish: handlePublish,
        unPublish: handleUnPublish,
        delete: handleShowDeleteDialog,
        archive: handleArchive,
        unArchive: handleUnArchive,
      }}
      form={api.form}>
      <Container saving={api.busy}>{children}</Container>

      {deleteMethod && (
        <Dialog
          onClose={handleCloseDeleteDialog}
          open={showDeleteDialog}>
          <DialogTitle>Delete {name || 'item'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this {name || 'item'}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button
              color={'error'}
              onClick={handleDelete}
              variant={'contained'}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </InputFormProvider>
  )
}
