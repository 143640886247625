import 'react-toastify/dist/ReactToastify.css'

import { useIsAuthenticated } from '@gain/modules/auth'
import { Redirect, Route, Switch } from 'react-router'

import ToastContainerComponent from '../Components/Toastify/ToastContainer'
import { AUTH_CALLBACK_PATH, LOGIN_PATH } from '../util/path'
import PrivateRoutes from './private-routes'
import RouteAuthCallback from './route-auth-callback'
import RouteLogin from './route-login'

export default function MainRoutes() {
  const isAuthenticated = useIsAuthenticated()

  return (
    <>
      {/* Public authentication routes */}
      <Switch>
        <Route
          component={RouteLogin}
          path={LOGIN_PATH}
        />
        <Route
          component={RouteAuthCallback}
          path={AUTH_CALLBACK_PATH}
        />

        {/* Redirect to log in if not authenticated */}
        {!isAuthenticated && (
          <Route>
            <Redirect to={LOGIN_PATH} />
          </Route>
        )}
      </Switch>

      {/* Render private routes when we're authenticated */}
      {isAuthenticated && <PrivateRoutes />}

      <ToastContainerComponent />
    </>
  )
}
