import { getLinkedInCompanyUrl, getLinkedinExternalId } from '../linkedin-utils'
import InputEndAdornmentHref from './input-end-adornment-href'
import InputFieldText from './input-field-text'
import { InputFieldTransform } from './input-field-transform'

const transform: InputFieldTransform = {
  input: (value) => {
    return value
  },
  output: (event) => {
    const companyId = getLinkedinExternalId(event.target.value)
    if (!companyId) {
      return event
    }

    event.target.value = companyId.toString()
    return event
  },
}

interface InputFieldLinkedinCompanyIdProps {
  name: string
  required?: boolean
}

export default function InputFieldLinkedinCompanyId({
  name,
  required,
}: InputFieldLinkedinCompanyIdProps) {
  return (
    <InputFieldText
      consistencyGuideName={'linkedin'}
      InputProps={{
        endAdornment: (
          <InputEndAdornmentHref
            field={name}
            formatUrl={getLinkedInCompanyUrl}
          />
        ),
      }}
      label={'LinkedIn company ID'}
      name={name}
      required={required}
      transform={transform}
    />
  )
}
