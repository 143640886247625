import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'

import ImportCreditsDialog from './import-credits-dialog'

export default function ImportCreditsButton() {
  const [isOpen, handleOpen, handleClose] = useDialogState()

  return (
    <>
      <ImportCreditsDialog
        onClose={handleClose}
        open={isOpen}
      />
      <Button
        color={'primary'}
        onClick={handleOpen}
        variant={'contained'}>
        Import credits
      </Button>
    </>
  )
}
