import { useRpcClient } from '@gain/api/swr'
import { ConferenceEdition, RpcMethodMap } from '@gain/rpc/cms-model'
import { useOnMountEffect } from '@gain/utils/react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useRef } from 'react'

import {
  InputEndAdornmentHref,
  InputFieldAutoComplete,
  InputFieldDate,
  InputFieldFile,
  InputFieldText,
  InputFormData,
  useInputFormContext,
  useInputFormData,
} from '../../../common/input-fields'
import { ACCEPT_CSV_FILES, ACCEPT_IMAGE_FILES } from '../../../common/input-fields/input-file'
import { ItemPageBlock, ItemPageContent } from '../../../layout/item-page'

export default function ConferenceEditionInfoRoute() {
  const inputFormContext = useInputFormContext()
  const conferenceEdition = useInputFormData<ConferenceEdition>()
  const rpcClient = useRpcClient<RpcMethodMap>()
  const polling = useRef(false)
  const pollTimeout = useRef<number>(null)

  useOnMountEffect(() => {
    return () => {
      if (pollTimeout.current) {
        clearTimeout(pollTimeout.current)
      }
    }
  })

  const pollImporting = useCallback(async () => {
    polling.current = true

    // Re-fetch record
    const record = (await inputFormContext.fetch()) as ConferenceEdition

    // If we are still importing, check again in 2.5 seconds
    if (record) {
      if (record.exhibitorsImporting) {
        // @ts-expect-error this is not a readonly property
        pollTimeout.current = setTimeout(() => {
          pollImporting()
        }, 2500)
      } else {
        polling.current = false
      }
    }
  }, [inputFormContext])

  const handleExhibitorsFileUploaded = useCallback(
    async (fileId: number) => {
      if (!conferenceEdition) {
        return
      }

      await rpcClient({
        method: 'data.importConferenceEditionExhibitors',
        params: {
          id: conferenceEdition.id,
          fileId,
        },
      })

      await pollImporting()
    },
    [conferenceEdition, pollImporting, rpcClient]
  )

  useEffect(() => {
    if (!polling.current && conferenceEdition?.exhibitorsImporting) {
      pollImporting()
    }
    // We only want to run this effect if the conference edition changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceEdition])

  return (
    <ItemPageContent>
      <ItemPageBlock label={'Name and logo'}>
        <InputFieldText
          label={'Name'}
          name={'name'}
          required
        />

        <InputFieldFile
          accept={ACCEPT_IMAGE_FILES}
          label={'Logo'}
          name={'logoFileId'}
        />
      </ItemPageBlock>

      <ItemPageBlock label={'General information'}>
        <InputFieldAutoComplete
          label={'Conference family'}
          labelProp={'name'}
          method={'data.listConferences'}
          name={'conferenceId'}
          valueProp={'id'}
          required
        />

        <InputFieldText
          InputProps={{
            endAdornment: <InputEndAdornmentHref field={'webUrl'} />,
          }}
          label={'URL'}
          name={'webUrl'}
          required
        />

        <InputFieldAutoComplete
          label={'Venue'}
          labelProp={'name'}
          method={'data.listConferenceVenues'}
          name={'conferenceVenueId'}
          valueProp={'id'}
        />

        <InputFieldDate
          label={'Start date'}
          name={'startDate'}
        />

        <InputFieldDate
          label={'End date'}
          name={'endDate'}
        />
      </ItemPageBlock>

      <InputFormData field={'webUrl'}>
        {({ value }) => (
          <ItemPageBlock label={'General information'}>
            <InputFieldFile
              accept={ACCEPT_CSV_FILES}
              disabled={conferenceEdition?.exhibitorsImporting || !value}
              label={'Exhibitors File'}
              name={'exhibitorsFileId'}
              onUpload={handleExhibitorsFileUploaded}
              disableImagePreview
              secure>
              {conferenceEdition?.exhibitorsImporting && <CircularProgress size={24} />}
            </InputFieldFile>

            {(conferenceEdition?.exhibitorsImportFailedReason || !value) && (
              <Typography color={'error'}>
                {!value
                  ? 'Conference URL required before uploading exhibitors'
                  : conferenceEdition?.exhibitorsImportFailedReason}
              </Typography>
            )}
          </ItemPageBlock>
        )}
      </InputFormData>
    </ItemPageContent>
  )
}
