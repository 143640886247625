import { styled } from '@mui/material/styles'
import { TextFieldProps } from '@mui/material/TextField'
import { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import InputFieldHelperText from './input-field-helper-text'
import { useInputFieldIssues } from './input-field-hooks'
import InputFieldTextCharacterCount from './input-field-text-character-count'
import { defaultTransform, InputFieldTransform } from './input-field-transform'
import { useFieldName, useInputFormContext } from './input-form-hooks'
import StyledTextField from './styled-text-field'

const StyledHelperText = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export interface InputFieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<
    TextFieldProps,
    | 'autoFocus'
    | 'disabled'
    | 'helperText'
    | 'InputLabelProps'
    | 'InputProps'
    | 'minRows'
    | 'multiline'
    | 'onChange'
    | 'placeholder'
    | 'required'
    | 'size'
    | 'sx'
    | 'type'
  > {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  consistencyGuideName?: string
  transform?: InputFieldTransform
  recommendedLength?: number
}

export default function InputFieldText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  defaultValue,
  onChange,
  consistencyGuideName,
  transform = defaultTransform,
  disabled,
  recommendedLength,
  ...textFieldProps
}: InputFieldTextProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const issues = useInputFieldIssues(fieldName)

  const { field } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const transformedEvent = transform.output(event)

      field.onChange(transformedEvent)
      onChange?.(transformedEvent)
    },
    [field, onChange, transform]
  )

  return (
    <StyledTextField
      {...textFieldProps}
      disabled={inputForm.disabled || disabled}
      error={issues.hasErrors}
      helperText={
        <>
          <InputFieldHelperText
            consistencyGuideLabel={label}
            consistencyGuideName={consistencyGuideName || name}
            errorMessage={issues.message}
            helperText={textFieldProps.helperText}
          />
          <InputFieldTextCharacterCount
            recommendedLength={recommendedLength}
            text={field.value?.toString()}
          />
        </>
      }
      inputRef={field.ref}
      label={label}
      name={field.name}
      onBlur={inputForm.onBlur(fieldName, field.onBlur)}
      onChange={handleChange}
      required={required}
      slotProps={{
        formHelperText: { component: StyledHelperText },
      }}
      value={transform.input(field.value)}
      variant={'outlined'}
      warning={issues.hasWarnings}
      fullWidth
    />
  )
}
