import { useDialogState } from '@gain/utils/dialog'
import Stack from '@mui/material/Stack'

import Button, { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import PillIndicator from '../../Components/Indicators/pill-indicator.component'
import PageContentList from '../../Components/Layout/PageContentList'
import NameWithLogo from '../../Components/NameWithLogo/NameWithLogo'
import StickyTableHeader from '../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_LARGE,
  CELL_TYPE_PILL,
  CELL_TYPE_SMALL,
} from '../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../Components/Tabular/TabularRow/TabularRow'
import { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import CustomerActions from '../../Compositions/Actions/CustomerActions'
import ActionsMenu from '../../Compositions/ActionsMenu/ActionsMenu'
import AddButton from '../../Compositions/Buttons/AddButton'
import { TabularCellSortLink } from '../../Compositions/Link/TabularCellSortLink'
import CustomerCreateModal from '../../Compositions/Modals/CreateModals/CustomerCreateModal'
import ExportCustomerUsageDialog from '../../Compositions/Modals/export-customer-usage-dialog.component'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { useCurrencyName } from '../../util/currency'
import { CUSTOMER_METHODS } from '../../util/methods'
import { CUSTOMER_GENERAL_PATH } from '../../util/path'
import { pathGenerate } from '../../util/pathGenerate'
import { toastCreate } from '../../util/toastCreate'
import { ListContainer } from './helpers/ListContainer'

export default function CustomersPage() {
  const [exportUsersDialogOpen, handleDialogOpen, handleDialogClose] = useDialogState()
  const currencyNameCallback = useCurrencyName()

  return (
    <>
      <PageHeaderComposition
        extras={
          <Stack
            direction={'row'}
            spacing={1}>
            <Button
              onClick={handleDialogOpen}
              styleName={BUTTON_STYLENAME_PILL}>
              Export usage report
            </Button>
            <AddButton onClick={toastCreate(<CustomerCreateModal />)} />
          </Stack>
        }
        title={'Customers'}
      />
      <PageContentList>
        <StickyTableHeader>
          <Tabular styleName={TABULAR_HEADER}>
            <TabularRow styleName={ROWTYPE_HEAD}>
              <TabularCellSortLink
                sort={'-name'}
                styleName={CELL_TYPE_LARGE}>
                Name
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-usersActive'}
                styleName={CELL_TYPE_SMALL}>
                Active users
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-usersDeactivated'}
                styleName={CELL_TYPE_SMALL}>
                Deactivated users
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-userDefaultCurrency'}
                styleName={CELL_TYPE_SMALL}>
                Default currency
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-deactivated'}
                styleName={CELL_TYPE_PILL}>
                Status
              </TabularCellSortLink>
              <TabularCell styleName={CELL_TYPE_ACTIONS} />
            </TabularRow>
          </Tabular>
        </StickyTableHeader>
        <Tabular>
          <ListContainer>
            {(data) =>
              data.map((item) => (
                <MethodContextProvider
                  key={item.id}
                  data={item}
                  methods={CUSTOMER_METHODS}>
                  <TabularRow styleName={ROWTYPE_HOVER}>
                    <TabularCell styleName={CELL_TYPE_CLICK}>
                      <TabularRow
                        to={pathGenerate(CUSTOMER_GENERAL_PATH, {
                          id: item.id,
                        })}>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_LARGE}>
                          <NameWithLogo
                            logoUrl={item.logoFileUrl}
                            name={item.name}
                          />
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>{item.usersActive}</TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>
                          {item.usersDeactivated}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>
                          {currencyNameCallback(item.defaultUserCurrency)}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_PILL}>
                          <PillIndicator
                            live={!item.deactivated}
                            texts={['Activated', 'Deactivated']}
                          />
                        </TabularCell>
                      </TabularRow>
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_ACTIONS}>
                      <ActionsMenu
                        content={
                          <CustomerActions
                            data={item}
                            listPage
                          />
                        }
                        openerColorStyle={'darken'}
                      />
                    </TabularCell>
                  </TabularRow>
                </MethodContextProvider>
              ))
            }
          </ListContainer>
        </Tabular>
      </PageContentList>

      <ExportCustomerUsageDialog
        handleClose={handleDialogClose}
        open={exportUsersDialogOpen}
      />
    </>
  )
}
