import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, memo } from 'react'

import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { withClassNames } from '../HigherOrder/withClassNames'
import Text, { SIZE_HEADER_3 } from '../Text/Text'
import styles from './ModalBoxHead.module.scss'

export type ModalBoxHeadProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalBoxHead: FunctionComponent<ModalBoxHeadProps> = ({ children, ...rest }) => (
  <div {...rest}>
    <Text
      colorName={COLORNAME_TEXT_PRIMARY}
      size={SIZE_HEADER_3}>
      {children}
    </Text>
  </div>
)

export default memo(withClassNames(ModalBoxHead, styles)) as typeof ModalBoxHead
