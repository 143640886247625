import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import Search from '../../Components/Search/Search'
import { replaceCurrent } from '../../redux/action/redirect'
import { getParams } from '../../util/getParams'
import { LIST_PATH } from '../../util/path'
import { pathGenerate } from '../../util/pathGenerate'
import { getSearch, QUERY } from '../../util/search'

interface SearchCompositionProps {
  path?: string
}

export default function SearchComposition(props: SearchCompositionProps) {
  const dispatch = useDispatch()
  const [query, setQuery] = useState(getSearch())
  const timeoutRef = useRef<number | undefined>()

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      const path = props.path || LIST_PATH
      const params = getParams(path)

      if (typeof query === 'string' && query.trim().length > 0) {
        // always order by similarity when searching in assets
        if (params.method === 'data.listAssets') {
          params.sort = '-similarity'
        }

        dispatch(
          replaceCurrent(`${pathGenerate(path, params)}?${QUERY}=${encodeURIComponent(query)}`)
        )
      } else {
        dispatch(replaceCurrent(pathGenerate(path, params)))
      }
    }, 500)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [dispatch, props.path, query])

  return (
    <Search
      defaultValue={getSearch()}
      onChange={(event) => {
        setQuery(event.target.value)
      }}
    />
  )
}
