import LaunchIcon from '@mui/icons-material/Launch'
import IconButton from '@mui/material/IconButton'
import { FieldValues, useFormContext } from 'react-hook-form'

import { useFieldName } from './input-form-hooks'

interface InputEndAdornmentHrefProps<Item extends FieldValues, Field extends keyof Item> {
  field: Field
  formatUrl?: (value?: Item[Field]) => string | undefined
}

export default function InputEndAdornmentHref<
  Item extends Record<string, any>,
  Field extends keyof Item
>({ field, formatUrl }: InputEndAdornmentHrefProps<Item, Field>) {
  const fieldName = useFieldName(field as string)
  const form = useFormContext()
  const value = form.watch(fieldName)

  if (!value) {
    return null
  }

  const href = formatUrl?.(value) ?? value

  if (!href || typeof href !== 'string') {
    return null
  }

  return (
    <IconButton
      href={href}
      rel={'noopener noreferrer'}
      size={'small'}
      target={'_blank'}>
      <LaunchIcon />
    </IconButton>
  )
}
