import { ReactNode } from 'react'

import MethodContext from '../../Context/method.context'
import { partialDelete } from '../../util/partial'

interface DeleteArrayItemCompositionProps {
  path: string
  children: (callback: () => void) => ReactNode
  allowDeleteWhenDisabled?: boolean
}

export default function DeleteArrayItemComposition({
  path,
  children,
  allowDeleteWhenDisabled,
}: DeleteArrayItemCompositionProps) {
  return (
    <MethodContext.Consumer>
      {({ update, disabled }) => {
        if (disabled && !allowDeleteWhenDisabled) {
          return null
        }

        return children(() => {
          update(partialDelete(path))
        })
      }}
    </MethodContext.Consumer>
  )
}
