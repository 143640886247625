import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'

import ActionsMenuItem from '../../common/actions-menu-item'
import { useInputFormContext } from '../../common/input-fields'
import { useSwrDataGrid } from '../../common/swr-data-grid'

const StyledDeleteGridActionsCellItem = styled(ActionsMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  fill: theme.palette.error.main,

  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.error.main,
  },
}))

interface DataGridActionsArchiveProps {
  type: string
  isArchived: boolean
  canBeArchived: boolean
  // MUI requires this prop to show item in menu (data grid)
  showInMenu?: boolean
}

export default function DataGridActionsArchive({
  type,
  isArchived,
  canBeArchived,
}: DataGridActionsArchiveProps) {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()

  return (
    <StyledDeleteGridActionsCellItem
      key={'archive'}
      disabled={!canBeArchived}
      icon={isArchived ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />}
      label={`${isArchived ? 'Unarchive' : 'Archive'} ${type}`}
      onClick={async () => {
        if (isArchived) {
          await inputFormContext.unArchive()
        } else {
          await inputFormContext.archive()
        }

        if (!swrDataGrid.api) {
          return
        }

        // Refresh the grid
        await swrDataGrid.api.swr.mutate()
      }}
    />
  )
}
