import { useFormContext } from 'react-hook-form'

import { useFieldName } from './input-form-hooks'

export function useInputGroupData<Data>(field?: string): Data {
  const fieldName = useFieldName(field)
  const { getValues } = useFormContext()

  return getValues(fieldName)
}
