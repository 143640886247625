import { Issue } from '@gain/rpc/cms-model'
import { createSelector } from '@reduxjs/toolkit'
import { ChangeEvent, createContext, FocusEvent, useContext, useEffect } from 'react'
import { FieldPath, FieldValues, Noop, useFormContext, UseFormReturn } from 'react-hook-form'
import { useSelector } from 'react-redux'

export interface InputFormContextAPI {
  recordId: string | null
  issues: Issue[]
  disabled: boolean
  name?: string

  busy: boolean
  saving: boolean
  publishing: boolean
  validating: boolean
  deleting: boolean
  archiving: boolean

  namePrefix?: string

  fetch: () => Promise<undefined | object>
  publish: (ignoreWarnings?: boolean, callback?: () => void) => Promise<void>
  unPublish: (callback?: () => void) => Promise<boolean>
  delete: (callback?: () => void) => void
  archive: () => Promise<boolean>
  unArchive: () => Promise<boolean>

  patch: (partial: object) => Promise<boolean>
  onBlur: (
    fieldName: unknown,
    formOnBlur: Noop,
    force?: boolean,
    skipParentCheck?: boolean
  ) => (event?: FocusEvent<never> | ChangeEvent) => Promise<void>
}

export const emptyInputFormContext: InputFormContextAPI = {
  recordId: null,
  issues: [],
  disabled: false,
  busy: false,
  saving: false,
  publishing: false,
  validating: false,
  deleting: false,
  archiving: false,
  fetch: async () => {
    return undefined
  },
  patch: async (partial: object) => {
    return false
  },
  publish: async () => {
    // Do nothing
  },
  unPublish: async () => {
    return false
  },
  archive: async () => {
    return false
  },
  unArchive: async () => {
    return false
  },
  delete: async () => {
    // Do nothing
  },
  onBlur:
    (fieldName: unknown, formOnBlur: Noop) =>
    async (event?: FocusEvent<never> | ChangeEvent): Promise<void> =>
      formOnBlur(),
}

export const InputFormContext = createContext<InputFormContextAPI>(emptyInputFormContext)

export function useInputFormContext() {
  return useContext(InputFormContext)
}

export function useFieldName<TName>(name?: TName): TName {
  const inputForm = useInputFormContext()

  if (!inputForm || !inputForm.namePrefix) {
    return name ?? ('' as TName)
  }

  return [inputForm.namePrefix, name].filter(Boolean).join('.') as TName
}

export function useInputFormData<Data>(field?: string | string[]): Data | undefined {
  const { getValues } = useFormContext()

  const value = getValues(field as FieldPath<never>)

  if (value && (typeof value !== 'object' || Object.keys(value).length > 0)) {
    return value
  }

  return undefined
}

/**
 * This hook syncs the errors from the old form context to the new one
 */
export function useSyncFormIssues<Item extends FieldValues>(
  form: UseFormReturn<Item, unknown, undefined>,
  formIssues: Issue[]
) {
  const oldFormErrors = useSelector(
    createSelector(
      [(state: { validateItem: { data: Issue[] } }) => state.validateItem.data],
      (errors) => errors
    )
  )

  useEffect(() => {
    if (oldFormErrors && oldFormErrors.length > 0) {
      // We are going to set errors from the old context so reset existing ones
      // (this also makes sure that the user does not have to press publish twice)
      form.clearErrors()

      oldFormErrors.forEach(async (error: Issue) => {
        if (error.type === 'error') {
          form.setError(error.path as never, {
            message: error.message,
          })
        }
      })
    }
  }, [form, oldFormErrors])

  return formIssues.concat(oldFormErrors)
}
