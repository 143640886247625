import { useRpcClient } from '@gain/api/swr'
import { Address, RpcMethodMap, SuggestAddressResponse } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import {
  ForwardedRef,
  forwardRef,
  SyntheticEvent,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import * as uuid from 'uuid'

import { InputFieldTextProps } from './input-field-text'
import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldAddressProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputFieldTextProps<TFieldValues, TName> {
  countryCodeField: string
}

export interface InputFieldAddressRef {
  updateOrCreateAddress: (addressId: number | null, address: Address) => Promise<number>
}

function isAddress(value: SuggestAddressResponse | Address): value is Address {
  return !(value as SuggestAddressResponse)['googlePlaceId']
}

function InputFieldAddress<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  { label, name, required, countryCodeField }: InputFieldAddressProps<TFieldValues, TName>,
  ref: ForwardedRef<InputFieldAddressRef>
) {
  const inputForm = useInputFormContext()
  const { watch } = useFormContext()
  const fieldName = useFieldName<TName>(name)

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Array<SuggestAddressResponse | Address>>([])
  const fetcher = useRpcClient<RpcMethodMap>()
  const sessionTokenRef = useRef(uuid.v4())
  const countryCode = watch(countryCodeField)

  const { enqueueSnackbar } = useSnackbar()

  const { field, fieldState } = useController<TFieldValues>({
    name: fieldName,
    rules: { required },
    // @ts-expect-error this will never match
    defaultValue: null,
  })

  const handleUpdateOrCreateAddress = useCallback(
    async (addressId: number | null, { id, ...address }: Address) => {
      if (typeof addressId === 'number') {
        // If we already have an addressId, update that address
        await fetcher({
          method: 'data.updateAddress',
          params: {
            id: addressId,
            partial: address,
          },
        })

        return addressId
      } else {
        // Otherwise, create a new address
        const createdAddress = await fetcher({
          method: 'data.createAddress',
          params: {
            partial: address,
          },
        })

        return createdAddress.id
      }
    },
    [fetcher]
  )

  useImperativeHandle(ref, () => ({
    updateOrCreateAddress: handleUpdateOrCreateAddress,
  }))

  const isOptionEqualToValue = useCallback(
    (option: SuggestAddressResponse | Address, value: SuggestAddressResponse | Address) => {
      if (!isAddress(option) && !isAddress(value) && option.googlePlaceId === value.googlePlaceId) {
        return true
      } else if (isAddress(option) && isAddress(value) && option.id === value.id) {
        return true
      }

      return false
    },
    []
  )

  // Returns an address for the given googlePlaceId in the locale of the given countryCode
  const getAddressByPlaceId = useCallback(
    async (googlePlaceId: string): Promise<Address> => {
      return fetcher({
        method: 'data.getAddressByPlaceId',
        params: {
          googlePlaceId,
          countryCode,
        },
      })
    },
    [countryCode, fetcher]
  )

  const handleAutocompleteOptionSelected = useCallback(
    async (
      event: SyntheticEvent,
      newValue: SuggestAddressResponse | Address | null | (SuggestAddressResponse | Address)[],
      reason: AutocompleteChangeReason
    ) => {
      if (Array.isArray(newValue)) {
        return
      }

      if (reason === 'clear') {
        setOptions([])
      }

      if (newValue === null) {
        // When the new value equals null, we set the address id to null
        field.onChange(null as FieldPathValue<TFieldValues, TName>)
        sessionTokenRef.current = uuid.v4()
      } else if (!isAddress(newValue)) {
        // When the new value is a suggested address we fetch the address details,
        // update or insert it, and store the id in the linked address id path
        try {
          field.onChange(
            (await getAddressByPlaceId(newValue.googlePlaceId)) as FieldPathValue<
              TFieldValues,
              TName
            >
          )

          sessionTokenRef.current = uuid.v4()
        } catch (e) {
          // dispatch(handleEpicRpcError(e))
          setOptions([])
        }
      }
    },
    [field, getAddressByPlaceId]
  )

  const handleInputValueChanged = useCallback(
    async (event: SyntheticEvent, newInputValue: string) => {
      setInputValue(newInputValue)

      if (newInputValue === '') {
        // When the input is empty the only visible option is the currently selected option
        setOptions(field.value ? [field.value] : [])
      } else {
        // When there is an input value we merge the selected option with the suggested options

        try {
          const nextOptions = await fetcher({
            method: 'data.suggestAddress',
            params: {
              countryCode,
              input: newInputValue,
              sessionToken: sessionTokenRef.current,
            },
          })

          let newOptions = new Array<SuggestAddressResponse | Address>()

          if (field.value) {
            newOptions = [field.value]
          }

          if (nextOptions) {
            newOptions = [...newOptions, ...nextOptions]
          }

          setOptions(newOptions)
        } catch (err) {
          if (isJsonRpcError(err)) {
            enqueueSnackbar(err.message, {
              key: 'suggest-address-error',
              preventDuplicate: true,
              variant: 'error',
            })
          }
        }
      }
    },
    [countryCode, enqueueSnackbar, fetcher, field.value]
  )

  return (
    <Autocomplete
      disabled={inputForm.disabled || !countryCode}
      getOptionLabel={(option) =>
        isAddress(option) ? option.formattedAddress : option.description
      }
      inputValue={inputValue}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={
        <>
          No locations found,&nbsp;
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              inputValue
            )}`}
            rel={'noopener noreferrer'}
            target={'_blank'}>
            search google maps
          </a>
        </>
      }
      onChange={handleAutocompleteOptionSelected}
      onInputChange={handleInputValueChanged}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete={'off'}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error && fieldState.error.message}
          label={label}
          name={fieldName}
          required={required}
          slotProps={{
            input: params.InputProps,
          }}
        />
      )}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>{isAddress(option) ? option.formattedAddress : option.description}</li>
      )}
      value={field.value}
      autoComplete
      filterSelectedOptions
      fullWidth
      includeInputInList
    />
  )
}

export default forwardRef(InputFieldAddress)
