import PropTypes from 'prop-types'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { OnBlur, OnChange } from 'react-final-form-listeners'
import MethodContext from '../../Context/method.context'
import { partial } from '../../util/partial'
import { UpdateForm } from '../UpdateForm/UpdateForm'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import FormLabel, { FORMLABEL_SECONDARY } from '../../Components/FormElements/FormLabel/FormLabel'
import Input from '../../Components/FormElements/Input/Input'
import useInputSelectStyles from '../../util/use-input-select-styles'
import registerType from '../../json/registerType.json'
import ColumnedItems from '../../Components/SpacedItems/ColumnedItems'
import {
  ENTITY_COURT,
  ENTITY_ID,
  ENTITY_SUFFIX,
  ENTITY_TYPE,
  GERMAN_SEPARATOR,
  getIdAndSuffix,
  REGISTER_COURT_OPTIONS,
} from '../../util/germanEntityId'

const RegisterCourtForm = ({ data, path }) => {
  const styles = useInputSelectStyles()
  const saveValues = (update, dirty, values) => {
    if (dirty) {
      const newCourt = values[ENTITY_COURT].value || ''
      const newType = values[ENTITY_TYPE].value || ''
      const newId = values[ENTITY_ID] || ''
      const newSuffix = values[ENTITY_SUFFIX] || ''

      update(
        partial(
          path,
          `${newCourt}${GERMAN_SEPARATOR}${newType}${GERMAN_SEPARATOR}${newId}${newSuffix}`
        )
      )
    }
  }

  const { 0: court = '', 1: type = '', 2: idSuffix = '' } = data[path].split(GERMAN_SEPARATOR)
  const { id, suffix } = getIdAndSuffix(idSuffix)

  return (
    <UpdateForm
      subscription={{ values: true, dirty: true }}
      initialValues={{
        [ENTITY_COURT]: { label: court, value: court },
        [ENTITY_TYPE]: {
          label: type === '' ? 'Not available' : type,
          value: type,
        },
        [ENTITY_ID]: id,
        [ENTITY_SUFFIX]: suffix,
      }}>
      {(form, dirty, values) => (
        <>
          <MethodContext.Consumer>
            {({ update }) => (
              <>
                <OnChange name={ENTITY_COURT}>{() => saveValues(update, dirty, values)}</OnChange>
                <OnChange name={ENTITY_TYPE}>{() => saveValues(update, dirty, values)}</OnChange>
                <OnBlur name={ENTITY_ID}>{() => saveValues(update, dirty, values)}</OnBlur>
                <OnBlur name={ENTITY_SUFFIX}>{() => saveValues(update, dirty, values)}</OnBlur>
              </>
            )}
          </MethodContext.Consumer>

          <InputGroupItem>
            <ColumnedItems columns={4}>
              <div>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Register court</FormLabel>
                <Field
                  name={ENTITY_COURT}
                  type="select"
                  options={REGISTER_COURT_OPTIONS}>
                  {({ input, ...rest }) => (
                    <Select
                      placeholder="Select register court"
                      styles={styles}
                      {...input}
                      {...rest}
                    />
                  )}
                </Field>
              </div>
              <div>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Register type</FormLabel>
                <Field
                  name={ENTITY_TYPE}
                  type="select"
                  options={registerType}>
                  {({ input, ...rest }) => (
                    <Select
                      placeholder="Select register type"
                      styles={styles}
                      {...input}
                      {...rest}
                    />
                  )}
                </Field>
              </div>
              <div>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Entity number</FormLabel>
                <Field
                  name={ENTITY_ID}
                  type="text">
                  {({ input }) => (
                    <Input
                      placeholder="Entity number"
                      input={input}
                    />
                  )}
                </Field>
              </div>
              <div>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Suffix</FormLabel>
                <Field
                  name={ENTITY_SUFFIX}
                  type="text">
                  {({ input }) => (
                    <Input
                      placeholder="Suffix"
                      input={input}
                    />
                  )}
                </Field>
              </div>
            </ColumnedItems>
          </InputGroupItem>
        </>
      )}
    </UpdateForm>
  )
}

RegisterCourtForm.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

export default RegisterCourtForm
