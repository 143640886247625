import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  resetPasswordSuccess,
  RESET_PASSWORD,
  RESET_PASSWORD_CANCELLED,
} from '../action/userPassword'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(RESET_PASSWORD),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => resetPasswordSuccess(response, action)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(RESET_PASSWORD_CANCELLED)))
        )
      )
    )
  )
