import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  fetchAfterUpdateItemSuccess,
  FETCH_AFTER_UPDATE,
  FETCH_AFTER_UPDATE_CANCELLED,
  FETCH_AFTER_UPDATE_SUCCESS,
} from '../action/fetchAfterUpdateItem'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(FETCH_AFTER_UPDATE),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => fetchAfterUpdateItemSuccess(response, action.next)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(FETCH_AFTER_UPDATE_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(FETCH_AFTER_UPDATE_SUCCESS),
      filter(({ next }) => isFunction(next)),
      // Wrapping response in object for backwards compatibility with
      // all the `next()` / callback chained code in the UI.
      map(({ response, next }) => next({ result: response }))
    )
  )
