import { LegalEntity } from '@gain/rpc/cms-model'
import Box from '@mui/material/Box'
import { Fragment } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { useSelector } from 'react-redux'

import Divider from '../../../Components/Divider/Divider'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import PageContent from '../../../Components/Layout/PageContent'
import AddAnnualReport from '../../../Compositions/File/helpers/add-annual-report'
import AddAnnualReportFile from '../../../Compositions/File/helpers/add-annual-report-file'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import subscription from '../../../Compositions/UpdateForm/helpers/subscription'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import cmsTheme from '../../../theme/theme'
import { canDownloadNewReports } from '../../../util/annual-report'
import DownloadAnnualReports from './helpers/DownloadAnnualReports'
import LegalEntityAnnualReportFormFileLine from './helpers/legal-entity-annual-report-file-form-line.component'
import LegalEntityAnnualReportFormLine from './helpers/legal-entity-annual-report-form-line.component'
import { ANNUAL_REPORTS } from './helpers/legalEntityDetailNames'

export default function LegalEntityDetailProfilePage() {
  const legalEntity: LegalEntity = useSelector((state: any) => state.fetchItem.data)
  const downloading = useSelector(
    (state: any) => Object.keys(state.downloadAnnualReports.downloading).length > 0
  )

  return (
    <PageContent>
      <FormLabelComposition>Annual reports</FormLabelComposition>

      {canDownloadNewReports(legalEntity.region) && <DownloadAnnualReports entity={legalEntity} />}

      <InputGroupComposition>
        {!downloading && (
          <UpdateForm initialValues={legalEntity}>
            {() => (
              <>
                {/* AnnualReport rows */}
                <FieldArray
                  name={ANNUAL_REPORTS}
                  subscription={subscription}>
                  {({ fields }) =>
                    fields.map((arPath, arIndex) => (
                      <Fragment key={arPath}>
                        <InputGroupItem>
                          <LegalEntityAnnualReportFormLine
                            key={arPath}
                            name={arPath}
                          />
                        </InputGroupItem>

                        {/* AnnualReportFile rows */}
                        <FieldArray
                          name={`${arPath}.files`}
                          subscription={subscription}>
                          {({ fields: fileFields }) =>
                            fileFields.map((filePath) => (
                              <Fragment key={filePath}>
                                <Divider style={{ margin: '0 10px' }} />
                                <InputGroupItem
                                  style={{ backgroundColor: cmsTheme.palette.background.default }}>
                                  <LegalEntityAnnualReportFormFileLine
                                    key={filePath}
                                    arName={arPath}
                                    name={filePath}
                                  />
                                </InputGroupItem>
                              </Fragment>
                            ))
                          }
                        </FieldArray>

                        {/* Add file on sublevel */}
                        <Divider style={{ margin: '0 10px' }} />
                        <InputGroupItem
                          style={{ backgroundColor: cmsTheme.palette.background.default }}>
                          <AddAnnualReportFile arIndex={arIndex} />
                        </InputGroupItem>

                        <Divider />
                      </Fragment>
                    ))
                  }
                </FieldArray>

                {/* Add AnnualReport */}
                <InputGroupItem>
                  <AddAnnualReport previous={legalEntity[ANNUAL_REPORTS][0]} />
                </InputGroupItem>
              </>
            )}
          </UpdateForm>
        )}

        {downloading && <Box p={1}>Downloading new annual reports, please wait…</Box>}
      </InputGroupComposition>
    </PageContent>
  )
}
