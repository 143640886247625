import { Investor, InvestorListItem } from '@gain/rpc/cms-model'
import { InvestorStatus } from '@gain/rpc/shared-model'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
} from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams'
import { ReactElement } from 'react'

import ActionsMenuItem from '../../../common/actions-menu-item'
import { useInputFormContext } from '../../../common/input-fields'
import { useSwrDataGrid } from '../../../common/swr-data-grid'
import DataGridActionsArchive from '../../../layout/data-grid/data-grid-actions-archive'
import DataGridActionsDelete from '../../../layout/data-grid/data-grid-actions-delete'

export interface InvestorActionsProps
  extends Pick<GridRowParams<InvestorListItem | Investor>, 'row'> {
  hidePublish?: boolean
}

/**
 * Renders investor actions on the investor page and inside the SWR data grid.
 */
export default function InvestorActions({
  row,
  hidePublish,
}: InvestorActionsProps): ReactElement<GridActionsCellItemProps>[] {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()

  // Show loader if we are rendering inside the grid and doing something
  if (inputFormContext.busy && swrDataGrid.api) {
    return [
      <GridActionsCellItem
        icon={<CircularProgress size={16} />}
        label={''}
      />,
    ]
  }

  return [
    !hidePublish && [InvestorStatus.Review, InvestorStatus.New].includes(row.status) && (
      <ActionsMenuItem
        key={'publish'}
        icon={<CheckCircleOutlinedIcon />}
        label={'Publish investor'}
        onClick={() => {
          inputFormContext.publish(false, () => {
            // Update the SWR data grid row
            if (swrDataGrid.api) {
              swrDataGrid.api.updateRow(row.id, {
                status: InvestorStatus.Published,
              })
            }
          })
        }}
        showInMenu
      />
    ),

    !hidePublish && row.status === InvestorStatus.Published && (
      <ActionsMenuItem
        key={'unpublish'}
        icon={<UnpublishedOutlinedIcon />}
        label={'Unpublish investor'}
        onClick={() => {
          inputFormContext.unPublish(() => {
            // Update the SWR data grid row
            if (swrDataGrid.api) {
              swrDataGrid.api.updateRow(row.id, {
                status: InvestorStatus.Review,
              })
            }
          })
        }}
        showInMenu
      />
    ),

    Boolean(row.publicationDate) && (
      <DataGridActionsArchive
        canBeArchived={row.status !== InvestorStatus.Published}
        isArchived={row.status === InvestorStatus.Archived}
        type={'investor'}
        showInMenu
      />
    ),
    !row.publicationDate && (
      <DataGridActionsDelete
        path={'/investors'}
        showInMenu
      />
    ),
  ].filter(Boolean) as ReactElement<GridActionsCellItemProps>[]
}
