import { useDebouncedCallback } from 'use-debounce'
import { StringParam, useQueryParam } from 'use-query-params'

export default function useSwrDataGridSearch(): [string, (query: string) => void] {
  const [search, setSearch] = useQueryParam('search', StringParam)

  const handleSearch = useDebouncedCallback(setSearch, 200)

  return [search || '', handleSearch]
}
