import { getEnvironment } from '@gain/utils/environment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './NotLive.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { memo } from 'react'

const NotLive = ({ className, children, ...rest }) => (
  <div
    className={classNames(className, styles[`environment-${getEnvironment().toUpperCase()}`])}
    {...rest}>
    <div className={styles.ear}>
      <div className={styles.txtWrap}>
        <div className={styles.txt}>{getEnvironment()}</div>
      </div>
    </div>
    <div className={styles.beamTop} />
    <div className={styles.beamLeft} />
  </div>
)

NotLive.propTypes = {
  env: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

NotLive.defaultProps = {
  env: '',
  className: '',
  children: undefined,
}

export default memo(withClassNames(NotLive, styles))
