import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import InputGroup from '../../../../Components/FormElements/InputGroup/InputGroup'
import InputGroupItem from '../../../../Components/FormElements/InputGroup/input-group-item'
import ContentAboveList from '../../../../Components/Layout/ContentAboveList'
import PageContent, { PAGE_CONTENT_WITH_TABBAR } from '../../../../Components/Layout/PageContent'
import { PageTitleComposition } from '../../../../Compositions/PageHeader/page-title-composition'
import AddIndustrySelect from '../../../../Compositions/Selects/AddAsyncSelect/AddIndustrySelect'
import MethodContext from '../../../../Context/method.context'
import { fetchListActions } from '../../../../redux/reducer/fetchList'
import { partialAdd, partialDelete } from '../../../../util/partial'
import IndustriesList from '../../../Lists/helpers/IndustriesList'
import { FORMLABEL_SECONDARY } from '../../../../Components/FormElements/FormLabel/FormLabel'
import { FormLabelComposition } from '../../../../Compositions/FormLabel/FormLabelComposition'

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  // eslint-disable-next-line no-param-reassign
  delete params.id
  return {
    fetchListData: () => dispatch(fetchListActions.fetchList(params)),
  }
}

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const ArticleIndustriesPage = ({ match, fetchListData, path, data: { linkedIndustries } }) => {
  useEffect(() => {
    fetchListData()
  }, [match, fetchListData])

  const attach = (update, industryId) => {
    update(
      partialAdd(`linkedIndustries[${linkedIndustries.length}]`, {
        industryId,
      })
    )
  }

  const detach = (update, industryId) => {
    const indexOf = linkedIndustries.findIndex((industry) => industry.industryId === industryId)
    if (indexOf !== -1) {
      update(partialDelete(`linkedIndustries[${indexOf}]`))
    }
  }

  return (
    <>
      <PageTitleComposition path={path}>Linked industries research</PageTitleComposition>
      <PageContent styleName={PAGE_CONTENT_WITH_TABBAR}>
        <MethodContext.Consumer>
          {({ update }) => (
            <>
              <ContentAboveList>
                <InputGroup>
                  <InputGroupItem>
                    <FormLabelComposition
                      yml={'linked_company'}
                      styleName={FORMLABEL_SECONDARY}>
                      Linked company
                    </FormLabelComposition>
                    <AddIndustrySelect
                      alreadySelected={linkedIndustries.map((item) => item.industryId)}
                      onChange={({ value }) => attach(update, value)}
                    />
                  </InputGroupItem>
                </InputGroup>
              </ContentAboveList>
              <IndustriesList
                path={path}
                detach={(industryId) => detach(update, industryId)}
              />
            </>
          )}
        </MethodContext.Consumer>
      </PageContent>
    </>
  )
}

ArticleIndustriesPage.propTypes = {
  fetchListData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArticleIndustriesPage))
