import { PropsWithChildren, useContext } from 'react'

import MethodContext from './method.context'
import { useMethodContextApi } from './use-method-context-api.hook'

interface MethodContextProviderProps {
  methods: any
  data: any
}

export function MethodContextProvider({
  children,
  methods,
  data,
}: PropsWithChildren<MethodContextProviderProps>) {
  const api = useMethodContextApi(methods, data)

  return <MethodContext.Provider value={api}>{children}</MethodContext.Provider>
}

export function useMethodContext() {
  const context = useContext(MethodContext)

  if (!context) {
    throw new Error('MethodContext not provided')
  }

  return context
}
