import Head from '@gain/modules/head'
import PropTypes from 'prop-types'
import Text, { SIZE_HEADER_3 } from '../../../../Components/Text/Text'
import PageHeader from '../../../../Components/Layout/PageHeader'
import CenteredPageHeader from '../../../../Components/PageTitle/CenteredPageHeader'
import CenteredPageHeaderTitle from '../../../../Components/PageTitle/CenteredPageHeaderTitle'
import PageNavigationComposition from '../../../../Compositions/PageNavigation/PageNavigationComposition'
import { LIST_ASSETS_METHOD, LIST_INDUSTRIES_METHOD } from '../../../../util/methods'
import PageHeaderButtons from '../../../../Components/PageTitle/PageHeaderButtons'
import StatusToggleButtonComposition from '../../../../Compositions/ItemActions/StatusToggleButtonComposition'
import ColoredDotIndicator from '../../../../Components/Indicators/colored-dot-indicator'
import ActionsMenu from '../../../../Compositions/ActionsMenu/ActionsMenu'
import ArticleActions from '../../../../Compositions/Actions/ArticleActions'

const ArticleDetailHeader = ({ links, data, params, path, articleTypeName }) => {
  const paramLinks = links.map((link) => {
    if (link.tab === 'assets') {
      return {
        ...link,
        params: {
          ...params,
          method: LIST_ASSETS_METHOD,
          sort: 'name',
          filter: `id=${data.linkedAssets.map((item) => item.assetId).join('|')}`,
        },
      }
    }

    if (link.tab === 'research') {
      return {
        ...link,
        params: {
          ...params,
          method: LIST_INDUSTRIES_METHOD,
          sort: 'title',
          filter: `id=${data.linkedIndustries.map((item) => item.industryId).join('|')}`,
        },
      }
    }

    return {
      ...link,
      params,
    }
  })

  return (
    <PageHeader>
      <CenteredPageHeader>
        <div />
        <CenteredPageHeaderTitle>
          <Head>
            <title>{data.title}</title>
          </Head>
          <Text size={SIZE_HEADER_3}>{data.title}</Text>
          <ColoredDotIndicator
            title={articleTypeName}
            live={data.published}
          />
        </CenteredPageHeaderTitle>
        <PageHeaderButtons>
          <ActionsMenu
            content={
              <ArticleActions
                data={data}
                listPath={path}
              />
            }
          />
          <StatusToggleButtonComposition live={data.published} />
        </PageHeaderButtons>
      </CenteredPageHeader>
      <PageNavigationComposition links={paramLinks} />
    </PageHeader>
  )
}

ArticleDetailHeader.propTypes = {
  links: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  articleTypeName: PropTypes.string.isRequired,
}

export default ArticleDetailHeader
