import PropTypes from 'prop-types'
import Button, { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'

import IconAndText from '../../Components/Icons/IconAndText'
import { ICON_ADD } from '../../Components/Icons/Icon'
import { memo } from 'react'

const AddButton = ({ onClick, children }) => (
  <Button
    styleName={BUTTON_STYLENAME_PILL}
    onClick={onClick}>
    <IconAndText icon={ICON_ADD}>{children}</IconAndText>
  </Button>
)

AddButton.defaultProps = {
  children: 'Add',
}

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default memo(AddButton)
