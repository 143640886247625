import { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { fetchListActions } from '../../../../redux/reducer/fetchList'
import { PageTitleComposition } from '../../../../Compositions/PageHeader/page-title-composition'
import PageContent, { PAGE_CONTENT_WITH_TABBAR } from '../../../../Components/Layout/PageContent'
import ContentAboveList from '../../../../Components/Layout/ContentAboveList'
import MethodContext from '../../../../Context/method.context'
import { partialAdd, partialDelete } from '../../../../util/partial'
import AddAssetSelect from '../../../../Compositions/Selects/AddAsyncSelect/AddAssetSelect'
import InputGroup from '../../../../Components/FormElements/InputGroup/InputGroup'
import InputGroupItem from '../../../../Components/FormElements/InputGroup/input-group-item'
import AssetsList from '../../../Lists/helpers/assets-list.component'
import { FORMLABEL_SECONDARY } from '../../../../Components/FormElements/FormLabel/FormLabel'
import { FormLabelComposition } from '../../../../Compositions/FormLabel/FormLabelComposition'

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  // eslint-disable-next-line no-param-reassign
  delete params.id
  return {
    fetchListData: () => dispatch(fetchListActions.fetchList(params)),
  }
}

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const ArticleAssetsPage = ({ match, fetchListData, path, data: { linkedAssets } }) => {
  useEffect(() => {
    fetchListData()
  }, [match, fetchListData])

  const attach = (update, assetId) => {
    update(
      partialAdd(`linkedAssets[${linkedAssets.length}]`, {
        assetId,
      })
    )
  }

  const detach = (update, assetId) => {
    const indexOf = linkedAssets.findIndex((asset) => asset.assetId === assetId)
    if (indexOf !== -1) {
      update(partialDelete(`linkedAssets[${indexOf}]`))
    }
  }

  return (
    <>
      <PageTitleComposition path={path}>Linked companies</PageTitleComposition>
      <PageContent styleName={PAGE_CONTENT_WITH_TABBAR}>
        <MethodContext.Consumer>
          {({ update }) => (
            <>
              <ContentAboveList>
                <InputGroup>
                  <InputGroupItem>
                    <FormLabelComposition
                      yml={'linked_company'}
                      styleName={FORMLABEL_SECONDARY}>
                      Linked company
                    </FormLabelComposition>
                    <AddAssetSelect
                      alreadySelected={linkedAssets.map((item) => item.assetId)}
                      onChange={({ value }) => attach(update, value)}
                    />
                  </InputGroupItem>
                </InputGroup>
              </ContentAboveList>
              <AssetsList
                path={path}
                jsonPath={'linkedAssets'}
                onUnlinkAsset={(assetId) => detach(update, assetId)}
              />
            </>
          )}
        </MethodContext.Consumer>
      </PageContent>
    </>
  )
}

ArticleAssetsPage.propTypes = {
  fetchListData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArticleAssetsPage))
