import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router'

import ActionsMenuItem from '../../common/actions-menu-item'
import { useInputFormContext } from '../../common/input-fields'
import { useSwrDataGrid } from '../../common/swr-data-grid'

const StyledDeleteGridActionsCellItem = styled(ActionsMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  fill: theme.palette.error.main,

  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.error.main,
  },
}))

interface DataGridActionsDeleteProps {
  path: string

  // MUI requires this prop to show item in menu (data grid)
  showInMenu?: boolean
}

export default function DataGridActionsDelete({ path }: DataGridActionsDeleteProps) {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()
  const history = useHistory()

  return (
    <StyledDeleteGridActionsCellItem
      key={'delete'}
      icon={<DeleteIcon />}
      label={'Delete'}
      onClick={() => {
        inputFormContext.delete(() => {
          // From detail page, redirect back to assets table
          if (!swrDataGrid.api) {
            history.push(path)
            return
          }

          // Refresh the grid
          swrDataGrid.api.swr.mutate()
        })
      }}
    />
  )
}
