import Head from '@gain/modules/head'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography, { typographyClasses } from '@mui/material/Typography'
import { ReactElement } from 'react'

export interface ItemPageHeaderProps {
  title: string
  subtitle?: ReactElement
  actions?: ReactElement

  className?: string
}

const Root = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  minHeight: 64,
  maxHeight: 64,
  backgroundColor: 'rgb(27, 39, 51)', // theme.palette.background.default,
  padding: theme.spacing(0, 2.5),
  display: 'grid',
  gridTemplateColumns: 'minmax(5%,1fr) auto minmax(5%,1fr)',
  alignItems: 'center',
  zIndex: 3,

  [`& .${typographyClasses.root}.${typographyClasses.h3}`]: {
    color: theme.palette.common.white,
  },
}))

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(),
}))

export function ItemPageHeader({ title, subtitle, actions, className }: ItemPageHeaderProps) {
  return (
    <Root className={className}>
      <Head>
        <title>{title}</title>
      </Head>

      <div />
      <Stack
        justifyContent={'center'}
        maxWidth={700}
        textAlign={'center'}>
        <Typography
          variant={'h3'}
          noWrap>
          {title}
        </Typography>

        {subtitle}
      </Stack>

      <Actions>{actions}</Actions>
    </Root>
  )
}
