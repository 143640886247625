import { baseUrl } from '@gain/rpc/utils'
import { Visibility } from '@mui/icons-material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import { generatePath } from 'react-router'

interface PreviewButtonProps {
  to: string
  params: object
  label: string
  variant?: 'icon-button' | 'button'
  onBeforePreview?: () => Promise<void>
}

export default function PreviewButton({
  to,
  params,
  label,
  variant = 'icon-button',
  onBeforePreview,
}: PreviewButtonProps) {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    if (onBeforePreview) {
      setLoading(true)
      await onBeforePreview()
      setLoading(false)
    }

    const url = `${baseUrl}${generatePath(to, params)}?preview=1`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const text = `Preview ${label.toLowerCase()}`
  const icon = loading ? (
    <CircularProgress
      color={'inherit'}
      size={16}
    />
  ) : (
    <Visibility />
  )

  if (variant === 'icon-button') {
    return (
      <Tooltip
        title={text}
        disableInteractive>
        <IconButton
          disabled={loading}
          onClick={handleClick}
          sx={{ color: 'common.white' }}>
          {icon}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Button
      color={'primary'}
      disabled={loading}
      onClick={handleClick}
      startIcon={icon}
      variant={'outlined'}>
      {text}
    </Button>
  )
}
