import PropTypes from 'prop-types'
import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import Text, { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import { COLORNAME_SUPPORT_4 } from '../../util/colors'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'

const DeleteModal = ({ action, title, name, subTitle }) => (
  <ModalBox>
    <ModalBoxHead>{`Delete ${title}`}</ModalBoxHead>
    <ModalBoxBody>
      <InputGroupItem>
        Are you sure you want to delete
        <Text
          colorName={COLORNAME_SUPPORT_4}
          size={SIZE_CAPTION_1}
          tag="span">
          {` ${name}`}
        </Text>
        {`? ${subTitle} This cannot be undone.`}
      </InputGroupItem>
    </ModalBoxBody>
    <ModalBoxFoot>
      <FormButton
        styleName={BUTTON_STYLENAME_PILL}
        type="button"
        onClick={action}>
        {`Delete ${title}`}
      </FormButton>
    </ModalBoxFoot>
  </ModalBox>
)

DeleteModal.propTypes = {
  action: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

DeleteModal.defaultProps = {
  subTitle: '',
}

export default DeleteModal
