import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { tabClasses } from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ReactNode } from 'react'
import { useParams } from 'react-router'

import ItemPageTab, { TabConfig } from './item-page-tab'

export interface ItemPageTabsProps {
  tabs: ReadonlyArray<TabConfig>
  actions?: ReactNode
}

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered: boolean }>(({ theme, centered }) => ({
  position: 'sticky',
  // 64 = height of the item page header
  top: 64,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.04)',
  zIndex: 2,

  ...(!centered && {
    display: 'flex',
    justifyContent: 'space-between',
  }),
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${tabClasses.root}`]: {
    padding: theme.spacing(2),
  },
}))

export function ItemPageTabs({ tabs, actions }: ItemPageTabsProps) {
  const params = useParams<{ tab?: string }>()

  return (
    <Root centered={!actions}>
      <StyledTabs
        value={params.tab ?? false}
        variant={'scrollable'}>
        {tabs.map((tab) => (
          <ItemPageTab
            key={tab.id}
            tab={tab}
            value={tab.id}
          />
        ))}
      </StyledTabs>

      {actions && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={0.5}
          pl={1}
          pr={3}>
          {actions}
        </Stack>
      )}
    </Root>
  )
}
