import { ChangeEvent } from 'react'

export interface InputFieldTransform {
  input: (value: string | number) => string | number
  output: (event: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>
}

export const defaultTransform: InputFieldTransform = {
  input: (value) => value || '',
  output: (event) => event,
}
