import Button from '@mui/material/Button'
import { useCallback } from 'react'

import { useInputFormContext } from '../../common/input-fields'

export interface ItemPagePublishActionProps {
  live?: boolean
  onPublish?: (publish: () => Promise<void>) => void
}

export function ItemPagePublishAction({ live, onPublish }: ItemPagePublishActionProps) {
  const formContext = useInputFormContext()

  const handlePublishClick = useCallback(async () => {
    if (onPublish) {
      onPublish(async () => await formContext.publish())
    } else {
      await formContext.publish()
    }
  }, [formContext, onPublish])

  const handleUnpublishClick = useCallback(async () => {
    await formContext.unPublish()
  }, [formContext])

  if (!formContext) {
    return null
  }

  return (
    <div>
      {live && (
        <Button
          color={'error'}
          onClick={handleUnpublishClick}
          variant={'contained'}>
          Unpublish
        </Button>
      )}

      {!live && (
        <Button
          onClick={handlePublishClick}
          variant={'contained'}>
          Publish
        </Button>
      )}
    </div>
  )
}
