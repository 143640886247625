import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { isEmpty, isObject, setWith } from 'lodash'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldClearButtonProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  className?: string
}

/**
 * Checks if the provided field value is empty.
 */
function isFieldValueEmpty(fieldValue: unknown): boolean {
  if (isEmpty(fieldValue)) {
    return true
  }

  if (isObject(fieldValue)) {
    return Object.values(fieldValue).every((value) => value === null || value === undefined)
  }

  return false
}

/**
 * Renders button that will clear the field that is provided (used with groups to reset e.g. "share." field)
 */
export default function InputFieldClearButton<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name, className }: InputFieldClearButtonProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const form = useFormContext()

  const fieldValue = form.watch(fieldName)

  return (
    <Stack
      className={className}
      justifyContent={'center'}>
      <IconButton
        disabled={isFieldValueEmpty(fieldValue) || inputForm.disabled}
        onClick={() => {
          inputForm.patch(setWith({}, fieldName, null, Object))
        }}
        size={'small'}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}
