import { styled } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'

interface MarkdownProps {
  source: string
}

const StyledReactMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body2,
  margin: theme.spacing(1, 0),
  'h1,h2,h3': {
    ...theme.typography.h6,
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  ul: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: 0,
  },
  p: {
    marginBottom: theme.spacing(1),
  },
  a: {
    color: theme.palette.primary.main,
  },
}))

export default function Markdown({ source }: MarkdownProps) {
  return <StyledReactMarkdown children={source} />
}
