import { FieldPath, FieldValues } from 'react-hook-form'

import InputFieldText, { InputFieldTextProps } from './input-field-text'

export type InputFieldTextNumberProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = InputFieldTextProps<TFieldValues, TName>

export default function InputFieldNumber<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: InputFieldTextNumberProps<TFieldValues, TName>) {
  return (
    <InputFieldText
      type={'number'}
      {...props}
    />
  )
}
