import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import Input from '../../Components/FormElements/Input/Input'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import OnBlurSave from './helpers/OnBlurSave'
import InputError from '../../Components/FormElements/Error/InputError'

const InputNumber = ({ path, title, min, max, step, showUpDownArrows, alignInputText }) => (
  <>
    <Field
      name={path}
      type="number">
      {({ input, meta }) => (
        <>
          <Input
            alignInputText={alignInputText}
            showUpDownArrows={showUpDownArrows}
            placeholder={title}
            input={input}
            min={min}
            max={max}
            step={step}
          />
          <OnBlurSave
            path={path}
            value={step ? parseInt(input.value, 10) : parseFloat(input.value)}
            dirty={meta.dirty}
          />
        </>
      )}
    </Field>
    <ErrorContainer path={path}>
      {(onClick, hasErrors) => (
        <InputError
          onClick={onClick}
          hasErrors={hasErrors}
        />
      )}
    </ErrorContainer>
  </>
)

InputNumber.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  showUpDownArrows: PropTypes.bool,
  alignInputText: PropTypes.string,
}

InputNumber.defaultProps = {
  min: undefined,
  max: undefined,
  step: undefined,
  showUpDownArrows: true,
  alignInputText: undefined,
}

export default InputNumber
