import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './IconAndText.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Icon from './Icon'
import Text from '../Text/Text'
import { memo } from 'react'

const IconAndText = ({
  icon,
  iconSize,
  iconColor,
  children,
  className,
  text,
  direction,
  after,
  ...rest
}) => {
  return (
    <Text
      className={classNames(className, after && styles.iconAfter)}
      {...rest}>
      <Icon
        style={{ fontSize: iconSize && iconSize }}
        iconColor={iconColor}
        direction={direction}
        className={styles.icon}
        src={icon}
      />
      <div className={styles.iconText}>{text || children}</div>
    </Text>
  )
}

IconAndText.defaultProps = {
  className: '',
  iconColor: undefined,
  text: '',
  icon: undefined,
  iconSize: undefined,
  direction: undefined,
  children: undefined,
  after: false,
}

IconAndText.propTypes = {
  className: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.node,
  iconSize: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.string,
  after: PropTypes.bool,
}

export default memo(withClassNames(IconAndText, styles))
