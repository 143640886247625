import { CurrencyListItem } from '@gain/rpc/cms-model'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { loadCurrenciesSelectors } from '../redux/reducer/loadCurrencies'

/**
 * Returns "Danish Krone (DKK)" if currency display is "code", or
 * "Euro (€)" if currency display is "symbol".
 */
function formatCurrencyName(currency: CurrencyListItem) {
  if (currency.display === CurrencyDisplayType.Symbol) {
    return `${currency.title} (${currency.symbol})`
  }

  return `${currency.title} (${currencySymbol(currency)})`
}

/**
 * Custom hook to get the currency name from the currency name.
 */
export function useCurrencyName() {
  const currencies = useSelector(loadCurrenciesSelectors.all)

  return useCallback(
    (currencyName: string | null | undefined) => {
      if (!currencyName) {
        return '-'
      }

      const currency = currencies.find(({ name }: CurrencyListItem) => name === currencyName)
      if (!currency) {
        return '-'
      }

      return formatCurrencyName(currency)
    },
    [currencies]
  )
}

/**
 * Returns "DKK" if currency display is "code", or "€" if currency
 * display is "symbol".
 */
export function currencySymbol(currency: CurrencyListItem) {
  if (currency.display === CurrencyDisplayType.Symbol) {
    return currency.symbol
  }

  return currency.name // Name actually contains the ISO code
}
