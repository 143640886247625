import Badge, { badgeClasses, BadgeProps } from '@mui/material/Badge'
import { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    left: theme.spacing(-1.25),
    top: 9,
    height: 10,
    width: 10,
  },

  verticalAlign: 'baseline', // The default 'middle' has an off-by-1px alignment issue
  marginLeft: theme.spacing(2), // Creates the room for the dot
}))

interface ColoredDotIndicatorProps {
  text?: string
  dotColor?: ChipProps['color']
  title?: string
  live?: boolean
  offlineText?: string
}

export default function ColoredDotIndicator({
  live,
  dotColor,
  text,
  offlineText,
  title,
}: ColoredDotIndicatorProps) {
  let labelTxt = text
  let iconColor = dotColor

  if (live !== undefined) {
    if (iconColor === undefined) {
      iconColor = live ? 'success' : 'error'
    }

    // Offline text
    if (!live) {
      // Offline text overrules regular text
      if (offlineText) {
        labelTxt = offlineText
      } else if (labelTxt === undefined) {
        labelTxt = 'Offline'
      }
    } else if (!labelTxt) {
      // Regular text serves as "Online" text, this is the fallback
      labelTxt = 'Online'
    }
  }

  if (title !== undefined) {
    labelTxt += ` ${title}`
  }

  return (
    <Typography
      color={'grey.400'}
      fontWeight={'bold'}
      lineHeight={1.25}
      variant={'body2'}>
      <StyledBadge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        badgeContent={' '}
        color={iconColor}
        variant={'dot'}>
        {labelTxt}
      </StyledBadge>
    </Typography>
  )
}
