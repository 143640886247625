import { AnnualReport } from '@gain/rpc/cms-model'
import { getAnnualReportName } from '@gain/utils/annual-report'
import { useMemo } from 'react'
import { useField } from 'react-final-form'

import SpacedItems, {
  SPACED_ITEMS_STYLENAME_NOWRAP,
} from '../../../../Components/SpacedItems/SpacedItems'
import FlexItem from '../../../../Components/Tabular/FlexTable/FlexItem'
import Text from '../../../../Components/Text/Text'
import InputMonthYear from '../../../../Compositions/Inputs/InputMonthYear'
import { FlexContainerRepeaterComposition } from '../../../../Compositions/Repeater/flex-container-repeater-composition'

interface LegalEntityAnnualReportFormLineProps {
  name: string
}

export default function LegalEntityAnnualReportFormLine(
  props: LegalEntityAnnualReportFormLineProps
) {
  const { name } = props
  const field = useField<AnnualReport>(name)
  const report = field.input.value

  const hasInternalFiles = useMemo(() => report.files.some((arFile) => arFile.internal), [report])

  return (
    <>
      {/* Annual report row */}
      <FlexContainerRepeaterComposition
        confirmText={
          'Are you sure you want to delete this annual report and all of the attached files?'
        }
        name={name}
        style={{ alignItems: 'center' }}
        warningText={
          hasInternalFiles
            ? [
                'There are internal files connected to this annual report that are used for parsing financials.',
                'By deleting these files, the financials will also be lost. Are you sure?',
              ].join(' ')
            : undefined
        }>
        <FlexItem
          style={{ flex: '1 1 auto', maxWidth: '100%', alignItems: 'center', margin: '0 10px' }}>
          <strong>{getAnnualReportName(report)}</strong>
        </FlexItem>

        {/* Book year start / end */}
        <FlexItem style={{ flex: '0 0 240px' }}>
          <SpacedItems styleName={SPACED_ITEMS_STYLENAME_NOWRAP}>
            <InputMonthYear path={`${name}.bookYearStart`} />
            <Text>to</Text>
            <InputMonthYear
              path={`${name}.bookYearEnd`}
              end
            />
          </SpacedItems>
        </FlexItem>
      </FlexContainerRepeaterComposition>
    </>
  )
}
