import { useRpcClient } from '@gain/api/swr'
import Tooltip from '@gain/components/tooltip'
import { RpcMethodMap, UserListItem } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useCallback } from 'react'

import { toastCreate } from '../../../util/toastCreate'
import { toastDismiss } from '../../../util/toastDismiss'
import { toastError } from '../../../util/toastError'
import DeleteModal from '../../Modals/DeleteModal'
import { UserActiveSharedListsModal } from '../../Modals/UserActiveSharedListsModal'

const DELETE_USER_MODAL_TOAST = 'deleteUserModal'

interface UserDeleteButtonProps {
  user: UserListItem
  afterAction: () => void
  variant?: 'default' | 'icon'
}

export function UserDeleteButton({
  user,
  variant = 'default',
  afterAction,
}: UserDeleteButtonProps) {
  const rpcClient = useRpcClient<RpcMethodMap>()

  const name = `${user.firstName} ${user.lastName}`

  const deleteUser = useCallback(() => {
    ;(async () => {
      try {
        toastDismiss(DELETE_USER_MODAL_TOAST)

        await rpcClient({
          method: 'customer.deleteUser',
          params: { id: user.id },
        })

        afterAction()
      } catch (error) {
        if (isJsonRpcError(error)) {
          toastError(error.data?.[0]?.message ?? error.message)
        }
      }
    })()
  }, [rpcClient, user.id, afterAction])

  const handleClick = useCallback(async () => {
    // Make sure to have the latest user data
    const latestUserData = await rpcClient({
      method: 'customer.getUser',
      params: { id: user.id },
    })

    if (latestUserData.hasSharedBookmarks) {
      toastCreate(
        <UserActiveSharedListsModal
          firstName={user.firstName}
          lastName={user.lastName}
          mode={'delete'}
          onClick={deleteUser}
        />,
        DELETE_USER_MODAL_TOAST
      )()
    } else {
      toastCreate(
        <DeleteModal
          action={deleteUser}
          name={name}
          title={'user'}
        />,
        DELETE_USER_MODAL_TOAST
      )()
    }
  }, [rpcClient, name, user, deleteUser])

  if (variant === 'icon') {
    return (
      <Tooltip title={`Delete ${name}`}>
        <IconButton onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <MenuItem
      onClick={handleClick}
      sx={{ color: 'error.main' }}>
      Delete user
    </MenuItem>
  )
}
