import * as yup from 'yup'

export const PASSWORD_MIN_LENGTH_MIN = 12
export const PASSWORD_MIN_LENGTH_MAX = 20

// The below yup schema defines the validation rules for CustomerSecuritySettings
export default yup.object({
  // Password requirements
  passwordMinLength: yup
    .number()
    .required()
    .min(PASSWORD_MIN_LENGTH_MIN)
    .max(PASSWORD_MIN_LENGTH_MAX),
  passwordNotLastN: yup
    .number()
    .transform((value) => value || null) // Convert empty string into null
    .min(0)
    .nullable(),

  // Single Sign On
  ssoEnabled: yup.boolean().required(),
  workOsOrganisationId: yup
    .string()
    .nullable()
    .when('ssoEnabled', {
      is: true,
      then: (schema) => schema.required(),
    }),
  ssoDomains: yup
    .array()
    .of(
      yup
        .string()
        .matches(/\.[a-z]{2,}$/, 'Enter a correct domain name')
        .matches(/^(?![a-z]+:\/\/)/, 'Please omit http(s)://')
    )
    .transform((domains) => domains.filter(Boolean))
    .when('ssoEnabled', {
      is: true,
      then: (schema) => schema.required().min(1, 'Enter at least 1 domain name'),
    }),

  // Sessions & Account deactivation
  tokenExpiration: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .min(0)
    .nullable(),
  maxFailedLoginAttempts: yup
    .number()
    .transform((value) => value || null) // Convert empty string into null
    .min(1)
    .nullable(),
  maxPeriodOfInactivity: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .min(0)
    .nullable(),

  // IP whitelisting
  ipWhitelistEnabled: yup.boolean().required(),
  ipWhitelistCidrRanges: yup
    .array()
    .of(yup.string().matches(/^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/, 'Enter a correct IPv4 CIDR range'))
    .transform((domains) => domains.filter(Boolean))
    .when('ipWhitelistEnabled', {
      is: true,
      then: (schema) => schema.required().min(1, 'Enter at least 1 IPv4 CIDR range'),
    }),
})
