import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import ArticleAssetsPage from '../../Pages/Item/ArticleInthenewsDetail/helpers/ArticleAssetsPage'
import ArticleInthenewsGeneralPage from '../../Pages/Item/ArticleInthenewsDetail/ArticleInthenewsGeneralPage'
import { ARTICLE_INTHENEWS_ASSETS_PATH, ARTICLE_INTHENEWS_GENERAL_PATH } from '../../util/path'
import ArticleDetailHeader from '../../Pages/Item/ArticleInthenewsDetail/helpers/ArticleDetailHeader'
import ItemContainer from './helpers/ItemContainer'
import { ARTICLE_METHODS } from '../../util/methods'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { pathGenerate } from '../../util/pathGenerate'
import { getParams } from '../../util/getParams'
import { isSameAsCurrentPathname } from '../../util/getPathname'
import { DEFAULT_INTHENEWS_LIST_PATH } from '../../util/pathDefaults'

const LINKS = [
  { title: 'General', path: ARTICLE_INTHENEWS_GENERAL_PATH, tab: 'general' },
  {
    title: 'Companies',
    path: ARTICLE_INTHENEWS_ASSETS_PATH,
    tab: 'assets',
    fieldNames: ['linkedAssets'],
  },
]

const ArticleInthenewsDetailRoutes = ({ match: { params } }) => (
  <ItemContainer>
    {(data) => (
      <MethodContextProvider
        data={data}
        methods={ARTICLE_METHODS}>
        <ArticleDetailHeader
          links={LINKS}
          data={data}
          params={params}
          path={DEFAULT_INTHENEWS_LIST_PATH}
          articleTypeName="news item"
        />
        <Switch>
          <Route
            path={ARTICLE_INTHENEWS_ASSETS_PATH}
            exact>
            {() => {
              const assetListPath = pathGenerate(ARTICLE_INTHENEWS_ASSETS_PATH, {
                ...getParams(ARTICLE_INTHENEWS_ASSETS_PATH),
                filter: `id=${data.linkedAssets.map((item) => item.assetId).join('|')}`,
              })

              return isSameAsCurrentPathname(assetListPath) ? (
                <ArticleAssetsPage path={ARTICLE_INTHENEWS_ASSETS_PATH} />
              ) : (
                <Redirect to={assetListPath} />
              )
            }}
          </Route>
          <Route
            path={ARTICLE_INTHENEWS_GENERAL_PATH}
            exact
            component={ArticleInthenewsGeneralPage}
          />
        </Switch>
      </MethodContextProvider>
    )}
  </ItemContainer>
)

ArticleInthenewsDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ArticleInthenewsDetailRoutes
