import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  unlinkFromExcelSuccess,
  UNLINK_FROM_EXCEL,
  UNLINK_FROM_EXCEL_CANCELLED,
} from '../action/unlinkFromExcelFile'
import { rpc } from './rpc'

export default (action$) =>
  action$.pipe(
    ofType(UNLINK_FROM_EXCEL),
    mergeMap((action) =>
      rpc(action, action.method, action.body).pipe(
        map((response) => unlinkFromExcelSuccess(response)),
        catchError((error) => of(error)),
        takeUntil(action$.pipe(ofType(UNLINK_FROM_EXCEL_CANCELLED)))
      )
    )
  )
