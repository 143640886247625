import { SpinnerIcon } from '@gain/components/icons'
import PublicPage from '@gain/components/public-page'
import { useIsAuthenticated } from '@gain/modules/auth'
import { useOnMountEffect } from '@gain/utils/react'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router'

import { HOME_PATH } from '../../util/path'
import { redirectToAppLogin } from '../../util/redirect'

/**
 * RouteLogin is a very small page that redirects to either the app login or the
 * CMS home page, depending on the authentication status of the user.
 */
export default function RouteLogin() {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()

  // Either redirect back to app login on error or to CMS
  useOnMountEffect(() => {
    if (!isAuthenticated) {
      redirectToAppLogin()
    } else {
      history.replace(HOME_PATH)
    }
  })

  return (
    <PublicPage>
      <SpinnerIcon fontSize={'inherit'} />
      <Typography variant={'body2'}>Redirecting to login...</Typography>
    </PublicPage>
  )
}
