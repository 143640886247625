import { cloudErrorReporter, getErrorReporterConfig } from '@gain/modules/cloud-error-reporter'
import { getEnvironment } from '@gain/utils/environment'

const config = getErrorReporterConfig()

if (config != null) {
  cloudErrorReporter.start({
    key: config.apiKey,
    projectId: config.projectId,
    service: `cms-${getEnvironment()}`,
    version: process.env['NX_PUBLIC_APP_VERSION'],
  })
}
