import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { createNewsItems, NEWS_ITEMS } from '../../redux/action/createNewsItems'
import CheckRadio from '../../Components/FormElements/CheckRadio/CheckRadio'
import ModalBox from '../../Components/Modal/ModalBox'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import { INDUSTRY_RESEARCH } from '../../util/articleTypes'
import { NAV_RESEARCH } from '../../util/navigationTitles'

const mapDispatchToProps = (dispatch, { id }) => ({
  doCreateNewsItems: (values) => dispatch(createNewsItems(id, values)),
})

const IndustryPublishedModal = ({ doCreateNewsItems }) => (
  <Form onSubmit={(values) => doCreateNewsItems(values)}>
    {({ handleSubmit, pristine, submitting, invalid, dirtySinceLastSubmit }) => (
      <form onSubmit={handleSubmit}>
        <ModalBox>
          <ModalBoxHead>Create news item after publish</ModalBoxHead>
          <ModalBoxBody>
            <InputGroupItem>
              <Field
                type="checkbox"
                name={NEWS_ITEMS}
                value={INDUSTRY_RESEARCH}>
                {({ input }) => (
                  <CheckRadio
                    input={input}
                    label={NAV_RESEARCH}
                  />
                )}
              </Field>
            </InputGroupItem>
          </ModalBoxBody>
          <ModalBoxFoot>
            <FormButton
              styleName={BUTTON_STYLENAME_PILL}
              spinner={submitting}
              disabled={!dirtySinceLastSubmit && (submitting || pristine || invalid)}>
              Create
            </FormButton>
          </ModalBoxFoot>
        </ModalBox>
      </form>
    )}
  </Form>
)

IndustryPublishedModal.propTypes = {
  doCreateNewsItems: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(IndustryPublishedModal)
