import { MainNavigationItemProps } from './main-navigation-item'

export function navItems(...items: Array<MainNavigationItemProps | null | undefined | false>) {
  return items.filter(Boolean) as MainNavigationItemProps[]
}

export function navItem(
  path: string,
  title: string,
  options?: { children?: MainNavigationItemProps[]; adminOnly?: boolean }
): MainNavigationItemProps {
  return {
    title,
    path,
    shortcuts: options?.children,
    adminOnly: options?.adminOnly,
  }
}
