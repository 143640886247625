import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, ignoreElements, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  previewSourcingUpdateShow,
  previewSourcingUpdateSuccess,
  PREVIEW_SOURCING_UPDATE,
  PREVIEW_SOURCING_UPDATE_CANCELLED,
  PREVIEW_SOURCING_UPDATE_SUCCESS,
  sendSourcingUpdateSuccess,
  SEND_SOURCING_UPDATE,
  SEND_SOURCING_UPDATE_CANCELLED,
} from '../action/userEmail'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(PREVIEW_SOURCING_UPDATE),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => previewSourcingUpdateSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(PREVIEW_SOURCING_UPDATE_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(PREVIEW_SOURCING_UPDATE_SUCCESS),
      map(({ response }) => {
        const newWindow = window.open('url', '_blank') || {
          document: { write: (body) => body },
        }
        newWindow.document.write(response.body)
        return previewSourcingUpdateShow(response)
      }),
      ignoreElements()
    ),

    action$.pipe(
      ofType(SEND_SOURCING_UPDATE),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => sendSourcingUpdateSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(SEND_SOURCING_UPDATE_CANCELLED)))
        )
      )
    )
  )
