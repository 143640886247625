import {
  CREDIT_SUB_TYPE_LOOKUP,
  CREDIT_SUBTYPE_OPTIONS,
  CreditSubtype,
  CreditType,
  Option,
} from '@gain/rpc/shared-model'
import { valueToEnum } from '@gain/utils/common'
import { useFormContext } from 'react-hook-form'

import { InputFieldSelect } from './index'

function getValidSubtypeOptions(typeStr: string) {
  const type = valueToEnum(CreditType, typeStr)

  if (!type) {
    return new Array<Option<CreditSubtype>>()
  }

  return CREDIT_SUBTYPE_OPTIONS.filter((o) => {
    return CREDIT_SUB_TYPE_LOOKUP[type].includes(o.value)
  })
}

interface InputFieldCreditSubtypeProps {
  label: string
  name: string
  typeFieldName: string
}

export default function InputFieldCreditSubtype({
  label,
  name,
  typeFieldName,
}: InputFieldCreditSubtypeProps) {
  const form = useFormContext()
  const type = form.watch(typeFieldName)
  const options = getValidSubtypeOptions(type)

  return (
    <InputFieldSelect
      disabled={options.length === 0}
      label={label}
      name={name}
      options={options}
    />
  )
}
