import { useRpcClient } from '@gain/api/swr'
import { RpcListMethods } from '@gain/rpc/cms-model'
import { ListFilter, ListSort } from '@gain/rpc/list-model'
import { listFilter, serializeListSort } from '@gain/rpc/utils'
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import {
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import { TextFieldVariants } from '@mui/material/TextField/TextField'
import debounce from '@mui/utils/debounce'
import {
  Fragment,
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'

import InputFieldHelperText from './input-field-helper-text'
import { useInputFieldIssues } from './input-field-hooks'
import { InputFieldTextProps } from './input-field-text'
import { useFieldName, useInputFormContext } from './input-form-hooks'
import StyledTextField from './styled-text-field'

function buildListFilter<Method extends keyof RpcListMethods, Item extends RpcListMethods[Method]>(
  prop: keyof Item,
  value: Item | Item[] | string | number
): ListFilter<Item>[] {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return []
    }

    return [
      listFilter(
        prop as never,
        '=',
        value.map((val) => (typeof val === 'object' ? val[prop] : val)) as never
      ),
    ]
  }

  if (typeof value === 'object') {
    return [listFilter(prop as never, '=', value[prop] as never)]
  }

  return [listFilter(prop as never, '=', value as never)]
}

/**
 * Extracts the value that we want to store as the field value from the items
 */
function getChangeValue<Item>(items: null | Item[] | Item, valueProp: keyof Item) {
  if (items === null) {
    return null
  }

  // In case of a multi select autocomplete, we store the entire item array
  if (Array.isArray(items)) {
    return items
  }

  // When the autocomplete is used to select a single value, store the value
  // that is defined in the valueProp argument
  if (typeof items === 'object' && valueProp in items) {
    return items[valueProp]
  }

  return null
}

export interface InputFieldAutocompleteProps<
  Method extends keyof RpcListMethods,
  Item extends RpcListMethods[Method],
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<InputFieldTextProps<TFieldValues, TName>, 'onChange'>,
    Pick<
      AutocompleteProps<Item, boolean | undefined, boolean | undefined, false>,
      'slotProps' | 'limitTags' | 'multiple' | 'disableClearable'
    > {
  method: Method
  labelProp: keyof Item
  valueProp: keyof Item
  enableCheckboxes?: boolean
  variant?: TextFieldVariants
  getOptionLabel?: (option: Item) => ReactNode
  defaultFilter?: ListFilter<Item>[]
  defaultSort?: ListSort<Item>[]
  alreadySelectedItems?: Item[]
  onBeforeChange?: (value, item: Item) => void
  onAfterChange?: (value, item: Item) => void
  renderTags?: (value: Item[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode
}

export default function InputFieldAutocomplete<
  Method extends keyof RpcListMethods,
  Item extends RpcListMethods[Method],
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  method,
  labelProp,
  valueProp,
  multiple = false,
  disabled,
  enableCheckboxes,
  disableClearable,
  getOptionLabel: getOptionLabelProp,
  variant,
  required,
  defaultFilter,
  defaultSort,
  renderTags,
  onBeforeChange,
  onAfterChange,
  slotProps,
  limitTags,
  consistencyGuideName,
  alreadySelectedItems,
}: InputFieldAutocompleteProps<Method, Item, TFieldValues, TName>) {
  // Map to any as rpc client expects something like RpcMethodMap, and we use the RpcListMethods here
  // so the types will never match
  const rpcClient = useRpcClient<any>()
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const issues = useInputFieldIssues(fieldName)

  const fetchedInitialItems = useRef<boolean>(false)

  const { field, fieldState } = useController<TFieldValues>({
    name: fieldName,
    rules: { required },
    // @ts-expect-error this will never match
    defaultValue: multiple ? [] : null,
  })

  const initialSearch = useRef(true)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Item[]>([])
  const [searchQuery, setSearchQuery] = useState<string>()
  const [searchError, setSearchError] = useState(false)
  const helperText =
    (fieldState.error && fieldState.error.message) ||
    (searchError && 'Error while executing request, please try again')

  const loading = open && !fetchedInitialItems.current

  const handleFetch = useCallback(
    async (search: string | undefined, callback: (results: Item[]) => void) => {
      // Prevent fetching if there is no value in the field, no value in the search, and it's the initial search
      // Prevent fetching when user gave the disabled prop directly to the component
      if ((!field.value && !search && initialSearch.current) || disabled) {
        return callback([])
      }

      setSearchError(false)

      try {
        const response = await rpcClient({
          method,
          params: {
            search: search || '',
            limit: 100,

            sort: defaultSort?.map(serializeListSort),

            ...(field.value && !search && initialSearch.current
              ? {
                  filter: (defaultFilter || []).concat(
                    buildListFilter<Method, Item>(valueProp, field.value)
                  ),
                }
              : {
                  filter: defaultFilter,
                }),
          },
        })
        fetchedInitialItems.current = true
        callback(response?.items)
      } catch {
        setSearchError(true)
      }
    },
    [defaultFilter, defaultSort, disabled, field.value, method, rpcClient, valueProp]
  )

  const debouncedFetch = useMemo(() => debounce(handleFetch, 300), [handleFetch])

  useEffect(() => {
    let active = true

    if (!method) {
      return () => {
        active = false
      }
    }

    debouncedFetch(searchQuery, (results: Item[]) => {
      if (active) {
        setOptions(results)

        initialSearch.current = false
      }
    })

    return () => {
      active = false
    }
  }, [searchQuery, loading, field.value, method, debouncedFetch])

  const handleOnClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOnOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onInputChange = useCallback(
    (_: SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (!initialSearch.current && reason !== 'reset') {
        setSearchQuery(newInputValue)
      }
    },
    []
  )

  const handleIsOptionEqualToValue = useCallback(
    (option, value) => {
      if (typeof value !== 'object') {
        return option[valueProp] === value
      }

      return option[valueProp] === value[valueProp]
    },
    [valueProp]
  )

  const getOptionLabel = useCallback(
    (option) => {
      if (typeof option !== 'object') {
        const foundOption = options.find((item) => item[valueProp] === option)

        if (foundOption) {
          option = foundOption
        }
      }

      if (getOptionLabelProp) {
        return getOptionLabelProp(option) || ''
      }

      // Option not found, return empty string
      if (!option) {
        return ''
      }

      return option[labelProp] || ''
    },
    [getOptionLabelProp, labelProp, options, valueProp]
  )

  const handleOnChange = useCallback(
    (_, items) => {
      const changeValue = getChangeValue(items, valueProp)

      onBeforeChange?.(changeValue, items)

      // Propagate changes to form, this will save the changes to the API using
      // the update method
      field.onChange(changeValue as never)

      onAfterChange?.(changeValue, items)
    },
    [field, onAfterChange, onBeforeChange, valueProp]
  )

  const handleServerFilter = useCallback((serverOptions) => {
    return serverOptions
  }, [])

  const handleInputOnKeyDown = useCallback((event: KeyboardEvent) => {
    // https://github.com/mui/material-ui/issues/36133
    // Fixes issue that when clicking input and enter the first char the input will blur
    event.stopPropagation()
  }, [])

  const handleRenderTags = useCallback(
    (items: Item[], getCustomizedTagProps: AutocompleteRenderGetTagProps) => {
      return items.map((option, index) => (
        <Chip
          label={getOptionLabel(option)}
          size={'small'}
          {...getCustomizedTagProps({ index })}
        />
      ))
    },
    [getOptionLabel]
  )

  const handleRenderCheckboxOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: Item,
      { selected }: AutocompleteRenderOptionState
    ) => (
      <li {...props}>
        <Checkbox
          checked={selected}
          checkedIcon={<CheckBoxIcon fontSize={'small'} />}
          icon={<CheckBoxOutlineBlankIcon fontSize={'small'} />}
          style={{ marginRight: 8 }}
        />
        {getOptionLabel(option)}
      </li>
    ),
    [getOptionLabel]
  )

  return (
    <Autocomplete
      ref={field.ref}
      disableClearable={disableClearable}
      disabled={inputForm.disabled || disabled}
      filterOptions={handleServerFilter}
      getOptionDisabled={(option) =>
        alreadySelectedItems?.some(
          (selectedItem) => selectedItem[valueProp] === option[valueProp]
        ) ?? false
      }
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      limitTags={limitTags}
      loading={loading}
      multiple={multiple}
      onBlur={inputForm.onBlur(fieldName, field.onBlur)}
      onChange={handleOnChange}
      onClose={handleOnClose}
      onInputChange={onInputChange}
      onOpen={handleOnOpen}
      open={open}
      options={options}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          autoComplete={'off'}
          error={issues.hasErrors}
          helperText={
            <InputFieldHelperText
              consistencyGuideLabel={label}
              consistencyGuideName={consistencyGuideName || name}
              errorMessage={issues.message}
              helperText={helperText}
            />
          }
          label={label}
          name={fieldName}
          onKeyDown={handleInputOnKeyDown}
          required={required}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading && (
                    <CircularProgress
                      color={'inherit'}
                      size={20}
                    />
                  )}

                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            },
          }}
          variant={variant}
          warning={issues.hasWarnings}
        />
      )}
      renderOption={enableCheckboxes ? handleRenderCheckboxOption : undefined}
      renderTags={renderTags ?? handleRenderTags}
      slotProps={slotProps}
      value={field.value}
      fullWidth
      includeInputInList
    />
  )
}
