import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { generatePath, useHistory } from 'react-router'

import {
  InputFieldGroup,
  InputFieldNumber,
  InputFieldRegion,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../common/input-fields'
import { createPersonSchema } from '../route-person/create-person-schema'
import { ROUTE_PERSON_PROFILE_PATH } from '../route-person/route-person-path'

interface CreatePersonDialogProps {
  show: boolean
  onClose: () => void
}

export default function CreatePersonDialog({ show, onClose }: CreatePersonDialogProps) {
  const inputFormAPI = useInputFormAPI({
    defaultValues: {
      firstName: '',
      lastName: '',
      birthYear: null,
      locationRegion: null,
    },
    validationSchema: createPersonSchema,
    createMethod: 'data.createPerson',
  })
  const history = useHistory()

  const handleClose = () => {
    inputFormAPI.form.reset()
    onClose()
  }

  const handleSubmit = inputFormAPI.form.handleSubmit(async (values) => {
    await inputFormAPI.create(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        birthYear: values.birthYear,
        locationRegion: values.locationRegion,
      },
      (person) => {
        history.push(
          generatePath(ROUTE_PERSON_PROFILE_PATH, {
            id: person.id,
          })
        )

        handleClose()
      }
    )
  })

  return (
    <Dialog
      keepMounted={false}
      maxWidth={'sm'}
      onClose={handleClose}
      open={show}
      fullWidth>
      <DialogTitle>Create a new person</DialogTitle>
      <DialogContent>
        <InputFormProvider form={inputFormAPI.form}>
          <Stack
            gap={2}
            mt={0.5}>
            <InputFieldGroup>
              <InputFieldText
                label={'First name'}
                name={'firstName'}
                required
              />

              <InputFieldText
                label={'Last name'}
                name={'lastName'}
                required
              />
            </InputFieldGroup>

            <InputFieldGroup>
              <InputFieldNumber
                label={'Birth year'}
                name={'birthYear'}
              />

              <InputFieldRegion
                label={'Location'}
                name={'locationRegion'}
              />
            </InputFieldGroup>
          </Stack>
        </InputFormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inputFormAPI.busy}
          onClick={handleClose}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          disabled={inputFormAPI.busy}
          onClick={handleSubmit}
          variant={'contained'}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
