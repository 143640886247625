import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { LEGAL_ENTITY_METHODS } from '../../util/methods'
import {
  downloadAnnualReportsSuccess,
  DOWNLOAD_ANNUAL_REPORTS,
  DOWNLOAD_ANNUAL_REPORTS_CANCELLED,
  DOWNLOAD_ANNUAL_REPORTS_SUCCESS,
} from '../action/downloadAnnualReports'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(DOWNLOAD_ANNUAL_REPORTS),
      mergeMap((action) =>
        rpc(action, LEGAL_ENTITY_METHODS.download, action.body).pipe(
          map((response) => downloadAnnualReportsSuccess(action.name, response, action.next)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(DOWNLOAD_ANNUAL_REPORTS_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(DOWNLOAD_ANNUAL_REPORTS_SUCCESS),
      filter(({ next }) => isFunction(next)),
      map(({ response, next }) => next(response))
    )
  )
