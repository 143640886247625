import PropTypes from 'prop-types'
import { withClassNames } from '../HigherOrder/withClassNames'
import styles from './ButtonsList.module.scss'
import { Children, memo } from 'react'

const ButtonsList = ({ children, ...rest }) => (
  <ul {...rest}>
    {Children.map(children, (child) => child && <li className={styles.listItem}>{child}</li>)}
  </ul>
)

ButtonsList.defaultProps = {
  children: undefined,
}

ButtonsList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default memo(withClassNames(ButtonsList, styles))
