import PropTypes from 'prop-types'
import InputError from '../../../Components/FormElements/Error/InputError'
import MethodContext from '../../../Context/method.context'
import { IMAGE_FIlES } from '../../../util/fileTypes'
import { ErrorContainer } from '../../UpdateForm/ErrorContainer'
import FileActionsGroup, {
  FILE_ACTION_REPLACE,
  FILE_ACTION_TYPE_IMAGE,
} from '../file-actions-group'
import InputFile from '../InputFile'

const ImageFieldPrefixed = ({ data, path, prefix }) => (
  <>
    <MethodContext.Consumer>
      {({ disabled }) =>
        disabled ? (
          <FileActionsGroup
            fileId={data[path][`${prefix}FileId`]}
            imageUrl={data[path][`${prefix}FileUrl`]}
            filename={data[path][`${prefix}Filename`] || 'Please add a file'}
            fileType={FILE_ACTION_TYPE_IMAGE}
            action={FILE_ACTION_REPLACE}
          />
        ) : (
          <InputFile
            path={`${path}.${prefix}FileId`}
            accept={IMAGE_FIlES}>
            <FileActionsGroup
              fileId={data[path][`${prefix}FileId`]}
              imageUrl={data[path][`${prefix}FileUrl`]}
              filename={data[path][`${prefix}Filename`] || 'Please add a file'}
              fileType={FILE_ACTION_TYPE_IMAGE}
              action={FILE_ACTION_REPLACE}
            />
          </InputFile>
        )
      }
    </MethodContext.Consumer>
    <ErrorContainer path={`${path}.${prefix}FileId`}>
      {(onClick, hasErrors) => (
        <InputError
          onClick={onClick}
          hasErrors={hasErrors}
        />
      )}
    </ErrorContainer>
  </>
)

ImageFieldPrefixed.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
}

export default ImageFieldPrefixed
