import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'

import CreatePersonDialog from './create-person-dialog'

export default function CreatePersonButton() {
  const [showDialog, handleShow, handleClose] = useDialogState()

  return (
    <>
      <Button
        onClick={handleShow}
        variant={'contained'}>
        Create new person
      </Button>
      <CreatePersonDialog
        onClose={handleClose}
        show={showDialog}
      />
    </>
  )
}
