import { ElementType, FunctionComponent, memo, ReactNode } from 'react'

import { withClassNames } from '../HigherOrder/withClassNames'
import Icon, { ICON_SPINNER } from '../Icons/Icon'
import styles from './Button.module.scss'

export const BUTTON_STYLENAME_ALERT = 'styleNameAlert'
export const BUTTON_STYLENAME_ALERT_SOFT = 'styleNameAlertSoft'
export const BUTTON_STYLENAME_TINT = 'styleNameTint'
export const BUTTON_STYLENAME_PILL = 'styleNameButtonPill'
export const BUTTON_STYLENAME_SECONDARY = 'styleNameSecondary'

export interface ButtonProps {
  type?: string
  styleName?: string
  disabled?: boolean
  spinner?: string | boolean
  before?: ReactNode
  text?: string
  after?: ReactNode
  children: string | ReactNode
  tagName?: ElementType<any>
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button: FunctionComponent<ButtonProps> = ({
  before,
  type,
  text,
  after,
  children,
  spinner,
  tagName = 'button',
  ...rest
}) => {
  const spinnerClass = typeof spinner === 'string' ? spinner : 'before'
  const TagName = tagName
  return (
    <TagName
      type={type || 'submit'}
      {...rest}>
      {spinner && (
        <div className={styles[`spinner-${spinnerClass}`]}>
          <Icon src={ICON_SPINNER} />
        </div>
      )}
      <div className={styles.elements}>
        {before && <div className={styles.before}>{before}</div>}
        <div className={styles.body}>{children}</div>
        {after && <div className={styles.after}>{after}</div>}
      </div>
    </TagName>
  )
}

export default memo(withClassNames(Button, styles))
