import { Flags } from '@gain/components/flag'
import classNames from 'classnames'
import { noop } from 'lodash'
import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ArrowLink from '../../common/arrow-link'
import PillIndicator from '../../Components/Indicators/pill-indicator.component'
import PageContentList from '../../Components/Layout/PageContentList'
import StickyTableHeader from '../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_SIZE_MEDIUM,
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_LARGE,
  CELL_TYPE_PILL,
  CELL_TYPE_REGION,
} from '../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../Components/Tabular/TabularRow/TabularRow'
import { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import ArticleActions from '../../Compositions/Actions/ArticleActions'
import ActionsMenu from '../../Compositions/ActionsMenu/ActionsMenu'
import AddButton from '../../Compositions/Buttons/AddButton'
import { TabularCellSortLink } from '../../Compositions/Link/TabularCellSortLink'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { createItem } from '../../redux/action/createItem'
import { nextRedirectTo } from '../../redux/action/redirect'
import { INDUSTRY_RESEARCH } from '../../util/articleTypes'
import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { DATE_HH_MM } from '../../util/dateFormat'
import getSubSectorTitle from '../../util/getSubSectorTitle'
import { ARTICLE_METHODS, CREATE_ARTICLE_METHOD } from '../../util/methods'
import { ARTICLE_RESEARCH_GENERAL_PATH, INDUSTRY_GENERAL_PATH } from '../../util/path'
import { DEFAULT_RESEARCH_LIST_PATH } from '../../util/pathDefaults'
import { pathGenerate } from '../../util/pathGenerate'
import { ListContainer } from './helpers/ListContainer'

export default function ArticlesResearchPage() {
  const subSectors = useSelector((state: any) => state.loadSubSectors.data)
  const dispatch = useDispatch()

  const doCreateItem = useCallback(() => {
    dispatch(
      createItem(
        CREATE_ARTICLE_METHOD,
        {
          partial: {
            type: INDUSTRY_RESEARCH,
            date: moment().toISOString(),
          },
        },
        noop,
        nextRedirectTo(ARTICLE_RESEARCH_GENERAL_PATH)
      )
    )
  }, [dispatch])

  return (
    <>
      <PageHeaderComposition
        extras={<AddButton onClick={doCreateItem} />}
        title={'Latest industry research'}
      />
      <PageContentList>
        <StickyTableHeader>
          <Tabular styleName={TABULAR_HEADER}>
            <TabularRow styleName={ROWTYPE_HEAD}>
              <TabularCellSortLink
                sort={'-title'}
                styleName={CELL_TYPE_LARGE}>
                Linked research
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-regions'}
                styleName={CELL_TYPE_REGION}>
                Regions
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-subsector'}
                styleName={CELL_SIZE_MEDIUM}>
                Category
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-date'}
                styleName={classNames(CELL_SIZE_MEDIUM)}>
                Date
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-published'}
                styleName={CELL_TYPE_PILL}>
                Status
              </TabularCellSortLink>
              <TabularCell styleName={CELL_TYPE_ACTIONS} />
            </TabularRow>
          </Tabular>
        </StickyTableHeader>

        <Tabular>
          <ListContainer>
            {(data) =>
              data.map((item) => (
                <MethodContextProvider
                  key={item.id}
                  data={item}
                  methods={ARTICLE_METHODS}>
                  <TabularRow styleName={ROWTYPE_HOVER}>
                    <TabularCell styleName={CELL_TYPE_CLICK}>
                      <TabularRow
                        to={pathGenerate(ARTICLE_RESEARCH_GENERAL_PATH, {
                          id: item.id,
                        })}>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_LARGE}>
                          <ArrowLink
                            to={pathGenerate(INDUSTRY_GENERAL_PATH, {
                              id: item.highlightedIndustryId,
                            })}>
                            {item.title}
                          </ArrowLink>
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_REGION}>
                          {item.regions && <Flags regions={item.regions.slice().sort()} />}
                        </TabularCell>
                        <TabularCell styleName={CELL_SIZE_MEDIUM}>
                          {getSubSectorTitle(subSectors, item)}
                        </TabularCell>
                        <TabularCell styleName={CELL_SIZE_MEDIUM}>
                          {moment(item.date).format(DATE_HH_MM)}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_PILL}>
                          <PillIndicator live={item.published} />
                        </TabularCell>
                      </TabularRow>
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_ACTIONS}>
                      <ActionsMenu
                        content={
                          <ArticleActions
                            data={item}
                            detailPath={ARTICLE_RESEARCH_GENERAL_PATH}
                            listPath={DEFAULT_RESEARCH_LIST_PATH}
                            listPage
                          />
                        }
                        openerColorStyle={'darken'}
                      />
                    </TabularCell>
                  </TabularRow>
                </MethodContextProvider>
              ))
            }
          </ListContainer>
        </Tabular>
      </PageContentList>
    </>
  )
}
