import Stack from '@mui/material/Stack'
import { PropsWithChildren } from 'react'

export interface InputFieldGroupProps {
  className?: string
}

/**
 * Renders a group of input fields.
 */
export default function InputFieldGroup({
  children,
  className,
}: PropsWithChildren<InputFieldGroupProps>) {
  return (
    <Stack
      alignItems={'center'}
      className={className}
      direction={'row'}
      gap={2}
      width={'100%'}>
      {children}
    </Stack>
  )
}
