import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PageContent from '../../../Components/Layout/PageContent'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import { REGION_RECOMMENDATIONS } from '../../../util/regionRecommendations'
import CheckboxArrayForm from '../../../Compositions/UpdateForm/checkbox-array-form.component'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { useCmsSwr } from '@gain/api/swr'

const RECOMMEND_REGIONS = 'recommendRegions'
const RECOMMEND_SUB_SECTORS = 'recommendSubsectors'

const mapStateToProps = (state) => ({
  sectors: state.loadSectors.data,
  subSectors: state.loadSubSectors.data,
  data: state.fetchItem.data,
})

function useRegions() {
  const regions = useCmsSwr('data.listRegions', {
    limit: 500,
  })

  if (!regions.data) {
    return []
  }

  return regions.data.items.filter((item) => REGION_RECOMMENDATIONS.includes(item.name))
}

const UserDetailMatchingPage = ({ sectors, subSectors, data }) => {
  const regions = useRegions()

  return (
    <PageContent>
      <FormLabel>Region recommendations</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          <CheckboxArrayForm
            data={data}
            path={RECOMMEND_REGIONS}
            items={regions}
          />
        </InputGroupItem>
      </InputGroupComposition>
      <FormLabel>Sector recommendations</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          {sectors.map((sector) => (
            <div key={sector.title}>
              <FormLabel styleName={FORMLABEL_SECONDARY}>{sector.title}</FormLabel>
              <CheckboxArrayForm
                data={data}
                path={RECOMMEND_SUB_SECTORS}
                items={subSectors.filter((item) => sector.name === item.sector)}
              />
            </div>
          ))}
        </InputGroupItem>
      </InputGroupComposition>
    </PageContent>
  )
}

UserDetailMatchingPage.propTypes = {
  sectors: PropTypes.array.isRequired,
  subSectors: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(UserDetailMatchingPage)
