import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  deleteItemSuccess,
  DELETE_ITEM,
  DELETE_ITEM_CANCELLED,
  DELETE_ITEM_SUCCESS,
} from '../action/deleteItem'
import { rpc } from './rpc'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(DELETE_ITEM),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => deleteItemSuccess(response, action.path, action.next)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(DELETE_ITEM_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(DELETE_ITEM_SUCCESS),
      filter(({ next }) => isFunction(next)),
      // Wrapping response in object for backwards compatibility with
      // all the `next()` / callback chained code in the UI.
      map(({ response, next }) => next({ result: response }))
    )
  )
