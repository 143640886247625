import { PropsWithChildren } from 'react'

import InputGroup from '../../Components/FormElements/InputGroup/InputGroup'
import MethodContext from '../../Context/method.context'

export interface InputGroupCompositionProps extends HTMLDivElement {
  disabled?: boolean
}

export default function InputGroupComposition({
  children,
  disabled: disabledProp,
  ...rest
}: PropsWithChildren<InputGroupCompositionProps & any>) {
  return (
    <MethodContext.Consumer>
      {({ disabled }) => (
        <InputGroup
          disabled={disabledProp ?? disabled}
          {...rest}>
          {children}
        </InputGroup>
      )}
    </MethodContext.Consumer>
  )
}
