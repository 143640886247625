import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import Text, { SIZE_BODY_1 } from '../../Components/Text/Text'
import { COLORNAME_SUPPORT_4, COLORNAME_TEXT_SECONDARY } from '../../util/colors'
import Divider from '../../Components/Divider/Divider'

const SendUserInvitationsModal = ({ action, users }) => (
  <ModalBox>
    <ModalBoxHead>Send invitations</ModalBoxHead>
    <ModalBoxBody>
      <InputGroupItem>
        <Text
          center
          colorName={COLORNAME_TEXT_SECONDARY}>
          Invitations will be sent to the following users:
        </Text>
      </InputGroupItem>
      <Divider />

      {users.length !== 0 ? (
        users.map((item) => (
          <Fragment key={item.email}>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                padding: '16px 0',
                maxWidth: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  margin: '0 8px 0 16px',
                  flex: '0 1 40%',
                  minWidth: 0,
                }}>
                <Text
                  size={SIZE_BODY_1}
                  maxLines={1}>
                  {`${item.firstName} ${item.lastName}`}
                </Text>
              </div>

              <div
                style={{
                  display: 'flex',
                  margin: '0 16px 0 23px',
                  flex: '1 1 60%',
                  minWidth: 0,
                }}>
                <Text
                  size={SIZE_BODY_1}
                  maxLines={1}>
                  {item.email}
                </Text>
              </div>
            </div>
            <Divider />
          </Fragment>
        ))
      ) : (
        <InputGroupItem>
          <Text colorName={COLORNAME_SUPPORT_4}>All users are active, no emails will be send</Text>
        </InputGroupItem>
      )}
      <br />
    </ModalBoxBody>
    <ModalBoxFoot>
      <FormButton
        style={{ margin: '0 auto' }}
        styleName={BUTTON_STYLENAME_PILL}
        type="button"
        onClick={action}
        disabled={users.length === 0}>
        Send invitation emails
      </FormButton>
    </ModalBoxFoot>
  </ModalBox>
)

SendUserInvitationsModal.propTypes = {
  action: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
}

export default SendUserInvitationsModal
