import Help from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { MouseEvent } from 'react'

import Markdown from '../../common/markdown'
import { getFromConsistencyGuide } from '../../features/consistency-guide'
import NotificationModal, {
  NOTIFICATION_MODAL_TYPE_HELP,
} from '../../Modals/Notification/NotificationModal'
import { toastExplain } from '../../util/toastExplain'

const StyledButton = styled('span')(({ theme }) => ({
  pointerEvents: 'all',
  display: 'inline-flex',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontSize: 'inherit',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export interface InputLabelConsistencyGuideProps {
  name: string
  label: string
  iconOnly?: boolean
}

// TODO:: Refactor and remove toast
export default function InputLabelConsistencyGuideInfo({
  name,
  label,
  iconOnly,
}: InputLabelConsistencyGuideProps) {
  const markdown = getFromConsistencyGuide(name)

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    toastExplain(
      <NotificationModal
        body={<Markdown source={markdown} />}
        header={label}
        modalType={NOTIFICATION_MODAL_TYPE_HELP}
      />
    )()
  }

  if (!markdown) {
    return null
  }

  if (iconOnly) {
    return (
      <Tooltip title={'Show help'}>
        <IconButton onClick={handleClick}>
          <Help />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <StyledButton onClick={handleClick}>
      <span>Help</span> <Help sx={{ fontSize: 14 }} />
    </StyledButton>
  )
}
