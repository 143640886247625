import styles from './FlexItem.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import { memo } from 'react'

export const FLEXITEM_AUTO = 'flex-item-auto'
export const FLEXITEM_25_PERCENT = 'flex-25-percent'
export const FLEXITEM_50_PERCENT = 'flex-50-percent'
export const FLEXITEM_50_PX = 'flex-50-px'
export const FLEXITEM_ROUNDBUTTON = 'flexitem-roundbutton'

const FlexItem = (props) => {
  return <div {...props} />
}

export default memo(withClassNames(FlexItem, styles))
