import PropTypes from 'prop-types'
import styles from './NotificationModal.module.scss'
import Text, { SIZE_CAPTION_2, SIZE_HEADER_5 } from '../../Components/Text/Text'
import { withClassNames } from '../../Components/HigherOrder/withClassNames'
import IconAndText from '../../Components/Icons/IconAndText'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import { ICON_QUESTIONMARK } from '../../Components/Icons/Icon'
import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { memo } from 'react'

export const NOTIFICATION_MODAL_TYPE_HELP = {
  label: 'Help',
  icon: (
    <IconInCircle
      size={'small'}
      colorStyle={'textSecondary'}
      icon={ICON_QUESTIONMARK}
    />
  ),
}

const NotificationModal = ({ header, body, data, closeToast, modalType, ...rest }) => (
  <div {...rest}>
    <div className={styles.modalTitle}>
      <IconAndText icon={modalType.icon}>
        <Text size={SIZE_CAPTION_2}>{modalType.label}</Text>
      </IconAndText>
    </div>
    <div className={styles.modalBody}>
      <Text
        className={styles.contentTitle}
        size={SIZE_HEADER_5}
        colorName={COLORNAME_TEXT_PRIMARY}>
        {header}
      </Text>
      {body}
      {Array.isArray(data) &&
        data.map((error) => <p key={error.path}>{`${error.path}: ${error.message}`}</p>)}
    </div>
  </div>
)

NotificationModal.propTypes = {
  modalType: PropTypes.object,
  header: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.any,
  closeToast: PropTypes.func,
}

NotificationModal.defaultProps = {
  modalType: NOTIFICATION_MODAL_TYPE_HELP,
  header: undefined,
  data: undefined,
  closeToast: undefined,
}

export default memo(withClassNames(NotificationModal, styles))
