import Chip from '@mui/material/Chip'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'

import { isChipOptionsColumn } from './swr-data-grid-chip-options-utils'

export function renderChipOptionsCell(params: GridRenderCellParams) {
  if (!isChipOptionsColumn(params.colDef)) {
    return '-'
  }

  const option = params.colDef.options.find((chipOption) => chipOption.value === params.value)

  if (!option) {
    return '-'
  }

  return (
    <Chip
      color={option.color}
      label={option.label}
      size={'small'}
      variant={'outlined'}
    />
  )
}
