import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Input.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import KeyboardInput, { KEYBOARD_INPUT_READONLY } from '../KeyboardInput/KeyboardInput'
import MethodContext from '../../../Context/method.context'
import { memo } from 'react'

export const KEYBOARD_INPUT_ALIGN_INPUT_TEXT_CENTER = 'alignInputCenter'

/* eslint-disable jsx-a11y/no-autofocus */
const Input = ({
  autoComplete,
  defaultValue,
  placeholder,
  after,
  before,
  input,
  min,
  max,
  step,
  autoFocus,
  showUpDownArrows,
  alignInputText,
  hasError,
  ...rest
}) => (
  <MethodContext.Consumer>
    {(values) => (
      <KeyboardInput
        disabled={values && values.disabled}
        styleName={hasError ? 'hasError' : values && values.readOnly ? KEYBOARD_INPUT_READONLY : ''}
        {...rest}>
        {before && <div className={styles.before}>{before}</div>}
        <input
          disabled={values && values.disabled}
          readOnly={values && values.readOnly}
          step={step}
          min={min}
          max={max}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={classNames(
            styles.textInput,
            alignInputText && styles[alignInputText],
            showUpDownArrows ? '' : styles.noUpDownArrows
          )}
          autoFocus={autoFocus}
          {...input}
        />
        {after && <div className={styles.after}>{after}</div>}
      </KeyboardInput>
    )}
  </MethodContext.Consumer>
)

Input.defaultProps = {
  after: undefined,
  before: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  autoComplete: 'off',
  defaultValue: undefined,
  input: {},
  placeholder: '',
  autoFocus: false,
  showUpDownArrows: true,
  alignInputText: undefined,
  hasError: false,
}

Input.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  before: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  step: PropTypes.number,
  autoFocus: PropTypes.bool,
  showUpDownArrows: PropTypes.bool,
  alignInputText: PropTypes.string,
  hasError: PropTypes.bool,
}

export default memo(withClassNames(Input, styles))
