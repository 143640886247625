import PropTypes from 'prop-types'
import CreateForm from './helpers/create-form'
import { CUSTOMER_GENERAL_PATH } from '../../../util/path'
import { CREATE_CUSTOMER_METHOD } from '../../../util/methods'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import { nextRedirectTo } from '../../../redux/action/redirect'
import CreateInputField from './helpers/CreateInputField'
import ModalBoxHead from '../../../Components/Modal/ModalBoxHead'
import ModalBoxBody from '../../../Components/Modal/ModalBoxBody'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'

const CustomerCreateModal = ({ next }) => (
  <CreateForm
    method={CREATE_CUSTOMER_METHOD}
    next={next}>
    {() => (
      <>
        <ModalBoxHead>New customer</ModalBoxHead>
        <ModalBoxBody>
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Name</FormLabel>
            <CreateInputField
              name="name"
              placeholder="Name"
              autoFocus
            />
          </InputGroupItem>
        </ModalBoxBody>
      </>
    )}
  </CreateForm>
)

CustomerCreateModal.propTypes = {
  next: PropTypes.func,
}

CustomerCreateModal.defaultProps = {
  next: nextRedirectTo(CUSTOMER_GENERAL_PATH),
}

export default CustomerCreateModal
