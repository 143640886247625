import { writeToClipboard } from '@gain/utils/common'
import moment from 'moment'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { BUTTON_STYLENAME_PILL } from '../../../Components/Buttons/Button'
import { FormButton } from '../../../Components/Buttons/FormButton/FormButton'
import PillButtonSecondary from '../../../Components/Buttons/pill-button-secondary'
import FormLabel from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroup from '../../../Components/FormElements/InputGroup/InputGroup'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import PageContent from '../../../Components/Layout/PageContent'
import Note, { NOTE_STYLENAME_INFO } from '../../../Components/Note/Note'
import SpacedItems from '../../../Components/SpacedItems/SpacedItems'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_AUTO } from '../../../Components/Tabular/FlexTable/FlexItem'
import Text from '../../../Components/Text/Text'
import CreateInputField from '../../../Compositions/Modals/CreateModals/helpers/CreateInputField'
import { sendUserInvitation } from '../../../redux/action/sendUserInvitation'
import { resetPassword } from '../../../redux/action/userPassword'
import { DATE_HH_MM, isDateBogus } from '../../../util/dateFormat'
import { toastSuccess } from '../../../util/toastSuccess'
import { CUSTOMER_METHODS } from '../../../util/methods'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { UserStatus } from '@gain/rpc/shared-model'

export default function UserDetailSecurityPage() {
  const data = useSelector((state) => state.fetchItem.data)
  const dispatch = useDispatch()

  const showPasswordReset =
    (!data.isSsoUser && data.status === UserStatus.Active) ||
    data.status === UserStatus.Deactivated ||
    data.isApiUser
  const showOnboarding = data.status !== UserStatus.Active && data.status !== UserStatus.Deactivated

  return (
    <PageContent>
      {/* Single Sign On section */}
      {data.isSsoUser && !data.isApiUser && (
        <>
          <FormLabel>Single Sign On (SSO)</FormLabel>
          <Note styleName={NOTE_STYLENAME_INFO}>
            This user signs in using SSO, which means you can not reset their password
          </Note>
        </>
      )}

      {/* Password reset section */}
      {showPasswordReset && (
        <>
          <FormLabel>Security</FormLabel>
          <InputGroupComposition>
            <Form
              initialValues={{ id: data.id }}
              onSubmit={(values, form, callback) =>
                dispatch(
                  resetPassword(CUSTOMER_METHODS.resetPassword, values, () => {
                    callback()
                    form.reset({ id: data.id })
                    form.resetFieldState('password')
                  })
                )
              }>
              {({ handleSubmit, pristine, submitting, invalid, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <CreateInputField
                    name="password"
                    type="text"
                    placeholder="Password"
                  />

                  <InputGroupItem>
                    <FormButton
                      styleName={BUTTON_STYLENAME_PILL}
                      spinner={submitting}
                      disabled={!dirtySinceLastSubmit && (submitting || pristine || invalid)}>
                      Reset password
                    </FormButton>
                  </InputGroupItem>
                </form>
              )}
            </Form>
          </InputGroupComposition>
        </>
      )}

      {/* Onboarding / invitation section */}
      {showOnboarding && (
        <>
          <FormLabel>Onboarding</FormLabel>
          <InputGroup disabled>
            <InputGroupItem>
              <FlexContainer
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <FlexItem style={{ flex: '0 1 auto' }}>
                  <KeyboardInput>
                    {isDateBogus(data.invitationSentAt) ? (
                      <Text>Invitation has not been sent</Text>
                    ) : (
                      <Text>
                        {`Invitation sent: ${moment(data.invitationSentAt).format(DATE_HH_MM)}`}
                      </Text>
                    )}
                  </KeyboardInput>
                </FlexItem>
                <FlexItem styleName={FLEXITEM_AUTO}>
                  <SpacedItems>
                    <PillButtonSecondary
                      onClick={() =>
                        dispatch(
                          sendUserInvitation(CUSTOMER_METHODS.sendUserInvitation, { id: data.id })
                        )
                      }>
                      {isDateBogus(data.invitationSentAt) ? 'Send invitation' : 'Resend invitation'}
                    </PillButtonSecondary>
                    {data.invitationLink && (
                      <PillButtonSecondary
                        onClick={() => {
                          writeToClipboard(data.invitationLink)
                          toastSuccess(
                            `Invitation link copied to clipboard: ${data.invitationLink}`
                          )
                        }}>
                        Copy invitation link
                      </PillButtonSecondary>
                    )}
                  </SpacedItems>
                </FlexItem>
              </FlexContainer>
            </InputGroupItem>
          </InputGroup>
        </>
      )}
    </PageContent>
  )
}
