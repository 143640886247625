import { useRpcClient } from '@gain/api/swr'
import Tooltip from '@gain/components/tooltip'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { useDialogState } from '@gain/utils/dialog'
import { IncludeStartsWith } from '@gain/utils/typescript'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

import { useSwrDataGrid } from '../../common/swr-data-grid'

export interface ItemPageDeleteActionProps {
  name: string
  redirectPath?: string

  recordId: string | number
  deleteMethod: IncludeStartsWith<keyof RpcMethodMap, 'data.delete' | 'customer.delete'>

  variant?: 'default' | 'icon'
}

export function ItemPageDeleteAction({
  name,
  redirectPath,
  deleteMethod,
  recordId,
  variant = 'default',
}: ItemPageDeleteActionProps) {
  const [showDeleteModal, handleOpenDeleteModal, handleCloseDeleteModal] = useDialogState()
  const rpcClient = useRpcClient<RpcMethodMap>()
  const swrDataGrid = useSwrDataGrid()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const handleDeleteClick = useCallback(async () => {
    handleCloseDeleteModal()

    try {
      await rpcClient({
        method: deleteMethod,
        params: {
          id: parseInt(`${recordId}`, 10),
        },
      })

      if (redirectPath) {
        history.push(redirectPath)
      }

      // If the delete action is rendered within a data grid, then re-fetch its data.
      if (swrDataGrid) {
        await swrDataGrid.api.swr.mutate()
      }
    } catch (err) {
      if (isJsonRpcError(err)) {
        enqueueSnackbar(err.message, {
          key: 'item-page-delete-error',
          preventDuplicate: true,
          variant: 'error',
        })
      }
    }
  }, [
    deleteMethod,
    enqueueSnackbar,
    handleCloseDeleteModal,
    history,
    recordId,
    redirectPath,
    rpcClient,
    swrDataGrid,
  ])

  return (
    <>
      {variant === 'default' && (
        <MenuItem
          onClick={handleOpenDeleteModal}
          sx={{ color: 'error.main' }}>
          Delete {name}
        </MenuItem>
      )}

      {variant === 'icon' && (
        <Tooltip title={`Delete ${name}`}>
          <IconButton onClick={handleOpenDeleteModal}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        onClose={handleCloseDeleteModal}
        open={showDeleteModal}>
        <DialogTitle>Delete {name}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this {name}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button
            color={'error'}
            onClick={handleDeleteClick}
            variant={'contained'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
