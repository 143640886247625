import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputDate from '../../../Compositions/Inputs/InputDate'
import PageContent from '../../../Components/Layout/PageContent'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const ArticleResearchGeneralPage = ({ data }) => (
  <PageContent>
    <FormLabel>General information</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <FormLabel styleName={FORMLABEL_SECONDARY}>Publication date</FormLabel>
        <UpdateForm initialValues={data}>{() => <InputDate path="date" />}</UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>
  </PageContent>
)

ArticleResearchGeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(ArticleResearchGeneralPage)
