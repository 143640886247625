import { useUserProfileContext } from '@gain/modules/user'
import { isAdministrator } from '@gain/utils/user'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import Icon, { DIRECTION_DOWN, DIRECTION_RIGHT, ICON_CHEVRON } from '../../Components/Icons/Icon'

const mainNavigationItemClasses = generateUtilityClasses('mainNavigationItemClasses', [
  'root',
  'active',
])

const StyledNavLinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'rgb(255 255 255 / 70%)',
  padding: theme.spacing('6px', 0, '6px', 3),

  // Added by react-router
  [`&.${mainNavigationItemClasses.active}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    borderRight: `solid 3px ${theme.palette.primary.main}`,
  },

  '& > a': {
    flex: '1 0 auto',
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const StyledSubMenu = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  margin: 0,
  padding: 0,
  maxHeight: open ? 400 : 0,
  overflow: 'hidden',
  transition: 'max-height 0.2s linear',
  backgroundColor: alpha(theme.palette.common.white, 0.05),
}))

interface MainNavigationItemProps {
  title: string
  path: string
  adminOnly?: boolean
  shortcuts?: MainNavigationItemProps[]
}

export default function MainNavigationItem({
  title,
  path,
  adminOnly,
  shortcuts = [],
}: MainNavigationItemProps) {
  const userProfile = useUserProfileContext()

  const match = useRouteMatch({
    path,
  })

  const defaultSubMenuOpen = useRouteMatch({
    path: shortcuts.map((shortcut) => shortcut.path),
  })

  const [opened, setOpened] = useState<boolean>(Boolean(defaultSubMenuOpen))
  const toggleSubMenu = useCallback(() => {
    setOpened((prevOpen) => !prevOpen)
  }, [])

  if (adminOnly && !isAdministrator(userProfile.role)) {
    return null
  }

  return (
    <li>
      <StyledNavLinkContainer
        className={clsx(mainNavigationItemClasses.root, {
          [mainNavigationItemClasses.active]: match,
        })}>
        <NavLink to={path}>{title}</NavLink>
        {shortcuts?.length > 0 && (
          <Stack
            display={'block'}
            onClick={toggleSubMenu}
            p={1.5}
            style={{ cursor: opened ? 'n-resize' : 's-resize' }}
            title={'Show shortcuts'}>
            <Icon
              direction={opened ? DIRECTION_DOWN : DIRECTION_RIGHT}
              src={ICON_CHEVRON}
              style={{ fontSize: 12 }}
            />
          </Stack>
        )}
      </StyledNavLinkContainer>

      {shortcuts.length > 0 && (
        <StyledSubMenu open={opened}>
          {shortcuts.map((shortcut) => (
            <MainNavigationItem
              key={shortcut.path}
              {...shortcut}
            />
          ))}
        </StyledSubMenu>
      )}
    </li>
  )
}
