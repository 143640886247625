import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import InputTextArea from '../../../Compositions/Inputs/InputTextArea'
import { DealSelect } from '../../../Compositions/Selects/AsyncSelects/deal-select'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputString from '../../../Compositions/Inputs/input-string.component'
import { REGIONS } from './helpers/articleDetailNames'
import FormLabel from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import PageContent from '../../../Components/Layout/PageContent'
import InputDate from '../../../Compositions/Inputs/InputDate'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import CategorySelect from '../../../Compositions/Selects/CategorySelect'
import SubSectorSelect from '../../../Compositions/Selects/SubSectorSelect'
import RegionsSelect from '../../../Compositions/Selects/regions-select.component'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const ArticleInthenewsGeneralPage = ({ data }) => (
  <PageContent>
    <FormLabel>News title</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>
          {() => (
            <InputString
              path="title"
              title="Title"
              maxLength={70}
            />
          )}
        </UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>
    <FormLabel>Body</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>
          {() => (
            <InputTextArea
              path="body"
              title="Subtitle"
              maxLength={750}
            />
          )}
        </UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <FormLabel>Category</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>{() => <CategorySelect path="category" />}</UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <FormLabel>Publication date</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>{() => <InputDate path="date" />}</UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <FormLabel>Regions</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={{ [REGIONS]: data[REGIONS] }}>
          {() => (
            <RegionsSelect
              path={REGIONS}
              title="Select regions"
            />
          )}
        </UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <FormLabel>Sector</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={data}>{() => <SubSectorSelect path="subsector" />}</UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <FormLabel>Deal</FormLabel>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={{ linkedDealId: data.linkedDealId }}>
          {(form, dirty) => (
            <DealSelect
              dealId={data.linkedDealId}
              path="linkedDealId"
              dirty={dirty}
            />
          )}
        </UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>
  </PageContent>
)

ArticleInthenewsGeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(ArticleInthenewsGeneralPage)
