import PropTypes from 'prop-types'
import { withClassNames } from '../../HigherOrder/withClassNames'
import styles from './InputError.module.scss'
import IconInCircle from '../../Icons/icon-in-circle'
import { ICON_EXCLAMATION_MARK } from '../../Icons/Icon'
import { memo } from 'react'

const InputError = ({ hasErrors, onClick, ...rest }) => (
  <div {...rest}>
    <IconInCircle
      onClick={(event) => {
        event.stopPropagation()
        onClick()
      }}
      size={'small'}
      icon={ICON_EXCLAMATION_MARK}
      colorStyle={hasErrors ? 'red' : 'yellow'}
    />
  </div>
)

InputError.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(withClassNames(InputError, styles))
